import React, { Component } from 'react'
import NumberFormat from 'react-number-format'
import Switch from "react-switch";

export  default class EmployeeListRow extends Component{

    render(){

        let employeeList;

        if (this.props.employee_list.length === 0){
            employeeList = <tr><td colSpan={6} className="text-center"><span className="text-danger">ไม่พบข้อมูล</span></td></tr>
        }else {

            employeeList = this.props.employee_list.map((employee, index) => {
                return (
                    <tr key={index}>
                        <td className="text-center">{index+1}</td>
                        <td className="text-center">{employee.employee_code}<br/>
                            <br/>
                            { employee.status_field === 'show' ? 'ใช้งาน' : 'ปิดการใช้งาน' }
                            <br/>

                            <Switch height = {20} width ={48}
                                    onChange={(checked) => this.props.onChangeSwitch(checked, employee)}
                                    checked={employee.status_field === 'show'}
                            />
                        </td>
                        <td className="text-left text-nowrap">
                          ชื่อ-นามสกุล: {employee.employee_name}&nbsp;&nbsp;{employee.employee_lastname}
                          <br/>
                          โทร: {employee.employee_tel}
                          <hr/>
                          ตำแหน่ง: {employee.employee_position} <br/>
                          {this.props.user_type === 'admin' ?
                            <>
                              เงินเดือน:&nbsp;
                              <NumberFormat value={employee.employee_salary}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={'฿'}
                              />
                            </>
                            : null
                          }
                        </td>
                        <td className="text-center">

                            <button className="btn btn-sm btn-warning btn-md-width mr-2 mb-2"
                                    style={styles.button}
                                    onClick={this.props.employeeCallbacks.edit.bind(null, employee)}
                            >
                                <i className="fa fa-edit"/> แก้ไข</button>

                          <button className="btn btn-sm btn-danger btn-md-width mb-2 mr-2"
                                  style={styles.button}
                                  onClick={this.props.employeeCallbacks.delete.bind(null, employee)}
                                  disabled={employee.car_sell_id !== null}
                          >
                            <i className="fa fa-remove"/> ลบ</button>
                        </td>
                    </tr>
                )
            });
        }

        return (

                <table className="table table-striped table-responsive">
                    <thead>
                    <tr>
                        <th className="text-center">#</th>
                        <th className="text-center text-nowrap">รหัสพนักงาน</th>
                        <th className="text-center text-nowrap">ข้อมูล</th>
                        <th className="text-center">จัดการ</th>
                    </tr>
                    </thead>
                    <tbody>
                        {employeeList}
                    </tbody>
                </table>

        )
    }
}

const styles = {
    button: {
        borderRadius:5,
        height: 36,
    },

    inputSearch: {
        borderRadius:5,
        display: 'inline'
    }
}
