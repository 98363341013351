import React, { Component } from 'react';
import {APIURL} from "../../config/config";
import axios from "axios";
import {AlertSuccess} from "../Alert/Alert";
import Swal from "sweetalert2";
import CustomerCreditForm from "./CustomerCreditForm";
import { format } from "date-fns";
import {checkGroupPermission} from "../../services/grouppermission";
import {DateUtil} from "../../utils/dateUtil";

class CustomerCredit extends Component{
    constructor (props) {
        super(props)

        this.state = {
            is_modal: false,
            width: 0,
            height: 0,
            customer_credit: [],
            customer_credit_id: 0,
            customer_credit_edit: null,
            form_status: 'add'
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.loadCustomerCredit = this.loadCustomerCredit.bind(this);
        this.onClickEdit = this.onClickEdit.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);

    }

    componentDidMount () {
        this.loadCustomerCredit()
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    loadCustomerCredit() {
        let customer_id = this.props.customer_id
        // console.log('customer_id=', customer_id)
        let url = APIURL +'/customer_credit/'+customer_id
        axios.get(url).then(res => {
            let result = res.data;
            this.setState({
                customer_credit: result
            })
        })

        /*let url = APIURL +'/customer_credit'
        axios.get(url).then(res => {
            let result = res.data;
            this.setState({
                customer_credit: result
            })
        })*/
    }

    onClickEdit(item) {
        this.setState({
            customer_credit_id: item.id,
            customer_credit_edit: item,
            form_status: 'edit',
            is_modal: true
        })
    }

    onClickDelete(item) {
        let finance_name = item.finance_name
        let fullname = item.customer_name+' '+item.customer_lastname

        Swal.fire({
            title: 'ยืนยันการลบประวัติการเช็คเครดิต '+finance_name + ' ของ '+fullname,
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
        }).then(result => {
            if(result.value){
                let id = item.id
                let url = APIURL +'/customer_credit/'+id
                axios.delete(url).then(res => {
                    let result = res.data;
                    if(result === 'success') {
                        AlertSuccess('ลบบ้อมูลเรียบร้อยแล้ว');

                        this.loadCustomerCredit()
                    }
                })
            }
        })
    }

    render() {

        let customer_credit = this.state.customer_credit

        let empty_row = <tr><td className="text-center text-danger" colSpan={6}>ไม่พบข้อมูล</td></tr>
        let customer_credit_rows = customer_credit.map((item, index) => {

            let finance_check_date = item.finance_check_date === null ? '-' : DateUtil.convertLongDateThai( format(new Date(item.finance_check_date), 'yyyy-MM-dd'))
            let balance_closing_date = item.balance_closing_date === null ? '-' : format(new Date(item.balance_closing_date), 'yyyy-MM-dd')

            return (
                <tr key={index}>
                    <td className="text-center">{ index+1}</td>
                    <td className="text-left" style={{width: 250}}>
                        ชื่อ-นามสกุล : {item.customer_name}{' '} {item.customer_lastname} <br/>
                        โทร: {item.customer_mobile===''? '-' : item.customer_mobile}
                    </td>
                    <td className="text-center justify-content-center">
                        <div style={{ width: '100%'}}>
                         <table style={{marginTop: 0, marginBottom: 0, marginLeft: 'auto', marginRight: 'auto' ,}} >
                            <tbody>
                                <tr>
                                    <td className="text-left" style={{width: 180}}>วันที่เช็คเครดิตล่าสุด :</td><td>{finance_check_date} </td>
                                </tr>
                                <tr>
                                    <td className="text-left" style={{width: 180}}>ติดแท็กซิ่ง :</td><td>{item.bureau_tracing_name} </td>
                                </tr>
                                <tr>
                                    <td className="text-left" style={{width: 180}}>จำนวนครั้งที่ติด :</td><td>{item.bureau_tracing_count} </td>
                                </tr>
                                <tr>
                                    <td className="text-left" style={{width: 180}}>ระยะเวลาที่ติดแบล็คลิสต์ :</td><td>{item.blacklist_period_name} </td>
                                </tr>
                                <tr>
                                    <td className="text-left" style={{width: 180}}>วันที่ปิดยอด :</td><td>{balance_closing_date} </td>
                                </tr>
                                <tr>
                                    <td className="text-left" style={{width: 180}}>ชื่อไฟแนนซ์ :</td><td>{item.finance_name} </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </td>

                    <td className="text-left"> {item.finance_credit_result}</td>

                    <td className="text-center">

                        {
                            checkGroupPermission(405, this.props.grouppermissions).modified ? (
                                <button className="btn btn-sm btn-warning btn-md-width mr-2 mb-2"
                                        style={styles.button}
                                        onClick={() => this.onClickEdit(item)}
                                >
                                    <i className="fa fa-edit"/> แก้ไข
                                </button>
                            ) : null
                        }

                        {
                            checkGroupPermission(405, this.props.grouppermissions).deleted ? (
                                <button className={"btn btn-sm btn-danger btn-md-width mb-2 mr-2"}
                                        onClick={() => this.onClickDelete(item)}
                                        style={styles.button}
                                >
                                    <i className="fa fa-remove"/> ลบ</button>
                            ) : null
                        }

                        {
                            checkGroupPermission(405, this.props.grouppermissions).modified === 0
                            && checkGroupPermission(405, this.props.grouppermissions).deleted === 0
                                ? <><i className="icon-close"/>  </> : null
                        }

                    </td>
                </tr>
            )

        })

       let customer_credit_list = customer_credit.length === 0 ? empty_row : customer_credit_rows
       let customer = this.props.customer

        return (
            <>

                {
                    this.state.is_modal
                    &&
                    <CustomerCreditForm
                        isDateFill={this.props.isDateFill}
                        username={this.props.username}

                        is_modal={this.state.is_modal}
                        width={this.state.width}

                        customer_id={this.props.customer_id}
                        customer={this.props.customer}

                        toggle={() => {
                            this.setState({is_modal: false})
                        }}
                        form_status={this.state.form_status}
                        customer_credit_id={this.state.customer_credit_id}
                        customer_credit_edit={this.state.customer_credit_edit}
                        closeAndReload={() => {
                            this.setState({is_modal: false, form_status: 'add'})
                            this.loadCustomerCredit()
                        }}
                    />
                }

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header card-customer">
                                <strong className="text-title">
                                    ประวัติการเช็คเครดิต : {customer.customer_name}{' '} {customer.customer_lastname}
                                </strong>

                                {
                                  this.state.customer_id !== 0 &&  checkGroupPermission(405, this.props.grouppermissions).created ? (
                                        <button className="btn btn-head-bar float-right"  onClick={() => {
                                            this.setState({
                                                is_modal: true,
                                                form_status: 'add',
                                            })
                                        }} >
                                            <i className="icon-plus"/>&nbsp; เพิ่มประวัติการเช็คเครดิต
                                        </button>
                                    ) : null
                                }

                            </div>

                            <div className="card-block">

                                <table className="table table-striped table-responsive">
                                    <thead>
                                    <tr>
                                        <th className="text-center" style={{width: 10}}>#</th>
                                        <th className="text-center text-nowrap">ลูกค้า</th>
                                        <th className="text-center text-nowrap">รายละเอียด</th>
                                        <th className="text-left text-nowrap" style={{width: 130}}>
                                            ผลการเช็คเครดิต/เรื่องที่ติดเครดิตบูโร
                                        </th>
                                        <th className="text-center" style={{width: 80}}>จัดการ</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {customer_credit_list}
                                    </tbody>
                                </table>

                            </div>

                        </div>
                    </div>
                </div>

            </>
        )
    }
}

const styles = {
    button: {
        borderRadius:5,
        height: 36,
    }
}

export default CustomerCredit
