import {format, parse, isValid} from "date-fns";
import { enGB } from 'date-fns/locale';

export const DateUtil = {
  convertShortDateThai(date) {
    // ddmmyyyy
    try {
      let arrDate = date.split('/')
      let dd = arrDate[0]
      let mm = arrDate[1]
      let yyyy = parseInt(arrDate[2], 10) + 543
      return  dd+'/'+mm+'/'+yyyy
    } catch (e) {
      return "InvalidDate"
    }
  },
  convertShortDateThaiDash(date) {
    // 2023-11-31 => dd/mm/yyyy
    try {
      let arrDate = date.split('-')
      let dd = arrDate[2]
      let mm = arrDate[1]
      let yyyy = parseInt(arrDate[0], 10) + 543
      return  dd+'/'+mm+'/'+yyyy
    } catch (e) {
      return "-ไม่ระบุวันที่-"
    }
  },
  convertShortMonthYearThai(date) {
    // 2023-11-31 => dd/mm/yyyy
    try {
      let arrDate = date.split('-')
      let dd = arrDate[2]
      let mm = arrDate[1]
      let yyyy = parseInt(arrDate[0], 10) + 543
      let yy = String(yyyy).substring(2,  4);
      return  mm+'/'+yy
    } catch (e) {
      return "-ไม่ระบุวันที่-"
    }
  },
  convertCCtoDDMMYYYY(cc_date){ //2018-01-27
    let date_arr=cc_date.split('-');
    let yyyy = Number(date_arr[0]);
    let mm = date_arr[1];
    let dd = date_arr[2];

    return dd+'-'+mm+'-'+yyyy

  },
  convertDateThaiToDB(date_thai) {
    //  car_date_in=' '2567-03-27' => 2024-03-27
    let date_arr=date_thai.split('-');
    let yyyy = Number(date_arr[0])-543;
    let mm = String(date_arr[1]).length === 1 ? '0'+date_arr[1]: date_arr[1];
    let dd = date_arr[2];

    return yyyy+'-'+mm+'-'+dd
  },
  convertCCtoBB(cc_date){ //2018-01-27
    let date_arr=cc_date.split('-');
    let yyyy = Number(date_arr[0])+543;
    let mm = date_arr[1];
    let dd = date_arr[2];

    return dd+'-'+mm+'-'+yyyy

  },
  convertCCtoCCDB(cc_date){
    // 27-01-2018 => 2018-01-27
    let date_arr=cc_date.split('-');
    let dd = date_arr[0];
    let mm = date_arr[1];
    let yyyy = Number(date_arr[2]);

    return yyyy+'-'+mm+'-'+dd

  },
  convertLongDateThai(data) {
    // 2022-10-12 to thai 12 ตุลาคม 2567
    // console.log('data=', data)
    // moment(car.car_date_regis).locale('th').format('LL')

    if (data === undefined) {
      return '-'
    }

    if (data === null) {
      return '-'
    }

    if (data === 'null') {
      return '-'
    }

    if (data === '0000-00-00') {
      return '-'
    }

    let splitData = data.split('-');
    let date = splitData[2];
    let month = parseInt(splitData[1], 10);
    let year = splitData[0];
    let yearBudd = parseInt(year, 10) + 543
    let monthName = ''
    switch (month) {
      case 1: monthName = 'มกราคม'; break;
      case 2: monthName = 'กุมภาพันธ์'; break;
      case 3: monthName = 'มีนาคม'; break;
      case 4: monthName = 'เมษายน'; break;
      case 5: monthName = 'พฤษภาคม'; break;
      case 6: monthName = 'มิถุนายน'; break;
      case 7: monthName = 'กรกฎาคม'; break;
      case 8: monthName = 'สิงหาคม'; break;
      case 9: monthName = 'กันยายน'; break;
      case 10: monthName = 'ตุลาคม'; break;
      case 11: monthName = 'พฤศจิกายน'; break;
      case 12: monthName = 'ธันวาคม'; break;
      default:
        monthName = ''
    }
    return date+' ' + monthName+' '+yearBudd
  },
  convertLongDateThaiNew(date) {
    if (date === undefined) {
      return '-'
    }

    if (date === null) {
      return '-'
    }

    if (date === '0000-00-00') {
      return '-'
    }

    let dateNew = new Date(date)

    return dateNew.toLocaleDateString('th-TH', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })

  },
  getMonthYearFromDate(strDate) {
    let arrDate = strDate.split('-')
    // let dd = arrDate[2]
    let yyyy = Number(arrDate[0])+543
    let mm = Number(arrDate[1])
    let monthName = ''
    switch (mm) {
      case 1: monthName = 'มกราคม'; break;
      case 2: monthName = 'กุมภาพันธ์'; break;
      case 3: monthName = 'มีนาคม'; break;
      case 4: monthName = 'เมษายน'; break;
      case 5: monthName = 'พฤษภาคม'; break;
      case 6: monthName = 'มิถุนายน'; break;
      case 7: monthName = 'กรกฎาคม'; break;
      case 8: monthName = 'สิงหาคม'; break;
      case 9: monthName = 'กันยายน'; break;
      case 10: monthName = 'ตุลาคม'; break;
      case 11: monthName = 'พฤศจิกายน'; break;
      case 12: monthName = 'ธันวาคม'; break;
      default:
        monthName = ''
    }
    return monthName+' '+yyyy
  },
  calculateAge (birthDate) {

    birthDate = new Date(birthDate);
    // otherDate = new Date(otherDate);
    let otherDate = format(new Date(),'yyyy-MM-dd')
    otherDate = new Date(otherDate);

    let years = (otherDate.getFullYear() - birthDate.getFullYear());

    if (otherDate.getMonth() < birthDate.getMonth() ||
        otherDate.getMonth() == birthDate.getMonth() && otherDate.getDate() < birthDate.getDate()) {
      years--;
    }

    return years;
  },
  isValidDate(day, month, year) {
    const parsedDate = parse(`${day}/${month}/${year}`, 'P', new Date(), { locale: enGB })
    const formattedDate = format(parsedDate, 'yyyy-MM-dd');
    return {
      isValid: isValid(parsedDate),
      formatDate: isValid(parsedDate) ? formattedDate : null
    }
  },
  dateAgo(date) {
    let startDate = new Date(date);
    let diffDate = new Date(new Date() - startDate);
    return ((diffDate.toISOString().slice(0, 4) - 1970) + "Y " + diffDate.getMonth() + "M " + (diffDate.getDate()-1) + "D");
  },
  carDateTaxOverdue(car_date_tax) {
    let date1 = new Date(car_date_tax).getTime();
    let date2 = new Date().getTime();
    if (date1 > date2) {
      return '-'
    }

    let startDate = new Date(car_date_tax);
    let diffDate = new Date(new Date() - startDate);
    let year =  diffDate.toISOString().slice(0, 4) - 1970
    if (year > 0) {
      return ("เกิน "+year+ " ปิ " + diffDate.getMonth() + " เดือน " + (diffDate.getDate()-1) + " วัน");
    }
    return ( "เกิน "+diffDate.getMonth() + " เดือน " + (diffDate.getDate()-1) + " วัน");
  },
  carDateTaxRemaining(car_date_tax) {
    let date1 = new Date(car_date_tax).getTime();
    let date2 = new Date().getTime();
    if (date1 < date2) {
      return 'เกินกำหนด'
    }

    let startDate = new Date(car_date_tax);
    let diffDate = new Date(startDate - new Date());
    let year =  diffDate.toISOString().slice(0, 4) - 1970
    if (year > 0) {
      return ("อีก "+year+ " ปิ " + diffDate.getMonth() + " เดือน " + (diffDate.getDate()) + " วัน");
    }else if (diffDate.getMonth() === 0){
      return ("อีก "+ (diffDate.getDate()) + " วัน");
    }
    return ( "อีก "+diffDate.getMonth() + " เดือน " + (diffDate.getDate()) + " วัน");
  },
  carDateInMonth(car_date_in) {
    let date1 = new Date(car_date_in).getTime();
    let date2 = new Date().getTime();
    if (date1 > date2) {
      return '-'
    }

    let startDate = new Date(car_date_in);
    let diffDate = new Date(new Date() - startDate);
    // let year =  diffDate.toISOString().slice(0, 4) - 1970
    // if (year > 0) {
    //   return ("เกิน "+year+ " ปิ " + diffDate.getMonth() + " เดือน " + (diffDate.getDate()-1) + " วัน");
    // }
    // return ( "เกิน "+diffDate.getMonth() + " เดือน " + (diffDate.getDate()-1) + " วัน");
    return ( diffDate.getMonth());
  }
}
