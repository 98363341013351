let titleStyle = {
    name: "AngsanaUPC",
    family: 4,
    size: 18,
    bold: true,
};

let contentStyle = {
    name: "AngsanaUPC",
    family: 4,
    size: 16,
    bold: false,
};

let contentBoldStyle = {
    name: "AngsanaUPC",
    family: 4,
    size: 16,
    bold: true,
};

let border= {
    top: {style:'thin'},
    left: {style:'thin'},
    bottom: {style:'thin'},
    right: {style:'thin'}
}
export {
    titleStyle,
    contentStyle,
    contentBoldStyle,
    border
}