import React , { Component } from 'react'
import axios from 'axios'
import { APIURL } from '../../config/config'
import { Link } from 'react-router-dom'

class CustomerFollowupReport extends Component {
  constructor (props) {
    super(props);
    this.state = {
      followupSummary: [],
      followupUniqByName: []
    }
    this.loadFollowupSummary = this.loadFollowupSummary.bind(this)
  }

  componentDidMount () {
    this.loadFollowupSummary()
  }

  loadFollowupSummary() {
    let uri = APIURL+'/customer/followup/summary'
    axios.get(uri).then(res => {
      if (res.status === 200) {
        let data = res.data
        this.setState({
          followupSummary: data.followupSummary,
          followupUniqByName: data.followupUniqByName
        })
      }
    })
  }

  getCountByCustomerType(followupSummary, username, customer_type ) {
    let data = followupSummary.filter((followup)=> followup.followup_by===username && followup.customer_type ===customer_type)
    if(data.length === 0) {
      return 0
    }
    return  data[0].count_customer_type
  }

  render () {
    const {
      followupUniqByName,
      followupSummary
    } = this.state

      let rowEmpty = <tr><td colSpan={6} className="text-center text-danger">ไม่พบข้อมูล</td></tr>

    let followupData = followupUniqByName.map((followupName, index) => {
      let username = followupName.followup_by
      let fullname = followupName.fullname

      return <tr key={index}>
         <td> {fullname} : {username} </td>
         <td> {fullname} : {username} </td>
         <td> {fullname} : {username} </td>
         <td className="text-center"> {fullname} : {username} </td>
      </tr>
    })

    let followupRows =  followupUniqByName.length === 0 ? rowEmpty : followupData

    return (
      <div>
        <table className="table table-striped">
          <thead>
          <tr>
             <th className="text-center">ชื่อลูกค้า</th>
             <th className="text-center">รายละเอียดปัจจุบัน</th>
             <th className="text-center">สถานะการติดตาม</th>
             <th className="text-center">ชื่อผู้ติดตาม</th>
          </tr>
          </thead>
          <tbody>
             {followupRows}
          </tbody>
        </table>
      </div>
    )
  }
}

export default CustomerFollowupReport
