import React, {Component} from "react";
import {APIURL} from "../../config/config";
import axios from "axios";
import {AlertSuccess} from "../Alert/Alert";

// const _ = require('lodash');

class CarNote extends Component {
    constructor (props) {
        super(props)
        this.state = {
            car_notes: [],
            car_notes_original: []
        }
        this.saveCarNotes = this.saveCarNotes.bind(this);
    }

    componentDidMount() {
        this.mounted = true
        this.setState({
            car_notes: [],
            car_notes_original: []
        }, () =>  this.loadCarNotes());
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.car_id !== this.props.car_id) {
            this.setState({
                car_notes: [],
                car_notes_original: []
            }, () =>  this.loadCarNotes());
        }
    }

    componentWillUnmount () {
        this.mounted = false
    }

    loadCarNotes() {
        let car_id =  this.props.car_id
        let note_type =  this.props.note_type
        let username =  this.props.username
        let customer_id =  this.props.customer_id
        // 'technician', 'reserve'

        let uri = APIURL +'/car_note/'+car_id+'/'+note_type+'/'+customer_id;

        if (this.mounted) {
            axios.get(uri)
                .then(res => {

                    let car_notes = res.data
                    if (car_notes.length === 0) {
                        // status_form => new, exist, edit
                        car_notes = [
                            {id: 0, is_process: 0, is_done: 0, car_id: car_id, customer_id: customer_id, note_type: note_type, status_form: 'new', note_detail: '', user_created: username, user_updated: username },
                            {id: 0, is_process: 0, is_done: 0, car_id: car_id, customer_id: customer_id, note_type: note_type, status_form: 'new', note_detail: '', user_created: username, user_updated: username },
                            {id: 0, is_process: 0, is_done: 0, car_id: car_id, customer_id: customer_id, note_type: note_type, status_form: 'new', note_detail: '', user_created: username, user_updated: username },
                            {id: 0, is_process: 0, is_done: 0, car_id: car_id, customer_id: customer_id, note_type: note_type, status_form: 'new', note_detail: '', user_created: username, user_updated: username },
                        ];
                        let car_notes_original = JSON.stringify(car_notes);
                        this.setState({car_notes: car_notes, car_notes_original: car_notes_original});
                    } else {
                        let car_notes_original = JSON.stringify(car_notes);
                        this.setState({car_notes: car_notes, car_notes_original: car_notes_original});
                    }

                }).catch(error => {
                this.setState({car_notes: []})
            })
        }
    }

    saveCarNotes() {
        let car_notes = this.state.car_notes;
        let uri = APIURL+'/car_note';
        let data = {
            car_notes: car_notes
        };

        // console.log(data)
        // return

        axios.post(uri, data)
            .then(res => {
                // console.log(res.data)
                if(res.status === 200){
                    AlertSuccess('บันทึกเรียบร้อยแล้ว');
                    this.setState({
                        car_notes: [],
                        car_notes_original: []
                    }, () =>  this.loadCarNotes());
                }
            }).catch(error => {
            console.log('error:', error)
        })
    }

    render() {
        // let car_id =  this.props.car_id
        // let note_type =  this.props.note_type
        let username =  this.props.username
        let note_type =  this.props.note_type
        let car_notes = this.state.car_notes
        let car_notes_original = this.state.car_notes_original

        let note_type_name = ''
        if (note_type === 'technician') {
            note_type_name = '**หมายเหตุงานช่าง**'
        } else if (note_type === 'reserve') {
            note_type_name = '**หมายเหตุการจอง**'
        }

        let car_notes_json = JSON.stringify(this.state.car_notes)
        let is_car_notes_change = car_notes_json !== car_notes_original

        let car_note_list = car_notes.map((car_note, index) => {

            return (
                <tr key={index}>
                    <td>
                        <input
                            type="checkbox"
                            style={{accentColor: '#f0d000'}}
                            className="form-control"
                            value={car_note.is_process}
                            checked={car_note.is_process === 1}
                            onChange={(e) => {
                                let val = Number(e.target.value) === 0 ? 1 : 0;
                                let status_form = car_note.status_form === 'exist' ? 'edit' : car_note.status_form;
                                delete car_notes.status_form
                                car_notes[index] = {...car_note, is_process: val, status_form: status_form, user_updated: username};
                                this.setState({car_notes: car_notes});
                            }}

                        />
                    </td>
                    <td>
                        <input
                            type="checkbox"
                            style={{accentColor: '#11b122'}}
                            className="form-control"
                            value={car_note.is_done}
                            checked={car_note.is_done === 1}
                            onChange={(e) => {
                                let val = Number(e.target.value) === 0 ? 1 : 0;
                                let status_form = car_note.status_form === 'exist' ? 'edit' : car_note.status_form;
                                delete car_notes.status_form
                                car_notes[index] = {...car_note, is_done: val, status_form: status_form, user_updated: username};
                                this.setState({car_notes: car_notes});
                            }}

                        />
                    </td>
                    <td>
                            <textarea
                                name="profile_name"
                                className="form-control"
                                style={{width:130}}
                                value={car_note.note_detail}
                                onChange={(e) => {
                                    let note_detail = e.target.value
                                    let status_form = car_note.status_form === 'exist' ? 'edit' : car_note.status_form;
                                    delete car_notes.status_form
                                    car_notes[index] = {...car_note, note_detail: note_detail, status_form: status_form, user_updated: username}
                                    this.setState({car_notes: car_notes})
                                }}
                            />
                    </td>
                </tr>
            )
        })
        return (
            <div>
                <strong style={{fontSize:13}}>{note_type_name}</strong>
                <table>
                    <thead>
                    <tr>
                        <th className="text-nowrap" style={{fontSize:13}}>ดำเนินการ</th>
                        <th className="text-nowrap" style={{fontSize:13}}>สำเร็จแล้ว</th>
                        <th className="text-center" style={{fontSize:13}}>รายละเอียด</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        car_note_list
                    }
                    {
                        is_car_notes_change ? <tr>
                            <td colSpan={3}>
                                <button type="button" className="btn btn-primary"
                                 onClick={() => this.saveCarNotes()}
                                > บันทึก </button>
                            </td>
                        </tr> : null
                    }

                    </tbody>
                </table>
            </div>
        );
    }
}

export default CarNote
