import React, { Component } from 'react';
import { th } from 'date-fns/locale';
import axios from 'axios';
import { APIURL, CLOUDFRONT_URL, IMAGE_FULL_URL, IMAGE_NOPHOTO_URL } from '../../config/config';
import Loading from '../Loading';
import { DateUtil } from '../../utils/dateUtil';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { NumberUtil } from '../../utils/number-util';
// import { isNumber } from 'chart.js/helpers';
import { AlertError, AlertSuccess } from '../Alert/Alert';
import './table.css';
import {MonthUtil} from "../../utils/monthUtil";
import {format} from "date-fns";
import {
  titleStyle,
  contentStyle,
  contentBoldStyle,
  border
} from "../../utils/exceljsStyles";
import {ExcelUtil} from "../../utils/excelUtil";
// import * as XLSX from "xlsx-js-style";
// import * as FileSaver from "file-saver";
// import moment from "moment";

class TrackInformationCarIn extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      cars: [],
      car_status: [],
      car_parking_list: [],
      book_status_text_list: [],
      buy_car_from_list: [],
      liciense_status: [],
      branch: [],
      car_brands: [],
      car_gears: [],
      car_years: [],
      orders: [
        {order_id: 'car_in_desc', order_name: 'วันที่ซื้อรถเข้า : ใหม่ไปเก่า'},
        {order_id: 'car_in_asc', order_name: 'วันที่ซื้อรถเข้า : เก่าไปใหม่'},
        {order_id: 'car_brand_desc', order_name: 'ยี่ห้อ : มากไปน้อย'},
        {order_id: 'car_brand_asc', order_name: 'ยี่ห้อ : น้อยไปมาก'},
        {order_id: 'car_date_tax_desc', order_name: 'วันครบกำหนดภาษี : มากไปน้อย'},
        {order_id: 'car_date_tax_asc', order_name: 'วันครบกำหนดภาษี : น้อยไปมาก'},
        {order_id: 'year_desc', order_name: 'ปีรถ : ใหม่ไปเก่า'},
        {order_id: 'year_asc', order_name: 'ปีรถ : เก่าไปใหม่'},
        {order_id: 'overdue_date_tax_desc', order_name: 'ภาษีขาด : มากไปน้อย'},
        {order_id: 'overdue_date_tax_asc', order_name: 'ภาษีขาด : น้อยไปมาก'},
      ],
      width: 0,
      height: 0,
      order_by: '',
      park_location: '',
      car_status_id: '',
      book_status_text: '',
      liciense_status_id: '',
      buy_car_from: '',
      branch_id: '',
      car_brand_id: '',
      car_gear_id: '',
      car_year_id: '',
      month_year_list: [],
      carin_month_year: '',
      repair_status_text_search: '',
      car_license_plate: '',
      repair_status_text_list: []
    }

    this.loadCarData = this.loadCarData.bind(this);
    this.reloadCarData = this.reloadCarData.bind(this);
    this.onChangeInputRowLeft = this.onChangeInputRowLeft.bind(this);
    this.onChangeInputRowRight = this.onChangeInputRowRight.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.loadCarStatusData = this.loadCarStatusData.bind(this);
    this.loadLicienseStatus = this.loadLicienseStatus.bind(this);
    this.loadBranchData = this.loadBranchData.bind(this);
    this.loadCarBrandData = this.loadCarBrandData.bind(this);
    this.loadCarGearData = this.loadCarGearData.bind(this);
    this.loadCarYearData = this.loadCarYearData.bind(this);
    this.loadCarInNotSellMonthList = this.loadCarInNotSellMonthList.bind(this);
    this.getDataCarTrackInformationWithCriteria = this.getDataCarTrackInformationWithCriteria.bind(this);
    // this.exportExcel = this.exportExcel.bind(this);
    this.exportExcelWithImage = this.exportExcelWithImage.bind(this);
  }

  componentDidMount () {
    this.loadCarInNotSellMonthList();
    this.loadCarParking();
    this.loadCarStatusData();
    this.loadLicienseStatus();
    this.loadBranchData();
    this.loadCarBrandData();
    this.loadCarGearData();
    this.loadCarYearData();
    this.loadCarData();

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount () {
    this.mounted = false;
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  loadCarInNotSellMonthList() {
    let uri = APIURL + '/car/list/no_sell/month'
    axios.get(uri)
        .then(res => {
          if( res.data.length > 0) {
            let months = res.data

            let month_year_list =  months.map(month => {
              let month_list_thai = MonthUtil.convertMonthYear(month.month_list)
              return {
                ...month,
                month_list_thai: month_list_thai
              }
            });

            this.setState({
              month_year_list: month_year_list
            });

          }
        }).catch(error => {

    })
  }

  loadCarStatusData () {
    axios.get(APIURL + '/car_status/new')
      .then(res => {
        this.setState({car_status: res.data})
      }).catch(error => {
      this.setState({car_status: []})
    })
  }

  loadLicienseStatus() {
    axios.get(APIURL + '/liciense_status/')
      .then(res => {
        this.setState({liciense_status: res.data})
      }).catch(error => {
      this.setState({liciense_status: []})
    })
  }

  loadBranchData () {
    axios.get(APIURL + '/branch/')
      .then(res => {
        this.setState({branch: res.data})
      }).catch(error => {
      this.setState({branch: []})
    })
  }

  loadCarBrandData () {
    axios.get(APIURL + '/car_brand/')
      .then(res => {
        this.setState({car_brands: res.data})
      }).catch(error => {
      this.setState({car_brands: []})
    })
  }

  loadCarGearData () {
    axios.get(APIURL + '/car_gear/')
      .then(res => {
        this.setState({car_gears: res.data})
      }).catch(error => {
      this.setState({car_gears: []})
    })
  }

  loadCarYearData () {
    axios.get(APIURL + '/car_year/')
      .then(res => {
        this.setState({car_years: res.data})
      }).catch(error => {
      this.setState({car_years: []})
    })
  }

  reloadCarData() {
    this.setState({
      order_by: '',
      park_location: '',
      car_status_id: '',
      book_status_text: '',
      liciense_status_id: '',
      buy_car_from: '',
      branch_id: '',
      car_brand_id: '',
      car_gear_id: '',
      car_year_id: '',
      carin_month_year: '',
      repair_status_text_search: '',
      car_license_plate: ''
    }, () => this.loadCarData())
  }

  loadCarData() {

     this.setState({
       isLoading: true
     },() => {
       let uri = APIURL + '/car'
       axios.get(uri).then(res => {

         let cars = res.data
         let data = cars.map(car => {
           return {...car, car_edit: 1, edit_left: 1 ,edit_right: 1}
         });

         // book_status_text
         let _ = require('lodash');
         let bookStatusTextUniq = _.uniqBy(cars, 'book_status_text');
         let bookStatusSorted = _.orderBy(bookStatusTextUniq, ['book_status_text'], ['asc']);
         let bookStatusTextArrs = _.map(bookStatusSorted, 'book_status_text');

         let repairStatusTextUniq = _.uniqBy(cars, 'repair_status_text');
         let repairStatusTextSorted = _.orderBy(repairStatusTextUniq, ['repair_status_text'], ['asc']);
         let repairStatusText = _.map(repairStatusTextSorted, 'repair_status_text');
         let repairStatusFilter = repairStatusText.filter(text => text !== "")
         // console.log(repairStatusText)

         // let bookStatusTextFilter = _.filter(
         //   bookStatusTextUniq, function (o) {
         //     return o.book_status_text !== '';
         //   }
         // );

         let buyCarFromUniq = _.uniqBy(cars, 'buy_car_from');
         let buyCarFromSorted = _.orderBy(buyCarFromUniq, ['buy_car_from'], ['asc']);
         let buyCarFromArrs = _.map(buyCarFromSorted, 'buy_car_from');

         this.setState({
           cars: data,
           book_status_text_list: bookStatusTextArrs,
           repair_status_text_list: repairStatusFilter,
           buy_car_from_list: buyCarFromArrs,
           isLoading: false
         });
       })
     });
  }

  getDataCarTrackInformationWithCriteria() {
    let order_by = this.state.order_by
    let park_location = this.state.park_location
    let car_status_id = this.state.car_status_id
    let book_status_text = this.state.book_status_text
    let liciense_status_id = this.state.liciense_status_id
    let buy_car_from = this.state.buy_car_from
    let branch_id = this.state.branch_id
    let car_brand_id = this.state.car_brand_id
    let car_gear_id = this.state.car_gear_id
    let car_year_id = this.state.car_year_id
    let month_year_list = this.state.month_year_list
    let carin_month_year = this.state.carin_month_year
    let repair_status_text_search = this.state.repair_status_text_search
    let car_license_plate = this.state.car_license_plate
    let end_date = ''
    let start_date = ''

    if (carin_month_year !== '') {
      let month_year = month_year_list.filter(my => my.month_list === carin_month_year)
      end_date = month_year[0].end_date
      start_date = month_year[0].start_date
    }


    let data = {
      order_by,
      park_location,
      car_status_id,
      book_status_text,
      liciense_status_id,
      buy_car_from,
      branch_id,
      car_brand_id,
      car_gear_id,
      car_year_id,
      end_date,
      start_date,
      repair_status_text_search,
      car_license_plate
    }

    // trackcriteria
    let url = APIURL+'/car/trackcriteria'
    this.setState({
      isLoading: true
    }, () => {
      axios.post(url, data)
        .then(res => {
          this.setState({
            cars: res.data,
            isLoading: false
          })
        }).catch(error => {
        console.log(error)
      });
    });
  }

  exportExcelWithImage() {
    let cars = this.state.cars

    this.setState({
      isLoading: true
    })

    let excelData = cars.map((car, index) => {
      let order = index +1
      // let park_location = car.park_location
      let car_date_tax = car.car_date_tax
      let car_date_tax_text = DateUtil.convertShortDateThaiDash(car_date_tax)
      let date_ago = car_date_tax !== null && car_date_tax !== '0000-00-00' ? DateUtil.carDateTaxOverdue(car_date_tax) : '-'
      date_ago = date_ago === '-' ? '-' : date_ago

      let car_date_in = car.car_date_in
      let car_date_in_text = DateUtil.convertShortDateThaiDash(car_date_in)
      let car_license_plate_new = car.car_license_plate_new
      let car_license_plate_old = car.car_license_plate_old
      let car_license_plate = car_license_plate_new+' | ' +car_license_plate_old
      let car_brand_name = car.car_brand_name
      let car_model_name = car.car_model_name
      let car_sale_price = NumberUtil.addCommas(car.car_sale_price)
      return {
        order: order,
        thumbnail: car.car_upload_filename,
        park_location: car.park_location,
        car_status_name: car.car_status_name,
        repair_status_text: car.repair_status_text,
        book_status_text: car.book_status_text,
        liciense_status_name: car.liciense_status_name,
        buy_car_from: car.buy_car_from,
        branch_name: car.branch_name,
        date_ago: date_ago,
        car_date_tax_text: car_date_tax_text,
        car_date_in_text: car_date_in_text,
        car_license_plate: car_license_plate,
        car_brand_name: car_brand_name,
        car_model_name: car_model_name,
        car_gear_name: car.car_gear_name,
        car_color_name: car.car_color_name,
        car_year_name: car.car_year_name,
        car_sale_price: car_sale_price,
        car_upload_filename: car.car_upload_filename,
      }
    });

    const ExcelJS = require("exceljs/dist/exceljs");
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("ติดตามรถเข้า");

    /*let titleStyle = {
      name: "AngsanaUPC",
      family: 4,
      size: 18,
      bold: true,
    };

    let contentStyle = {
      name: "AngsanaUPC",
      family: 4,
      size: 16,
      bold: false,
    };

    let contentBoldStyle = {
      name: "AngsanaUPC",
      family: 4,
      size: 16,
      bold: true,
    };

    let border= {
      top: {style:'thin'},
      left: {style:'thin'},
      bottom: {style:'thin'},
      right: {style:'thin'}
    } */

    sheet.columns = [
      { header: "ลำดับ", key: "order", width: 6,},
      { header: "รูปรถ", key: "thumbnail", width: 16,},
      { header: "สถานที่จอด", key: "park_location", width: 16,},
      { header: "สถานะรถ", key: "car_status_name", width: 16,},
      { header: "ประเภทรถ", key: "repair_status_text", width: 16,},
      { header: "ที่อยู่เล่ม", key: "book_status_text", width: 16,},
      { header: "สถานะเล่ม", key: "liciense_status_name", width: 16,},
      { header: "หัวบิล/ซื้อรถจาก", key: "buy_car_from", width: 16,},
      { header: "เจ้าของ/สาขา", key: "branch_name", width: 16,},
      { header: "ภาษีขาด", key: "date_ago", width: 16,},
      { header: "วันครบกำหนดต่อภาษี", key: "car_date_tax_text", width: 16,},
      { header: "วันซื้อเข้า", key: "car_date_in_text", width: 16,},
      { header: "ทะเบียน", key: "car_license_plate", width: 16,},
      { header: "ยี่ห้อ", key: "car_brand_name", width: 16,},
      { header: "รุ่น", key: "car_model_name", width: 16,},
      { header: "เกียร์", key: "car_gear_name", width: 16,},
      { header: "สี", key: "car_color_name", width: 16,},
      { header: "ปี", key: "car_year_name", width: 16,},
      { header: "ราคาขาย", key: "car_sale_price", width: 16,},
    ]

    let curr = format(new Date(), 'dd/MM/yyyy HH:mm:ss')
    let currFileName = format(new Date(), 'yyyyMMdd_HHmmss')

    let rows = [
      ['ติดตามข้อมูลรถ'],
      ['พิมพ์วันที่ : '+curr],
    ]

    sheet.insertRows(1, rows);

    const promise = Promise.all(
        excelData.map(async (car, index) => {
          let rowNumber = index + 3;

          delete car.thumbnail
          sheet.addRow(car)

          // let image_url = CLOUDFRONT_URL+car.car_upload_key
          // console.log('image_url=', image_url)
          // const result = await ExcelUtil.toDataURL(image_url);

          const result = await ExcelUtil.toDataURL(car.car_upload_filename);
          const splitted = car.car_upload_filename.split(".");
          const extName = splitted[splitted.length - 1];

          const imageId2 = workbook.addImage({
            base64: result.base64Url,
            extension: extName,
          });

          sheet.getRow(rowNumber).eachCell((cell, colNumber ) =>  {
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
            cell.border = border;
          });

          if (rowNumber === 3) {
            sheet.getRow(rowNumber).font = contentBoldStyle
          } else {
            sheet.getRow(rowNumber).font = contentStyle
          }

          sheet.getRow(rowNumber+1).height = 80

          sheet.addImage(imageId2, {
            tl: { col: 1.1, row: rowNumber+0.1 },
            ext: { width: 96, height: 96 },
          });

          if((excelData.length-1) === index) {
            // set last row
            rowNumber = rowNumber +1
            sheet.getRow(rowNumber).eachCell((cell, colNumber ) =>  {
              cell.alignment = { vertical: 'middle', horizontal: 'center' };
              cell.border = border;
            });
            sheet.getRow(rowNumber).font = contentStyle;
          }
        })
    );

    promise.then(() => {
      sheet.getRow(1).font = titleStyle
      sheet.getRow(2).font = contentStyle
      sheet.getRow(3).font = contentBoldStyle
     // sheet.getRow(4).font = contentBoldStyle

      // B5
      for(let i=0; i < excelData.length; i++) {
        let row = i + 4
        let cellName = 'B'+String(row)
        sheet.getCell(cellName).border = border
        /*sheet.getCell(cellName).margins  = {
          insetmode: 'custom',
          inset: [0.25, 0.25, 0.35, 0.35]
        }*/
      }

      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        let curr = format(new Date(), 'yyyyMMdd_HHmmss')
        const fileName = 'ติดตามข้อมูลรถ_export_'+curr+'.xlsx'

        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = fileName;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });

      setTimeout(() => {
        this.setState({
          isLoading: false
        })
      } , 1200)

    });

  }

  /* แบบไม่มีภาพ
  exportExcel() {
    let cars = this.state.cars

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    let curr = format(new Date(), 'dd/MM/yyyy HH:mm:ss')
    // let currFileName = format(new Date(), 'yyyy-MM-dd')
    let currFileName = format(new Date(), 'yyyyMMdd_HHmmss')

    const title = [
      {
        A: 'ติดตามข้อมูลรถ',
      },
      {
        A: 'พิมพ์เมื่อ : ' + curr,
      }
    ]

    let rows_data = [
      {
        A: 'ลำดับ',
        B: 'รูป',
        C: 'สถานที่จอด',
        D: 'สถานะรถ',
        E: 'ประเภทรถ',
        F: 'ที่อยู่เล่ม',
        G: 'สถานะเล่ม',
        H: 'หัวบิล/ซื้อรถจาก',
        I: 'เจ้าของ/สาขา',
        K: 'ภาษีขาด',
        L: 'วันครบกำหนดต่อภาษี',
        M: 'วันซื้อเข้า',
        N: 'ทะเบียน',
        O: 'ยี่ห้อ',
        P: 'รุ่น',
        Q: 'เกียร์',
        R: 'สี',
        S: 'ปี',
        T: 'ราคาขาย'
      },
    ]

    cars.forEach((car, index) => {
      let order = index +1
      // let park_location = car.park_location
      let car_date_tax = car.car_date_tax
      let car_date_tax_text = DateUtil.convertShortDateThaiDash(car_date_tax)
      let date_ago = car_date_tax !== null && car_date_tax !== '0000-00-00' ? DateUtil.carDateTaxOverdue(car_date_tax) : '-'
      date_ago = date_ago === '-' ? '-' : date_ago

      let car_date_in = car.car_date_in
      let car_date_in_text = DateUtil.convertShortDateThaiDash(car_date_in)
      let car_license_plate_new = car.car_license_plate_new
      let car_license_plate_old = car.car_license_plate_old
      let car_license_plate = car_license_plate_new+' | ' +car_license_plate_old
      let car_brand_name = car.car_brand_name
      let car_model_name = car.car_model_name
      let car_sale_price = NumberUtil.addCommas(car.car_sale_price)
      let newRow = {
            A: order,
            B: 'รูป',
            C: car.park_location,
            D: car.car_status_name,
            E: car.repair_status_text,
            F: car.book_status_text,
            G: car.liciense_status_name,
            H: car.buy_car_from,
            I: car.branch_name,
            K: date_ago,
            L: car_date_tax_text,
            M: car_date_in_text,
            N: car_license_plate,
            O: car_brand_name,
            P: car_model_name,
            Q: car.car_gear_name,
            R: car.car_color_name,
            S: car.car_year_name,
            T: car_sale_price
          }
      rows_data.push(newRow)
    });

    const reportData = [...title, ...rows_data,]
    const cellTable = ExcelUtil.getBorderCell();

    this.setState({
      isLoading: true,
    }, () => {
      setTimeout(() => {
        const ws = XLSX.utils.json_to_sheet(reportData, { origin: 'A1', skipHeader: true });
        let wscols = [
          {wch:10},
          {wch:16},
          {wch:16},
          {wch:16},
          {wch:16},
          {wch:16},
          {wch:16},
          {wch:16},
          {wch:16},
          {wch:16},
          {wch:16},
          {wch:16},
          {wch:16},
          {wch:16},
          {wch:16},
          {wch:16},
          {wch:16},
          {wch:16},
          {wch:16},
        ];
        let last_row = cars.length + 2
        let last_col = 18
        let wsrows = [];
        for(let  i=1 ; i <=last_row + 1; i++){
          wsrows.push({hpt: 20})
        }

        ws['!cols'] = wscols;
        ws['!rows'] = wsrows;

        let rangeTitle = { s: {r:2, c:0}, e: {r:2, c: last_col} };
        ExcelUtil.setCellBoldWithBorder(ws, rangeTitle);

        let rangeOrder = { s: {r:3, c:0}, e: {r:last_row, c:0} };
        ExcelUtil.setCellCenterNormalWithBorder(ws, rangeOrder)

        let rangeNormal = { s: {r:3, c:1}, e: {r:last_row, c: last_col} };
        ExcelUtil.setCellNormalWithBorder(ws, rangeNormal)

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        let fileName = 'carin_'+currFileName
        FileSaver.saveAs(data, fileName + fileExtension);

        this.setState({
          isLoading: false,
        })

      }, 1200);
    })



  }
   */
  onChangeInputRowLeft(carSelect, index, event) {
    let car_id = carSelect.car_id
    let name = event.target.name
    let value = event.target.value

    let car_list = this.state.cars.map(car => car.car_id === car_id ? {...car, [name]: value, car_edit: 2, edit_left: 2 } : car )

    this.setState({
      cars: car_list
    });

  }

  onChangeInputRowRight(carSelect, index, event) {

    let car_id = carSelect.car_id
    let name = event.target.name
    let value = event.target.value
    let number_fields = [
      'finance_average_price',
      'monthly_installment_48',
      'monthly_installment_60',
      'monthly_installment_72',
      'monthly_installment_84',
      'monthly_installment_96'
    ]

    if (number_fields.includes(name)) {
      // check is number
      if(!NumberUtil.isNumber(value)) {
        AlertError('กรุณากรอกตัวเลข')
        // let car_list = this.state.cars.map(car => car.car_id === car_id ? {...car, [name]: value, car_edit: 2 } : car )
        //
        // this.setState({
        //   cars: car_list
        // });
        return
      }
    }

    let car_list = this.state.cars.map(car => car.car_id === car_id ? {...car, [name]: value, car_edit: 2, edit_right: 2 } : car )

    this.setState({
      cars: car_list
    });

  }

  onClickSave(car, index,) {
    let data = {
      park_location: car.park_location,
      repair_status_text: car.repair_status_text,
      sell_status_text: car.sell_status_text,
      book_status_text: car.book_status_text,
      // fb_page: car.fb_page,
      // finance_average_price: car.finance_average_price,
      // monthly_installment_48: car.monthly_installment_48,
      // monthly_installment_60: car.monthly_installment_60,
      // monthly_installment_72: car.monthly_installment_72,
      // monthly_installment_84: car.monthly_installment_84,
      // monthly_installment_96: car.monthly_installment_96,
      car_id: car.car_id,
    }


    let car_id = car.car_id
    this.setState({
      isLoading: true
    }, () => {

      let url = APIURL+'/car/trackinformation/'+car_id
      axios.put(url, data).then(res => {
        if(res.status === 200) {
          let car_list = this.state.cars.map(car => car.car_id === car_id ? {...car, car_edit: 1, edit_left: 1 ,edit_right: 1 } : car )
          this.setState({
            cars: car_list,
            isLoading: false
          }, () => {
            AlertSuccess('บันทึกข้อมูลเรียบร้อยแล้ว')
          });

        }
      })
        .catch(error => {
          AlertError("พบข้อผิดพลาด : " + error)
          this.setState({
            isLoading: false
          })
        })

    })
  }

  loadCarParking() {
    let url = APIURL+'/car_parking'
    axios.get(url).then(res => {
      let data = res.data
      this.setState({
        car_parking_list: data
      })
    })
  }

  render () {

    let {
      book_status_text_list,
      repair_status_text_list,
      cars,
      width,
      car_parking_list,
      car_status,
      liciense_status,
      buy_car_from_list,
      branch,
      car_brands,
      car_gears,
      orders,
      month_year_list
    } = this.state

    // let cars = this.state.cars
    // let width = this.state.width

    let width_box = 100

    if (width <= 980){
      width_box = 80
    }

    let carParkingOptions = car_parking_list.map((parking, index) => {
      return <option value={parking.car_parking_name} key={index}> {parking.car_parking_name} </option>
    })

    let carStatusOptions = car_status.map((status, index) => {
      return <option value={status.car_status_id} key={index}> {status.car_status_name} </option>
    })

    let bookStatusOptions = book_status_text_list.map((book_status_text, index) => {
      let book_status_str = book_status_text === '' ? 'ไม่ระบุ': book_status_text
      let book_status_val = book_status_text === '' ? 'empty': book_status_text
      return <option value={book_status_val} key={index}> {book_status_str} </option>
    })

    let repairStatusOptions = repair_status_text_list.map((repair_status_text, index) => {
      return <option value={repair_status_text} key={index}> {repair_status_text} </option>
    })

    let licienseStatusOptions = liciense_status.map((liciense_status, index) => {
      return (
        <option key={index} value={liciense_status.liciense_status_id}>{liciense_status.liciense_status_name}</option>
      )
    })

    let buyCarFromOptions = buy_car_from_list.map((buy_car_from, index) => {
      return <option value={buy_car_from} key={index}> {buy_car_from} </option>
    })

    let carBranchOptions = branch.map((branch, index) => {
      return (
        <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
      )
    })

    let carBrandOptions = car_brands.map((car_brand, index) => {
      return (
        <option key={index} value={car_brand.car_brand_id}>{car_brand.car_brand_name}</option>
      )
    })

    let carGearOptions = car_gears.map((car_gear, index) => {
      return (
        <option key={index} value={car_gear.car_gear_id}>{car_gear.car_gear_name}</option>
      )
    })

    let carYearOptions = this.state.car_years.map((car_year, index) => {
      return (
        <option key={index} value={car_year.car_year_id}>{car_year.car_year_name}</option>
      )
    })

    let order_list = orders.map((order, index) => {
      return (
        <option key={index} value={order.order_id}>{order.order_name}</option>
      )
    })

    let month_year_list_option = month_year_list.map((month_year, index) => (
        <option key={index} value={month_year.month_list}>{month_year.month_list_thai}</option>
    ));


    let carList = cars.map((car, index) => {

      let car_date_tax = car.car_date_tax
      let car_date_tax_text = DateUtil.convertShortDateThaiDash(car_date_tax)
      let car_date_in = car.car_date_in
      let car_date_in_text = DateUtil.convertShortDateThaiDash(car_date_in)
      let car_license_plate_new = car.car_license_plate_new
      let car_license_plate_old = car.car_license_plate_old
      let car_brand_name = car.car_brand_name
      let car_model_name = car.car_model_name
      let car_sale_price = NumberUtil.addCommas(car.car_sale_price)

      let date_ago = car_date_tax !== null && car_date_tax !== '0000-00-00' ? DateUtil.carDateTaxOverdue(car_date_tax) : '-'

      // let repair_status = ''
      // let sell_status = ''
      // let book_status = ''
      // let finance_average_price = ''
      // let monthly_installment_4 = ''
      // let monthly_installment_5 = ''
      // let monthly_installment_6 = ''
      // let monthly_installment_7 = ''
      // let monthly_installment_8 = ''

      let image_url = ''
      if (car.car_upload_filename !== null) {
        if (car.car_upload_filename.includes('https')) {
          // image_url = car.car_upload_filename
          let car_upload_key = car.car_upload_key
          image_url = CLOUDFRONT_URL+car_upload_key
        } else {
          image_url = IMAGE_FULL_URL + car.car_upload_filename
        }
      } else {
        image_url = IMAGE_NOPHOTO_URL
      }

      let order = index+1
      return <tr key={index}>
        <td>
          {
            car.edit_left === 2 ?
              <button
                className={car.car_edit === 1 ? "btn btn-sm btn-success" : (car.car_edit === 2) ? "btn btn-sm btn-warning" : "btn btn-sm " }
                disabled={car.car_edit===3}
                onClick={() => this.onClickSave(car, index)}
              > บันทึก </button>
              :  order
          }
        </td>
        <td>
          <LazyLoadImage src={image_url}
                         width={80}
                         height="auto"
                         effect="blur"
          />
        </td>
        <td>
          <select className="form-control"
                  name="park_location"
                  style={{...styles.selectFilter, width: width_box}}
                  value={this.state.cars[index].park_location}
                  onChange={(event)=> this.onChangeInputRowLeft(car, index, event)}
          >
            {carParkingOptions}
          </select>
        </td>
        <td>
          {car.car_status_name}
        </td>
        <td>
           <input
             type="text"
             name="repair_status_text"
             style={styles.input}
             maxLength={50}
             value={this.state.cars[index].repair_status_text}
             onChange={(event)=> this.onChangeInputRowLeft(car, index, event)}
           />
        </td>
        {/*<td>
          <input
            type="text"
            name="sell_status_text"
            style={styles.input}
            maxLength={50}
            value={this.state.cars[index].sell_status_text}
            onChange={(event)=> this.onChangeInputRowLeft(car, index, event)}
          />
        </td>*/}
        <td>
          <input name="book_status_text" style={styles.input}
                 maxLength={50}
                 value={this.state.cars[index].book_status_text}
                 onChange={(event)=> this.onChangeInputRowLeft(car, index, event)}
          />
        </td>
        <td> {car.liciense_status_name}</td>
        <td> {car.buy_car_from}</td>
        <td> {car.branch_name}</td>
        <td> {date_ago === '-' ? '-' : (<span className="text-danger">{date_ago}</span>)} </td>
        <td> {car_date_tax_text} </td>
        <td> {order} </td>
        <td> {car_date_in_text} </td>
        <td className=" text-nowrap"> ใหม่ : {car_license_plate_new} <br/> เดิม : {car_license_plate_old} </td>
        <td> {car_brand_name} </td>
        <td> {car_model_name} </td>
        <td> {car.car_gear_name} </td>
        <td> {car.car_color_name} </td>
        <td> {car.car_year_name} </td>

       {/* <td>
          <input type="text" name="fb_page" style={styles.input} maxLength={50}
                 value={this.state.cars[index].fb_page}
                 onChange={(event)=> this.onChangeInputRowRight(car, index, event)}
          />
        </td>*/}
        <td>{car_sale_price}</td>

            {/* <td>
          <input
            type="text"
            maxLength={8}
            name="finance_average_price"
            style={styles.input}
            value={this.state.cars[index].finance_average_price}
            onChange={(event)=> this.onChangeInputRowRight(car, index, event)}
          />
        </td>
        <td>
          <input name="monthly_installment_48"
                 maxLength={8}
                 type="text"
                 style={styles.input}
                 value={this.state.cars[index].monthly_installment_48}
                 onChange={(event)=> this.onChangeInputRowRight(car, index, event)}
          />
        </td>
        <td>
          <input
            name="monthly_installment_60"
            style={styles.input}
            type="text"
            maxLength={8}
            value={this.state.cars[index].monthly_installment_60}
            onChange={(event)=> this.onChangeInputRowRight(car, index, event)}
          />
        </td>
        <td>
          <input name="monthly_installment_72"
                 style={styles.input}
                 type="text"
                 maxLength={8}
                 value={this.state.cars[index].monthly_installment_72}
                 onChange={(event)=> this.onChangeInputRowRight(car, index, event)}
          />
        </td>
        <td>
          <input name="monthly_installment_84"
                 style={styles.input}
                 type="text"
                 maxLength={8}
                 value={this.state.cars[index].monthly_installment_84}
                 onChange={(event)=> this.onChangeInputRowRight(car, index, event)}
          />
        </td>
        <td>
          <input name="monthly_installment_96"
                 style={styles.input}
                 type="text"
                 maxLength={8}
                 value={this.state.cars[index].monthly_installment_96}
                 onChange={(event)=> this.onChangeInputRowRight(car, index, event)}
          />
        </td>*/}

        <td className="text-center" >
          {
            car.edit_right === 2 ?
              <button
                className={car.car_edit === 1 ? "btn btn-sm btn-success" : (car.car_edit === 2) ? "btn btn-sm btn-warning" : "btn btn-sm " }
                disabled={car.car_edit===3}
                onClick={() => this.onClickSave(car, index)}
              > บันทึก </button>
              :  <i className="icon-close" />
          }
        </td>

      </tr>
    })



    return (
      <div className="row">
        <Loading isLoading={this.state.isLoading} />

        <div className="col-sm-12 col-md-12">
          <div className="card card-accent-primary">
            <div className="card-header card-customer">
             <strong>ติดตามข้อมูลรถคงเหลือ {cars.length} คัน</strong>
              &nbsp;&nbsp;
              <button
                  type="button"
                  className="btn btn-sm btn-head-bar ml-2"
                  style={styles.buttonHeight}
                  onClick={() => {
                    this.reloadCarData()
                  }}
              >
                <i className="icon-refresh"/>
              </button>
            </div>
            <div className="card-block">
              <div className="row">
                <div className="col-12 form-inline">

                  <select
                      className="form-control mr-1"
                      style={{...styles.selectFilter, width: 120}}
                      name="carin_month_year"
                      value={this.state.carin_month_year}
                      onChange={(e) => {
                        let month_list = e.target.value
                        this.setState({
                          carin_month_year: month_list,
                        });
                      }}
                  >
                    <option value=''>เลือกเดือน</option>
                    {month_year_list_option}
                  </select>

                  <select className="form-control mr-1 mb-1"
                          name="park_location"
                          style={{...styles.selectFilter, width: 120}}
                          value={this.state.park_location}
                          onChange={(e) => {
                            this.setState({park_location: e.target.value})
                          }}
                  >
                    <option value="">สถานที่จอด</option>
                    {carParkingOptions}
                  </select>

                  <select className="form-control mr-1 mb-1"
                          name="park_location"
                          style={{...styles.selectFilter, width: 120}}
                          value={this.state.car_status_id}
                          onChange={(e) => {
                            this.setState({car_status_id: e.target.value})
                          }}
                  >
                    <option value="">สถานะรถ</option>
                    {carStatusOptions}
                  </select>

                  <select className="form-control mr-1 mb-1"
                          name="book_status_text"
                          style={{...styles.selectFilter, width: 120}}
                          value={this.state.book_status_text}
                          onChange={(e) => {
                            this.setState({book_status_text: e.target.value})
                          }}
                  >
                    <option value="">ที่อยู่เล่ม</option>
                    {bookStatusOptions}
                  </select>

                  <select className="form-control mr-1 mb-1"
                          name="book_status_text"
                          style={{...styles.selectFilter, width: 120}}
                          value={this.state.liciense_status_id}
                          onChange={(e) => {
                            this.setState({liciense_status_id: e.target.value})
                          }}
                  >
                    <option value="">สถานะเล่ม</option>
                    {licienseStatusOptions}
                  </select>

                  <select className="form-control mr-1 mb-1"
                          name="buy_car_from"
                          style={{...styles.selectFilter, width: 130}}
                          value={this.state.buy_car_from}
                          onChange={(e) => {
                            this.setState({buy_car_from: e.target.value})
                          }}
                  >
                    <option value="">หัวบิล/ซื้อรถจาก</option>
                    {buyCarFromOptions}
                  </select>

                  <select className="form-control mr-1 mb-1"
                          name="branch_id"
                          style={{...styles.selectFilter, width: 120}}
                          value={this.state.branch_id}
                          onChange={(e) => {
                            this.setState({branch_id: e.target.value})
                          }}
                  >
                    <option value="">เจ้าของ/สาขา</option>
                    {carBranchOptions}
                  </select>

                  <select className="form-control mr-1 mb-1"
                          name="car_brand_id"
                          style={{...styles.selectFilter, width: 120}}
                          value={this.state.car_brand_id}
                          onChange={(e) => {
                            this.setState({car_brand_id: e.target.value})
                          }}
                  >
                    <option value="">ยี่ห้อ</option>
                    {carBrandOptions}
                  </select>



                </div>
              </div>
              <div className="row">
                <div className="col-12 form-inline">

                  <select className="form-control mr-1 mb-1"
                          name="car_gear_id"
                          style={{...styles.selectFilter, width: 120}}
                          value={this.state.car_gear_id}
                          onChange={(e) => {
                            this.setState({car_gear_id: e.target.value})
                          }}
                  >
                    <option value="">เกียร์</option>
                    {carGearOptions}
                  </select>

                  <select className="form-control mr-1 mb-1"
                          name="car_year_id"
                          style={{...styles.selectFilter, width: 80}}
                          value={this.state.car_year_id}
                          onChange={(e) => {
                            this.setState({car_year_id: e.target.value})
                          }}
                  >
                    <option value="">ปีรถ</option>
                    {carYearOptions}
                  </select>

                  <select className="form-control mr-1 mb-1"
                          name="order_by"
                          style={{...styles.selectFilter, width: 180}}
                          value={this.state.order_by}
                          onChange={(e) => {
                            this.setState({order_by: e.target.value})
                          }}
                  >
                    <option value="">เรียงตาม</option>
                    {order_list}
                  </select>


                 {/* <input
                      className="form-control mr-1 ml-2"
                      style={{width: 120}}
                      placeholder={'ประเภทรถ'}
                      value={this.state.repair_status_text_search}
                      name="repair_status_text_search"
                      onChange={(e) => {
                        this.setState({repair_status_text_search: e.target.value})
                      }}
                  />*/}

                  <select className="form-control mr-1 mb-1"
                          name="order_by"
                          style={{...styles.selectFilter, width: 120}}
                          value={this.state.repair_status_text_search}
                          onChange={(e) => {
                            this.setState({repair_status_text_search: e.target.value})
                          }}
                  >
                    <option value="">ประเภทรถ</option>
                    {repairStatusOptions}
                  </select>

                  <input
                      className="form-control mr-1 ml-2"
                      style={{width: 150}}
                      placeholder={'ทะเบียนรถ'}
                      value={this.state.car_license_plate}
                      name="car_license_plate"
                      onChange={(e) => {
                        this.setState({car_license_plate: e.target.value})
                      }}
                  />


                  <button
                      className="btn btn-sm btn-primary mr-1 ml-2"
                      onClick={() => this.getDataCarTrackInformationWithCriteria()}
                      style={{...styles.buttonHeight, marginTop: 2}}
                  >
                    <i className="icon-search"/>ค้นหา
                  </button>


                  <button
                      className="btn btn-sm btn-success mr-1 ml-2"
                      onClick={() => this.exportExcelWithImage()}
                      style={{...styles.buttonHeight, marginTop: 2}}
                  >
                    <i className="icon-search"/>Excel
                  </button>

                </div>
              </div>
              <div className="row">
                <div className="col-12 tableFixHead" >
                      <table className="table table-striped table-responsive">
                        <thead>
                        <tr>
                          <th className="text-center th-sticky" >ลำดับ</th>
                          <th style={{width: 150}} className=" text-nowrap">รูป</th>
                          <th style={{width: 150}} className=" text-nowrap th-sticky">สถานที่จอด</th>
                          <th style={{width: 150}} className=" text-nowrap">สถานะรถ</th>
                          <th style={{width: 150}} className=" text-nowrap">ประเภทรถ</th>
                          {/*<th style={{width: 150}} className=" text-nowrap">สถานะขาย</th>*/}
                          <th style={{width: 150}} className=" text-nowrap text-center">ที่อยู่เล่ม</th>
                          <th style={{width: 150}} className=" text-nowrap">สถานะเล่ม</th>
                          <th style={{width: 150}} className=" text-nowrap">หัวบิล/ซื้อรถจาก</th>
                          <th style={{width: 150}} className=" text-nowrap">เจ้าของ/สาขา</th>
                          <th style={{width: 150}} className=" text-nowrap">ภาษีขาด</th>
                          <th style={{width: 150}} className=" text-nowrap">วันครบกำหนดต่อภาษี</th>
                          <th className="text-center th-sticky" >ลำดับ</th>
                          <th style={{width: 150}} className=" text-nowrap">วันซื้อเข้า</th>
                          <th style={{width: 150}} className=" text-nowrap">ทะเบียน</th>
                          <th style={{width: 150}} className=" text-nowrap">ยี่ห้อ</th>
                          <th style={{width: 150}} className=" text-nowrap">รุ่น</th>
                          <th style={{width: 150}} className=" text-nowrap">เกียร์</th>
                          <th style={{width: 150}} className=" text-nowrap">สี</th>
                          <th style={{width: 150}} className=" text-nowrap">ปี</th>

                         {/* <th style={{width: 150}} className=" text-nowrap">มาร์เก็ต</th>*/}
                          <th style={{width: 150}} className=" text-nowrap">ราคาขาย</th>
                          {/*<th style={{width: 150}} className=" text-nowrap">ราคากลาง</th>
                          <th style={{width: 150}} className=" text-nowrap">ผ่อน 4 ปี (48 งวด)</th>
                          <th style={{width: 150}} className=" text-nowrap">ผ่อน 5 ปี (60 งวด)</th>
                          <th style={{width: 150}} className=" text-nowrap">ผ่อน 6 ปี (72 งวด)</th>
                          <th style={{width: 150}} className=" text-nowrap">ผ่อน 7 ปี (84 งวด)</th>
                          <th style={{width: 150}} className=" text-nowrap">ผ่อน 8 ปี (96 งวด)</th>*/}
                          <th style={{width: 150}} className=" text-nowrap">จัดการ</th>
                        </tr>
                        </thead>
                        <tbody>
                        {carList}
                        </tbody>
                      </table>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  input: {
    width: 80,
  },
  searchCar: {
    marginLeft: 0,
    marginTop: 5,
    marginBottom: -8,
    width: 180,
    borderRadius:5,
    display: 'inline'
  },
  buttonHeight: {
    height: 36,
    paddingTop: 0,
    paddingBottom:0,
    borderRadius:5,
    display: 'inline',
    marginTop: 0,
    marginBottom: 4,
  },
  selectFilter: {
    marginTop: 5,
    marginBottom: 0,
    borderRadius:5,
    display: 'inline'
  },
  overflowY: {
    overflow: 'scroll',
  }
}

export default TrackInformationCarIn
