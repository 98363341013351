import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import {connect} from 'react-redux';
import 'moment/locale/th';
import BranchList from "../Branch/BranchList";
import EmployeeList from "../Employee/EmployeeList";
import BankList from "../Bank/BankList";
import MaintenanceCenterList from "../MaintenanceCenter/MaintenanceCenterList";
import AccountList from "../Account/AccountList";
import CompanySetting from '../CompanySetting';
import CarModelList from "../CarModel/CarModelList";
import { PORT } from '../../config/config'
import SettingCar from './SettingCar'
import {CarSellUtil, CarsUtil} from "../../utils/carSellUtil";
import ImportExcel from "./ImportExcel/ImportExcel";
import SettingSales from "./SettingSales";
import { checkGroupPermission, getGroupPermission } from '../../services/grouppermission'
import SettingDebtors from "./SettingDebtors";

// const PORT = window.env.PORT;

class Setting extends Component{
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            user_type: '',
            activeTab: '1',
            width: 0,
            menuID: [1601, 1602, 1603, 1604, 1605, 1606, 1607, 1608, 1609],
            grouppermissions: [],
        };

        this.toggle = this.toggle.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.getUserSettingTab = this.getUserSettingTab.bind(this);
        this.loadGroupPermission = this.loadGroupPermission.bind(this);
    }

    componentWillMount () {
        // const queryString = require('query-string'); 8
        let user = JSON.parse(this.props.user);
        if (PORT === 9021 && user.type === 'account') {
            this.setState({
                user: user,
                user_type: user.type,
                activeTab: '8'
            })
        } else if (PORT === 9059 && user.type === 'manager') {
            this.setState({
                user: user,
                user_type: user.type,
                activeTab: '8'
            })
        } else if (PORT === 9008 && user.type === 'sale') {
            this.setState({
                user: user,
                user_type: user.type,
                activeTab: '8'
            })
        } else {
            this.setState({
                user: user ,
                user_type : user.type
            })
        }

    }


    loadGroupPermission() {
        let user_type = this.state.user_type
        let menuID = this.state.menuID

        getGroupPermission(user_type, menuID).then(res => {
            if(res.data.result === 'success') {
                this.setState({
                    grouppermissions: res.data.grouppermissions
                })
            }
        })
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    componentDidMount() {
        CarsUtil.removeCarSelect()
        CarSellUtil.removeCarSellSelect()

        this.loadGroupPermission()
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    getUserSettingTab() {
        // let user_type = this.state.user_type
        // goodcar เฉพาะ username=admin ถึงเพิ่ม user ได้
        if (PORT === 9010 && this.state.user.user !== 'admin') {
            return null
        } else {
            if (checkGroupPermission(1601, this.state.grouppermissions).viewed === 1) {
                return <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '6' })}
                      onClick={() => {
                          this.toggle('6');
                      }}
                    >
                        <i className="icon-shield"/> ตั้งค่าผู้ใช้งาน
                    </NavLink>
                </NavItem>
            } else {
                return null
            }
            // if (user_type === 'admin') {
            //
            // }
            // return null
        }
    }

    render(){

        // let width = this.state.width
        // let user = this.state.user
        // let user_type = this.state.user_type

        return(
            <div className="col-md-12 mb-4">

                <Nav tabs>

                    {
                        checkGroupPermission(1602, this.state.grouppermissions).viewed === 1 ?
                          <NavItem>
                              <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}
                              >
                                  <i className="icon-options-vertical"/> ข้อมูลทั่วไป
                              </NavLink>
                          </NavItem>
                          : null
                    }

                    {
                        checkGroupPermission(1603, this.state.grouppermissions).viewed === 1 ?
                          <NavItem>
                              <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}
                              >
                                  <i className="icon-badge"/> ตั้งค่าสาขา
                              </NavLink>
                          </NavItem>
                          : null
                    }


                    {
                        checkGroupPermission(1604, this.state.grouppermissions).viewed === 1 ?
                          <NavItem>
                              <NavLink
                                className={classnames({ active: this.state.activeTab === '3' })}
                                onClick={() => { this.toggle('3'); }}
                              >
                                  <i className="icon-user"/> ตั้งค่าพนักงาน
                              </NavLink>
                          </NavItem>
                          : null
                    }


                    {
                        checkGroupPermission(1605, this.state.grouppermissions).viewed === 1 ?
                          <NavItem>
                              <NavLink
                                className={classnames({ active: this.state.activeTab === '4' })}
                                onClick={() => { this.toggle('4'); }}
                              >
                                  <i className="icon-organization"/> ตั้งค่าธนาคาร/ลิสซิ่ง
                              </NavLink>
                          </NavItem>
                          : null
                    }


                    {
                        checkGroupPermission(1606, this.state.grouppermissions).viewed === 1 ?
                          <NavItem>
                              <NavLink
                                className={classnames({ active: this.state.activeTab === '8' })}
                                onClick={() => { this.toggle('8'); }}
                              >
                                  <i className="icon-target"/> ตั้งค่าข้อมูลรถ
                              </NavLink>
                          </NavItem>
                          : null
                    }


                    {
                        checkGroupPermission(1607, this.state.grouppermissions).viewed === 1 ?
                          <NavItem>
                              <NavLink
                                className={classnames({ active: this.state.activeTab === '5' })}
                                onClick={() => { this.toggle('5'); }}
                              >
                                  <i className="icon-wrench"/> ตั้งค่าศูนย์ซ่อมบำรุง
                              </NavLink>
                          </NavItem>
                          : null
                    }

                    {
                        checkGroupPermission(1608, this.state.grouppermissions).viewed === 1 ?
                          <NavItem>
                              <NavLink
                                className={classnames({ active: this.state.activeTab === '7' })}
                                onClick={() => { this.toggle('7'); }}
                              >
                                  <i className="icon-basket"/> ตั้งค่าการขาย
                              </NavLink>
                          </NavItem>
                          : null
                    }

                    {
                        checkGroupPermission(1608, this.state.grouppermissions).viewed === 1 ?
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '10' })}
                                    onClick={() => { this.toggle('10'); }}
                                >
                                    <i className="icon-people"/> ตั้งค่าลูกหนี้
                                </NavLink>
                            </NavItem>
                            : null
                    }

                    {
                        this.getUserSettingTab()
                    }

                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <div className="row">
                            <div className="col-lg-12">
                                <CompanySetting />
                            </div>
                        </div>
                    </TabPane>

                    <TabPane tabId="2">
                        <div className="row">
                            <div className="col-lg-12">
                                <BranchList/>
                            </div>
                        </div>
                    </TabPane>

                    <TabPane tabId="3">
                        <div className="row">
                            <div className="col-lg-12">
                                <EmployeeList />
                            </div>
                        </div>
                    </TabPane>

                    <TabPane tabId="4">
                        <div className="row">
                            <div className="col-lg-12">
                                <BankList/>
                            </div>
                        </div>
                    </TabPane>

                    <TabPane tabId="5">
                        <div className="row">
                            <div className="col-lg-12">
                                <MaintenanceCenterList/>
                            </div>
                        </div>
                    </TabPane>

                    <TabPane tabId="6">
                        <div className="row">
                            <div className="col-lg-12">
                                <AccountList/>
                            </div>
                        </div>
                    </TabPane>

                    <TabPane tabId="7">
                        <div className="row">
                            <div className="col-lg-12">
                                {
                                    this.state.activeTab === '7' && <SettingSales />
                                }
                            </div>
                        </div>
                    </TabPane>

                    <TabPane tabId="8">
                        <div className="row">
                            <div className="col-lg-12">
                                {
                                    this.state.activeTab === '8' && <SettingCar />
                                }
                            </div>
                        </div>
                    </TabPane>

                    <TabPane tabId="10">
                        <div className="row">
                            <div className="col-lg-12">
                                {
                                    this.state.activeTab === '10' && <SettingDebtors />
                                }
                            </div>
                        </div>
                    </TabPane>

                    <TabPane tabId="9">
                        <div className="row">
                            <div className="col-lg-12">
                                <ImportExcel/>
                            </div>
                        </div>
                    </TabPane>

                </TabContent>
            </div>
        )
    }

}


// const styles = {
//     bottomLine: {
//         borderBottomColor: '#ccc',
//         borderBottomStyle: 'solid',
//         borderWidth: 0.5,
//     },
//     toUpper: {
//         textTransform: 'uppercase',
//     }
// }

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(Setting);
