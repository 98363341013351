import React, { Component } from 'react'
import Loading from '../Loading'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import axios from 'axios'
import { APIURL, PORT } from '../../config/config'
import { connect } from 'react-redux'
import { save } from 'save-file';
import { AlertWarning } from '../Alert/Alert'
import {CarUtil} from "../../utils/carUtil";
import {NumberUtil} from "../../utils/number-util";
import {getFormControlName} from "../../services/grouppermission";
import {
    getRefundDifference,
    getSumCostAfterSell,
    getSumCostBeforeSell,
    getSumCostFinanceAfterSell,
    getSumCostSellPayByCustomer
} from "../../services/costProfitService";
import Select from "react-select";
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const dot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
    },
});


class SellReport extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      date_start: new Date(),
      date_end: new Date(),
      sum_price_normal: 0,
      sum_price: 0,
      sum_profit: 0,
      sum_car_cost: 0,
      sum_car_cost_commissions: 0,
      sum_car_cost_repair: 0,
      sum_car_cost_other: 0,
      sum_car_cost_tax: 0,
      sum_car_cost_act: 0,
      //
      sum_car_external_commission: 0,
      sum_sales_commission: 0,
      sum_additional_commission: 0,
      sum_advertising_cost: 0,
      sum_delivery_fee: 0,
      sum_promotion_fee: 0,
      //
      sum_finance_fee: 0,
      sum_transfer_fee: 0,
      //
      sum_vat_sell: 0,
      sum_finance_commission: 0,
      sum_vat_commission: 0,
      lists: [],
      sum_cost_phra: 0,
      sum_cost_vat_and_property_tax: 0,

      person_vat_id: '0',

      employee_id : '',
      employees:[],
     //
     sum_cost_operation: 0,
     sum_cost_service: 0,
     sum_cost_transport: 0,
     sum_cost_porobor_lan: 0,
     sum_cost_bangkhab_phasi_lan: 0,
     sum_cost_bangkhab_xon_lan: 0,
     sum_cost_check_ton: 0,
     sum_cost_after_sales_service: 0,

     sum_tax_invoice_vat: 0,
     sum_vat_buy: 0,
     sum_car_cost_vat: 0,
     sum_financing_amount: 0,
     sum_tax_invoice_amount: 0,
     sum_down_total: 0,
     sum_amount_of_debt: 0,
        sum_expense_finance: 0,
     form_controls: [],

     branches: [],
     branch_id: '',
     branches_select:[],
        bank: [],
        bank_finance_id: ''
    }
    this.handleDateStart = this.handleDateStart.bind(this)
    this.handleDateEnd = this.handleDateEnd.bind(this)
    this.loadSellData = this.loadSellData.bind(this)
    this.loadEmployeeData = this.loadEmployeeData.bind(this)
    this.loadFormControls = this.loadFormControls.bind(this)
    this.loadBranchData = this.loadBranchData.bind(this)
    this.loadBankLeasingData = this.loadBankLeasingData.bind(this)
  }

    loadFormControls() {
        let tableName = "car_sell";
        axios.get(APIURL + '/form_controls/'+tableName)
            .then(res => {
                this.setState({form_controls: res.data});
            }).catch(error => {
            this.setState({form_controls: []})
        })
    }

  handleDateStart(date){
    this.setState({
      date_start: date
    })
  }

  handleDateEnd(date){
    this.setState({
      date_end: date
    })
  }

    componentDidMount() {
        this.mounted = true
        this.loadBankLeasingData();
        this.loadEmployeeData();
        this.loadFormControls();
        this.loadBranchData();
    }

    loadBankLeasingData () {
        // axios.get(APIURL + '/bank/')
        axios.get(APIURL + '/bank/get/is_leasing')
            .then(res => {
                this.setState({bank: res.data})
                //alert (res.data)
            }).catch(error => {
            this.setState({bank: []})
        })
    }

  loadSellData() {

    if(this.state.date_start > this.state.date_end){
      AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    const employee_id = this.state.employee_id
    const person_vat_id = this.state.person_vat_id
    const branch_id = this.state.branch_id
    const branches_select = this.state.branches_select
    const bank_finance_id = this.state.bank_finance_id

    const data = {
          start_date : start_date,
          end_date : end_date,
          employee_id : employee_id,
          person_vat_id : person_vat_id,
          branch_id : branch_id,
          branches_select : branches_select,
          bank_finance_id : bank_finance_id,
    };
    // console.log(data);
    // return

    this.setState({
      isLoading: true
    })

      axios.post(APIURL + '/report/sell/', data)
      .then(res => {
        let sum_price_normal = 0
        let sum_price = 0
        let sum_car_cost = 0
        let sum_car_cost_repair = 0
        let sum_car_cost_other = 0
        let sum_car_cost_other2 = 0
        let sum_profit = 0
        let sum_car_cost_tax = 0
        let sum_car_cost_act = 0
        //
        let sum_vat_sell = 0
        let sum_finance_commission = 0
        let sum_vat_commission = 0
        let sum_car_external_commission = 0
        let sum_sales_commission = 0
        let sum_additional_commission = 0
        let sum_advertising_cost = 0
        let sum_delivery_fee = 0
        let sum_promotion_fee = 0
        let sum_finance_fee = 0
        let sum_transfer_fee = 0
        //
        let sum_car_cost_commissions = 0
        let sum_cost_phra = 0
        let sum_cost_vat_and_property_tax = 0

        let  person_vat_id = this.state.person_vat_id
        let  employee_id = this.state.employee_id

        let  branch_id = this.state.branch_id

          //
          let sum_cost_operation = 0
          let sum_cost_service = 0
          let sum_cost_transport = 0
          let sum_cost_porobor_lan = 0
          let sum_cost_bangkhab_phasi_lan = 0
          let sum_cost_bangkhab_xon_lan = 0
          let sum_cost_check_ton = 0
          let sum_cost_after_sales_service = 0

          let sum_tax_invoice_vat = 0
          let sum_vat_buy = 0
          let sum_tax_invoice_amount = 0

          let sum_car_cost_vat = 0
          let sum_financing_amount = 0
          let sum_down_total = 0
          let sum_down_not_profit = 0
          let sum_amount_of_debt = 0
          let sum_expense_finance = 0

          if(res.data.length > 0) {

          sum_price_normal =  res.data.reduce((acc, next) => acc + next.normal_sell_price, 0)
          //sum_price =  res.data.reduce((acc, next) => acc + next.real_sell_price, 0) //ราคาขายจริง
          sum_price =  res.data.reduce((acc, next) => {
              let tmp = 0

              if(PORT === 9048){
                  if(next.car_sell_type_id === 1){
                      tmp  = next.tax_invoice_amount === null || next.tax_invoice_amount === 0 ? next.real_sell_price : next.tax_invoice_amount
                  }else {
                      tmp  = next.tax_invoice_amount === null || next.tax_invoice_amount === 0 ? next.rate_finance : next.tax_invoice_amount
                  }
                  //console.log('ทะเบียน', next.car_license_plate_new, tmp, next.car_sell_type_id, typeof next.car_sell_type_id)
              }else {
                  if(next.car_sell_type_id === 1) {
                      tmp = next.tax_invoice_amount > 0 ? next.tax_invoice_amount : next.real_sell_price
                  }else {
                      tmp  = next.tax_invoice_amount === null || next.tax_invoice_amount === 0 ? next.rate_finance : next.tax_invoice_amount
                      /*if (next.sum_down !== 0) {
                          tmp += next.sum_down
                      }*/
                  }
              }
              return acc + tmp
          }, 0) //ราคาขายจริง, ยอดจัด

          if(PORT === 9005 || PORT === 9008 || PORT === 9063 || PORT === 9046){
              sum_price = res.data.reduce((acc, next) => acc + next.real_sell_price, 0)
          }

          sum_car_cost =  res.data.reduce((acc, next) => acc + next.car_cost, 0)

          //
          sum_car_cost_commissions = res.data.reduce((acc, next) => acc + next.car_cost_commissions, 0)
          sum_car_cost_repair = res.data.reduce((acc, next) => acc + next.car_cost_repair, 0)
          sum_car_cost_tax = res.data.reduce((acc, next) => acc + next.car_cost_tax, 0)
          sum_car_cost_act = res.data.reduce((acc, next) => acc + next.car_cost_act, 0)
          sum_car_cost_other = res.data.reduce((acc, next) => acc + next.car_cost_expenses_other, 0)
          sum_car_cost_other2 = res.data.reduce((acc, next) => acc + next.car_cost_other, 0)
          sum_car_cost_other = sum_car_cost_other+sum_car_cost_other2

          sum_amount_of_debt = res.data.reduce((acc, next) => acc + next.amount_of_debt, 0)

          // รายรับการขาย
          sum_vat_sell = res.data.reduce((acc, next) => acc + next.vat_sell, 0)
          sum_tax_invoice_vat = res.data.reduce((acc, next) => {
                let tmp = next.tax_invoice_vat > 0 ? next.tax_invoice_vat : next.vat_sell
                return acc + tmp
            }, 0)

            sum_finance_commission = res.data.reduce((acc, next) => {
                let tmp = next.tax_commission_amount > 0 ? next.tax_commission_amount : next.finance_commission
                return acc + tmp
            }, 0)

            sum_vat_commission = res.data.reduce((acc, next) => {
                let tmp = next.tax_commission_vat > 0 ? next.tax_commission_vat : next.vat_commission
                return acc + tmp
            }, 0)

            sum_tax_invoice_amount = res.data.reduce((acc, next) => {
                let tmp = next.tax_invoice_amount > 0 ? next.tax_invoice_amount : next.rate_finance
                return acc + tmp
            }, 0)

            sum_down_total = res.data.reduce((acc, next) => acc + next.sum_down, 0)

            sum_down_not_profit = res.data.filter(d=>(d.tax_invoice_vat + d.tax_invoice_amount) === d.sum_down).reduce((acc, next) => acc + next.sum_down, 0)

          // รายจ่ายการขาย
            sum_cost_operation = res.data.reduce((acc, next) => acc + next.cost_operation, 0) //ค่าดำเนินการ
            sum_cost_service = res.data.reduce((acc, next) => acc + next.cost_service, 0)//ค่าบริการ
            sum_cost_transport = res.data.reduce((acc, next) => acc + next.cost_transport, 0)//ค่าขนย้าย
            sum_cost_porobor_lan = res.data.reduce((acc, next) => acc + next.cost_porobor_lan, 0)//ค่า พรบ. จากลาน
            sum_cost_bangkhab_phasi_lan = res.data.reduce((acc, next) => acc + next.cost_bangkhab_phasi_lan, 0)//ค่าบังคับต่อภาษี จากลาน
            sum_cost_bangkhab_xon_lan = res.data.reduce((acc, next) => acc + next.cost_bangkhab_xon_lan, 0)//ค่าบังคับโอนเข้าเต็นท์ จากลาน
            sum_cost_check_ton = res.data.reduce((acc, next) => acc + next.cost_check_ton, 0)//ค่าเช็คต้น

          sum_car_external_commission = res.data.reduce((acc, next) => acc + next.car_external_commission, 0)
          sum_sales_commission = res.data.reduce((acc, next) => acc + next.sales_commission, 0)
          sum_additional_commission = res.data.reduce((acc, next) => acc + next.additional_commission, 0)
          sum_advertising_cost = res.data.reduce((acc, next) => acc + next.advertising_cost, 0)
          sum_delivery_fee = res.data.reduce((acc, next) => acc + next.delivery_fee, 0)
          sum_promotion_fee = res.data.reduce((acc, next) => acc + next.promotion_fee, 0)
          // รายจ่ายการขาย 2
          sum_finance_fee = res.data.reduce((acc, next) => acc + next.finance_fee, 0) // ค่าธรรมเนียมไฟแนนท์
          sum_transfer_fee = res.data.reduce((acc, next) => acc + next.transfer_fee, 0) // ค่าโอนรถ

          sum_cost_after_sales_service = res.data.reduce((acc, next) => acc + next.cost_after_sales_service, 0)//บริการหลังการขาย

          sum_cost_phra = res.data.reduce((acc, next) => acc + next.cost_phra, 0)
          sum_cost_vat_and_property_tax = res.data.reduce((acc, next) => acc + next.cost_vat_and_property_tax, 0)
          sum_vat_buy = res.data.reduce((acc, next) => acc + next.vat_buy, 0)
          //
          sum_expense_finance = res.data.reduce((acc, car) => acc + getSumCostFinanceAfterSell(car), 0)


          sum_profit = sum_price - (sum_car_cost + sum_vat_buy + sum_car_cost_commissions + sum_car_cost_repair + sum_car_cost_other+ sum_car_cost_tax + sum_car_cost_act) +
          (sum_tax_invoice_vat + sum_finance_commission + sum_vat_commission) - (sum_cost_operation + sum_cost_service + sum_cost_transport + sum_cost_porobor_lan +
          sum_cost_bangkhab_phasi_lan + sum_cost_bangkhab_xon_lan + sum_cost_check_ton + sum_car_external_commission + sum_sales_commission + sum_additional_commission +
          sum_advertising_cost + sum_delivery_fee + sum_promotion_fee + sum_finance_fee + sum_transfer_fee + sum_cost_after_sales_service + sum_cost_phra + sum_cost_vat_and_property_tax) -
          (sum_down_not_profit)

            if(PORT === 9008){
                //กำไร ตัด VAT ซื้อ VAT ขาย
                sum_profit = sum_price - (sum_car_cost + sum_car_cost_commissions + sum_car_cost_repair + sum_car_cost_other+ sum_car_cost_tax + sum_car_cost_act) +
                (sum_finance_commission) - (sum_cost_operation + sum_cost_service + sum_cost_transport + sum_cost_porobor_lan + sum_cost_bangkhab_phasi_lan +
                sum_cost_bangkhab_xon_lan + sum_cost_check_ton + sum_car_external_commission + sum_sales_commission + sum_additional_commission + sum_advertising_cost +
                sum_delivery_fee + sum_promotion_fee + sum_finance_fee + sum_transfer_fee + sum_cost_after_sales_service)
            }else if(PORT === 9005){
                sum_profit = sum_profit
            }else if(PORT === 9048){
                sum_profit = sum_profit + sum_down_total
            } else {
                // ถ้าไม่ใช่ sc ลดยอดหนี้ออกจากกำไร
                sum_profit = sum_profit - sum_amount_of_debt + sum_down_total - (sum_expense_finance)
            }

          sum_financing_amount = sum_tax_invoice_amount + sum_tax_invoice_vat

          sum_car_cost_vat = (sum_car_cost + sum_vat_buy + sum_car_cost_commissions + sum_car_cost_repair + sum_car_cost_other+ sum_car_cost_tax + sum_car_cost_act) +
                             (sum_cost_operation + sum_cost_service + sum_cost_transport + sum_cost_porobor_lan + sum_cost_bangkhab_phasi_lan + sum_cost_bangkhab_xon_lan +
                              sum_cost_check_ton + sum_car_external_commission + sum_sales_commission + sum_additional_commission + sum_advertising_cost + sum_delivery_fee +
                              sum_promotion_fee + sum_finance_fee + sum_transfer_fee + sum_cost_after_sales_service + sum_cost_phra + sum_cost_vat_and_property_tax)
        }

       //console.log('sum_vat_sell =', sum_vat_sell, typeof sum_vat_sell)

        this.setState({
          lists: res.data,
          sum_price_normal: sum_price_normal,
          sum_price: sum_price,
          sum_car_cost: sum_car_cost,
          sum_car_cost_commissions: sum_car_cost_commissions,
          sum_car_cost_repair: sum_car_cost_repair,
          sum_car_cost_other: sum_car_cost_other,
          sum_profit: sum_profit,
          sum_car_cost_tax: sum_car_cost_tax,
          sum_car_cost_act: sum_car_cost_act,
          //
          sum_car_external_commission: sum_car_external_commission,
          sum_sales_commission: sum_sales_commission,
          sum_additional_commission: sum_additional_commission,
          sum_advertising_cost: sum_advertising_cost,
          sum_delivery_fee: sum_delivery_fee,
          sum_promotion_fee: sum_promotion_fee,
          sum_finance_fee: sum_finance_fee,
          sum_transfer_fee: sum_transfer_fee,
          //
          sum_vat_sell: sum_vat_sell,
          sum_finance_commission: sum_finance_commission,
          sum_vat_commission: sum_vat_commission,
          sum_cost_phra: sum_cost_phra,
          sum_cost_vat_and_property_tax: sum_cost_vat_and_property_tax,
          isLoading: false,

          person_vat_id: person_vat_id,
          employee_id: employee_id,

          branch_id: branch_id,
          //
            sum_cost_operation: sum_cost_operation,
            sum_cost_service: sum_cost_service,
            sum_cost_transport: sum_cost_transport,
            sum_cost_porobor_lan: sum_cost_porobor_lan,
            sum_cost_bangkhab_phasi_lan: sum_cost_bangkhab_phasi_lan,
            sum_cost_bangkhab_xon_lan: sum_cost_bangkhab_xon_lan,
            sum_cost_check_ton: sum_cost_check_ton,
            sum_cost_after_sales_service: sum_cost_after_sales_service,

            sum_tax_invoice_vat : sum_tax_invoice_vat,
            sum_vat_buy: sum_vat_buy,
            sum_car_cost_vat : sum_car_cost_vat,
            sum_financing_amount : sum_financing_amount,
            sum_down_total: sum_down_total,
            sum_amount_of_debt: sum_amount_of_debt,
            sum_expense_finance: sum_expense_finance
        })
      }).catch(error => {
        console.log('error:', error)
       // this.setState({lists: []})
    })

  }

  loadEmployeeData() {
        axios.get(APIURL + '/employee/')
            .then(res => {
                if(res.status === 200) {
                    let data = res.data
                    if (data.length > 0) {
                        let dataFilter = data.filter(d => d.employee_position.indexOf('ขาย') !== -1)
                        this.setState({ employees: dataFilter })
                    } else {
                        this.setState({employees: [] })
                    }
                }
            }).catch(error => {
            console.log('error:', error);
            this.setState({employees: [] })
        })
    }

  loadBranchData () {
        axios.get(APIURL + '/branch/')
            .then(res => {
                this.setState({branches: res.data})
            }).catch(error => {
            this.setState({branches: []})
        })
    }

  async exportSellData() {

        if(this.state.date_start > this.state.date_end){
            alert('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
            return
        }

        let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
        let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')
        const person_vat_id = this.state.person_vat_id
        const employee_id = this.state.employee_id
        const branch_id = this.state.branch_id
        const branches_select = this.state.branches_select
        //const branch_name = this.state.branch_name

        let branches = this.state.branches
        let branch = branches.filter(b => b.branch_id === Number(branch_id))
        let branch_name = branch.length === 1 ? branch[0].branch_name : ' ทุกสาขา '

      const data = {
          start_date: start_date,
          end_date: end_date,
          person_vat_id: person_vat_id,
          employee_id: employee_id,
          branch_id: branch_id,
          branch_name: branch_name,
          branches_select : branches_select,
      }

        this.setState({
            isLoading: true
        },()=>{
            axios.post(APIURL + '/report/sellexport/', data)
                .then( async res => {
                    const excelBuffer = res.data.excelBuffer
                    const currentDatetime = res.data.currentDatetime
                    const fileName = 'รายงานสรุปยอดขาย_export_'+currentDatetime+'.xlsx'
                    this.setState({
                        isLoading: false
                    })
                    await save(excelBuffer, fileName)

                }).catch(error => {
                console.log('error:', error)
                // this.setState({lists: []})
            })
        })
    }

  getExpenseCountColumn() {
    if (PORT === 9005) {
      return 12
    } else  if (PORT === 9043) {
      return 25
    } else  if (PORT === 9008) {
        return 22
    } else {
      return 23
    }
  }

  getIncomeCountColumn() {
      if (PORT === 9005 || PORT === 9008) {
          return 3
      } else  if (PORT === 9048) {
          return 4
      } else {
          return 6
      }
  }

  getHeaderColumn() {
      let form_controls = this.state.form_controls
      let additional_commission_label = getFormControlName('additional_commission', form_controls)

      const colIndexIncome = PORT === 9005 ? 1 : 1;
      let colIndexExpense = 5
      if(PORT === 9005){
          colIndexExpense = 3
      }else if(PORT === 9048){
          colIndexExpense = 4
      }

      const colIndex = PORT === 9005 ? 4 : 6;
      const colIndex2 = PORT === 9005 ? 7 : 10;

        if (PORT === 9005) {
            return(
                <>
                <tr>
                    <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">ลำดับ</th>
                    <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">ชื่อรถ</th>
                    <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">ยี่ห้อ</th>
                    <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">วันที่ขาย</th>
                    <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">ขายโดย</th>
                    <th colSpan={this.getIncomeCountColumn()} className="text-center text-nowrap" style={styles.rowReceive}  >รายรับ</th>
                    <th colSpan={this.getExpenseCountColumn()} className="text-center text-nowrap" style={styles.rowExpense} >รายจ่าย</th>
                    <th rowSpan={2} className="text-center text-nowrap" style={styles.rowProfit} >กำไรเบื้องต้น</th>
                </tr>
                <tr>
                    <th style={styles.rowReceive}  className="text-right text-nowrap">ราคาขายจริง({colIndexIncome})</th>
                    <th style={styles.rowReceive}  className="text-right text-nowrap">VAT ยอดจัด({colIndexIncome+1})</th>
                    <th style={styles.rowReceive}  className="text-right text-nowrap">ค่าคอมมิชชั่น FN({colIndexIncome+2})</th>
                    <th style={styles.rowExpense} className="text-right text-nowrap">ซื้อรถเข้า({colIndexExpense+1}) </th>
                    <th style={styles.rowExpense} className="text-right text-nowrap">ค่าซ่อม({colIndexExpense+2})</th>
                    <th style={styles.rowExpense} className="text-right text-nowrap">ค่าภาษีรถยนต์ ({colIndexExpense +3})</th>
                    <th style={styles.rowExpense} className="text-right text-nowrap">ค่าใช้จ่าย พ.ร.บ. ({colIndexExpense+4})</th>
                    <th style={styles.rowExpense} className="text-right text-nowrap">ค่านายหน้า({colIndex2+1})</th>
                    <th style={styles.rowExpense} className="text-right text-nowrap">ค่าคอมมิชชั่นฝ่ายขาย({colIndex2+2})</th>
                    <th style={styles.rowExpense} className="text-right text-nowrap">{additional_commission_label}({colIndex2+3})</th>
                    <th style={styles.rowExpense} className="text-right text-nowrap">ค่าโฆษณา({colIndex2+4})</th>
                    <th style={styles.rowExpense} className="text-right text-nowrap">ค่าบริการส่งรถ({colIndex2+5})</th>
                    <th style={styles.rowExpense} className="text-right text-nowrap">ค่าโปรโมชั่น({colIndex2+6})</th>
                    <th style={styles.rowExpense} className="text-right text-nowrap">ค่าธรรมเนียม FN({colIndex2+7})</th>
                    <th style={styles.rowExpense} className="text-right text-nowrap">ค่าโอนรถ({colIndex2+8})</th>
                </tr>
                </>
            )
        } else if(PORT === 9048){
            return(
                <>
                    <tr>
                        <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">ลำดับ</th>
                        <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">ชื่อรถ</th>
                        <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">ประเภท</th>
                        <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">วันที่ขาย</th>
                        <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">ขายโดย</th>
                        <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">ประเภทการขาย</th>
                        <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">ไฟแนนซ์</th>
                        <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">ทุน + VAT ทุน</th>
                        <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">ยอดจัด + VAT</th>
                        <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">เงินดาวน์</th>
                        <th rowSpan={2} className="text-right text-nowrap" style={styles.headerTable} >ราคาขาย</th>
                        <th colSpan={this.getIncomeCountColumn()} className="text-center text-nowrap" style={styles.rowReceive}  >รายรับ</th>
                        <th colSpan={this.getExpenseCountColumn()} className="text-center text-nowrap" style={styles.rowExpense} >รายจ่าย</th>
                        <th rowSpan={2} className="text-center text-nowrap" style={styles.rowProfit} >กำไรเบื้องต้น</th>
                    </tr>
                    <tr>
                        <th style={styles.rowReceive}  className="text-right text-nowrap">ราคาขายจริง/ยอดจัด({colIndexIncome})</th>
                        <th style={styles.rowReceive}  className="text-right text-nowrap">VAT ยอดจัด({colIndexIncome+1})</th>
                        <th style={styles.rowReceive}  className="text-right text-nowrap">ค่าคอมมิชชั่น FN({colIndexIncome+2})</th>
                        <th className="text-right text-nowrap" style={styles.rowReceive}>VAT คอมมิชชั่น({colIndexIncome+3})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ทุนซื้อรถ({colIndexExpense+1}) </th>
                        <th className="text-right text-nowrap" style={styles.rowExpense}>VAT ทุน({colIndexExpense+2}) </th>
                        <th className="text-right text-nowrap" style={styles.rowExpense} >ค่าคอมฯซื้อเข้า ({colIndexExpense+3}) </th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่าซ่อม({colIndexExpense+4})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่าภาษีรถยนต์({colIndexExpense +5})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่าใช้จ่าย พ.ร.บ({colIndexExpense+6})</th>
                        <th className="text-right text-nowrap" style={styles.rowExpense} >ค่าใช้จ่ายรถอื่นๆ({colIndexExpense + 7})</th>
                        <th className="text-right text-nowrap" style={styles.rowExpense} >ค่าดำเนินการ({colIndexExpense + 8})</th>
                        <th className="text-right text-nowrap" style={styles.rowExpense} >ค่าบริการ({colIndexExpense + 9})</th>
                        <th className="text-right text-nowrap" style={styles.rowExpense} >ค่าขนย้าย({colIndexExpense + 10})</th>
                        <th className="text-right text-nowrap" style={styles.rowExpense} >ค่า พรบ.จากลาน({colIndexExpense + 11})</th>
                        <th className="text-right text-nowrap" style={styles.rowExpense} >ค่าบังคับต่อภาษีจากลาน({colIndexExpense + 12})</th>
                        <th className="text-right text-nowrap" style={styles.rowExpense} >ค่าบังคับโอนเข้าเต็นท์จากลาน ({colIndexExpense + 13})</th>
                        <th className="text-right text-nowrap" style={styles.rowExpense} >ค่าเช็คต้น({colIndexExpense + 14})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่านายหน้า({colIndex2+10})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่าคอมมิชชั่นฝ่ายขาย({colIndex2+11})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">{additional_commission_label}({colIndex2+12})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่าโฆษณา({colIndex2+13})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่าบริการส่งรถ({colIndex2+14})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่าโปรโมชั่น({colIndex2+15})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่าธรรมเนียม FN({colIndex2+16})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่าโอนรถ({colIndex2+17})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">บริการหลังการขาย({colIndex2+18})</th>
                    </tr>
                </>
            )
        } else if(PORT === 9008){
            return(
                <>
                    <tr>
                        <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">ลำดับ</th>
                        <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">ชื่อรถ</th>
                        <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">ยี่ห้อ</th>
                        <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">ประเภท</th>
                        <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">วันที่ขาย</th>
                        <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">ขายโดย</th>
                        <th rowSpan={2} className="text-right text-nowrap" style={styles.headerTable} >ราคาขาย</th>
                        <th colSpan={this.getIncomeCountColumn()} className="text-center text-nowrap" style={styles.rowReceive}  >รายรับ</th>
                        <th colSpan={this.getExpenseCountColumn()} className="text-center text-nowrap" style={styles.rowExpense} >รายจ่าย</th>
                        <th rowSpan={2} className="text-center text-nowrap" style={styles.rowExpense} >ตั้งหนี้</th>
                        <th rowSpan={2} className="text-center text-nowrap" style={styles.rowProfit} >กำไรเบื้องต้น</th>
                    </tr>
                    <tr>
                        <th style={styles.rowReceive}  className="text-right text-nowrap">ราคาขายจริง/ยอดจัด({colIndexIncome})</th>
                        <th style={styles.rowReceive}  className="text-right text-nowrap">ค่าคอมมิชชั่น FN({colIndexIncome+1})</th>
                        <th style={styles.rowReceive}  className="text-right text-nowrap">ส่วนต่างเงินคืนลูกค้า({colIndexIncome+2})</th>

                        <th style={styles.rowExpense} className="text-right text-nowrap">ทุนซื้อรถ({colIndexExpense+1}) </th>
                        <th className="text-right text-nowrap" style={styles.rowExpense} >ค่าคอมฯซื้อเข้า ({colIndexExpense+2}) </th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่าซ่อม({colIndexExpense+3})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่าภาษีรถยนต์({colIndexExpense +4})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่าใช้จ่าย พ.ร.บ({colIndexExpense+5})</th>
                        <th className="text-right text-nowrap" style={styles.rowExpense} >ค่าใช้จ่ายรถอื่นๆ({colIndexExpense + 6})</th>
                        <th className="text-right text-nowrap" style={styles.rowExpense} >ค่าดำเนินการ({colIndexExpense + 7})</th>
                        <th className="text-right text-nowrap" style={styles.rowExpense} >ค่าบริการ({colIndexExpense + 8})</th>
                        <th className="text-right text-nowrap" style={styles.rowExpense} >ค่าขนย้าย({colIndexExpense + 9})</th>
                        <th className="text-right text-nowrap" style={styles.rowExpense} >ค่า พรบ.จากลาน({colIndexExpense + 10})</th>
                        <th className="text-right text-nowrap" style={styles.rowExpense} >ค่าบังคับต่อภาษีจากลาน({colIndexExpense + 11})</th>
                        <th className="text-right text-nowrap" style={styles.rowExpense} >ค่าบังคับโอนเข้าเต็นท์จากลาน ({colIndexExpense + 12})</th>
                        <th className="text-right text-nowrap" style={styles.rowExpense} >ค่าเช็คต้น({colIndexExpense + 13})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่านายหน้า({colIndex2+9})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่าคอมมิชชั่นฝ่ายขาย({colIndex2+10})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">{additional_commission_label}({colIndex2+11})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่าโฆษณา({colIndex2+12})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่าบริการส่งรถ({colIndex2+13})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่าโปรโมชั่น({colIndex2+14})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่าธรรมเนียม FN({colIndex2+15})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่าโอนรถ({colIndex2+16})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">บริการหลังการขาย({colIndex2+17})</th>
                    </tr>
                </>
            )
        } else {
            return(
                <>
                    <tr>
                        <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">ลำดับ</th>
                        <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">ชื่อรถ</th>
                        <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">ยี่ห้อ</th>
                        <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">วันที่ขาย</th>
                        <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">ขายโดย</th>
                        <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">สาขา</th>
                        <th rowSpan={2} className="text-right text-nowrap" style={styles.headerTable} >ราคาขาย</th>
                        <th colSpan={this.getIncomeCountColumn()} className="text-center text-nowrap" style={styles.rowReceive}  >รายรับ</th>
                        <th colSpan={this.getExpenseCountColumn()} className="text-center text-nowrap" style={styles.rowExpense} >รายจ่าย</th>
                        <th rowSpan={2} className="text-center text-nowrap" style={styles.rowExpense} > รวม คชจ. FN </th>
                        <th rowSpan={2} className="text-center text-nowrap" style={styles.rowExpense} >ตั้งหนี้</th>
                        <th rowSpan={2} className="text-center text-nowrap" style={styles.rowProfit} >กำไรเบื้องต้น</th>
                    </tr>
                    <tr>
                        <th style={styles.rowReceive}  className="text-right text-nowrap">ราคาขายจริง/ยอดจัด({colIndexIncome})</th>
                        <th style={styles.rowReceive}  className="text-right text-nowrap">VAT ยอดจัด({colIndexIncome+1})</th>
                        <th style={styles.rowReceive}  className="text-right text-nowrap">ค่าคอมมิชชั่น FN({colIndexIncome+2})</th>
                        <th style={styles.rowReceive}  className="text-right text-nowrap">VAT คอมมิชชั่น({colIndexIncome+3})</th>
                        <th style={styles.rowReceive}  className="text-right text-nowrap">เงินดาวน์({colIndexIncome+4})</th>
                        <th style={styles.rowReceive}  className="text-right text-nowrap">ส่วนต่างเงินคืนลูกค้า({colIndexIncome+5})</th>

                        <th style={styles.rowExpense} className="text-right text-nowrap">ซื้อรถเข้า({colIndexExpense+1}) </th>
                        <th className="text-right text-nowrap" style={styles.rowExpense}>VAT ซื้อเข้า({colIndexExpense+2}) </th>
                        <th className="text-right text-nowrap" style={styles.rowExpense} >ค่าคอมฯซื้อเข้า ({colIndexExpense+3}) </th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่าซ่อม({colIndexExpense+4})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่าภาษีรถยนต์({colIndexExpense +5})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่าใช้จ่าย พ.ร.บ({colIndexExpense+6})</th>
                        <th className="text-right text-nowrap" style={styles.rowExpense} >ค่าใช้จ่ายรถอื่นๆ({colIndexExpense + 7})</th>
                        <th className="text-right text-nowrap" style={styles.rowExpense} >ค่าดำเนินการ({colIndexExpense + 8})</th>
                        <th className="text-right text-nowrap" style={styles.rowExpense} >ค่าบริการ({colIndexExpense + 9})</th>
                        <th className="text-right text-nowrap" style={styles.rowExpense} >ค่าขนย้าย({colIndexExpense + 10})</th>
                        <th className="text-right text-nowrap" style={styles.rowExpense} >ค่า พรบ.จากลาน({colIndexExpense + 11})</th>
                        <th className="text-right text-nowrap" style={styles.rowExpense} >ค่าบังคับต่อภาษีจากลาน({colIndexExpense + 12})</th>
                        <th className="text-right text-nowrap" style={styles.rowExpense} >ค่าบังคับโอนเข้าเต็นท์จากลาน ({colIndexExpense + 13})</th>
                        <th className="text-right text-nowrap" style={styles.rowExpense} >ค่าเช็คต้น({colIndexExpense + 14})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่านายหน้า({colIndex2+10})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่าคอมมิชชั่นฝ่ายขาย({colIndex2+11})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">{additional_commission_label}({colIndex2+12})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่าโฆษณา({colIndex2+13})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่าบริการส่งรถ({colIndex2+14})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่าโปรโมชั่น({colIndex2+15})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่าธรรมเนียม FN({colIndex2+16})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">ค่าโอนรถ({colIndex2+17})</th>
                        <th style={styles.rowExpense} className="text-right text-nowrap">บริการหลังการขาย({colIndex2+18})</th>

                        <th className={PORT === 9043 ? '' : 'hidden'} style={styles.rowExpense}>พระ({colIndex2 + 19})</th>
                        <th className={PORT === 9043 ? 'text-right text-nowrap' : 'hidden'} style={styles.rowExpense}>ภาษีมูลค่าเพิ่ม + ภาษีโรงเรือน({colIndex2 + 20})</th>
                    </tr>
                </>
            )
        }
    }

  getValueColumn(car, refund_arr) {
      const car_sell_type = car.car_sell_type_id
      const car_sell_date = moment(car.car_sell_date).locale('th').format('LL')
      const car_cost = car.car_cost
      const car_cost_commissions = car.car_cost_commissions // คอมฯซื้อเข้า
      const car_cost_repair = car.car_cost_repair

      const sum_down = car.sum_down //ยอดดาวน์
      const car_sell_type_name = car.car_sell_type_name
      const bank_name = car.bank_name
      const car_cost_tax = car.car_cost_tax // ค่าใช้จ่าย พ.ร.บ./ค่าภาษีรถยนต์
      const car_cost_act = car.car_cost_act // ค่าภาษีรถยนต์

      let car_cost_with_vat = car.car_cost + car.vat_buy //ราคาซื้อเข้า + VAT

      //roddee ขอปรับ ไม่บวก VAT ซื้อ VAT ขาย 18-10-67
      if(PORT === 9008){
          car_cost_with_vat = car.car_cost
      }

      let sum_cost_before_sell = getSumCostBeforeSell(car) //ค่าใช้จ่ายอื่น + ค่าซ่อม
      let total_expenses_before_sell = car_cost_with_vat + sum_cost_before_sell

      const total_expenses_after_sell = getSumCostAfterSell(car) //รวมรายจ่ายหลังขาย

      const tax_invoice_amount = car.tax_invoice_amount > 0 ?  car.tax_invoice_amount : car.rate_finance //ยอดจัด
      const vat_invoice = car.vat_sell > 0 ? car.vat_sell : car.tax_invoice_vat //VAT ยอดจัด
      const tax_commission_amount = car.tax_commission_amount > 0 ? car.tax_commission_amount : car.finance_commission  //ค่าคอม
      const tax_commission_vat = car.tax_commission_vat > 0 ? car.tax_commission_vat : car.vat_commission  //VAT ค่าคอม

      // รวมรายจ่ายก่อนขาย
      // const total_expenses_before_sell = car_cost + car.vat_buy + car_cost_commissions + car_cost_repair + car_cost_expenses_other + car_cost_tax + car_cost_act

      // เฉพาะ 9048 scauto
      // รวมรายจ่ายก่อนขาย + หลังขาย
      const total_expenses = total_expenses_before_sell + total_expenses_after_sell
      const financing_amount = tax_invoice_amount + vat_invoice // ยอดจัด + VAT
      const car_cost_vat = total_expenses //รวมราคาซื้อเข้า + VAT, ่าใช้จ่ายอื่น + ค่าซ่อม และรวมรายจ่ายหลังขาย

      // let car_sell_type_id = Number(car.car_sell_type_id)

      // อันใหม่ตามที่ sc แจ้งมา ยอดจัดไฟแนนซ์+เงินดาวน์ -ต้นทุนรถ-ค่าคอมมิชชั่น-ค่าซ่อม-ค่าอื่นๆที่เกี่ยวกับตัวรถ
      // ขายเงินสด คำนวณจากราคาขายจริง car_sell_type_id === 1

      let sell_price = 0
      if(car_sell_type === 1){
          sell_price = car.tax_invoice_amount === null || car.tax_invoice_amount === 0 ? car.real_sell_price : car.tax_invoice_amount
      } else if (car_sell_type === 2) {
          // จัดไฟแนนซ์
          sell_price = car.tax_invoice_amount === null || car.tax_invoice_amount === 0 ? car.rate_finance : car.tax_invoice_amount
          //ปรับแก้ 17-10-67 ปิดไว้เพราะเพิ่มช่องรายรับเงินดาวน์ขึ้นใหม่
          /*if (sum_down !== 0 ) {
              sell_price += sum_down
          }*/
      }

      if(PORT === 9005 || PORT === 9008 || PORT === 9063 || PORT === 9046){
          sell_price = car.real_sell_price
      }

      if(PORT === 9048){
          if(car_sell_type === 1){
              sell_price = car.tax_invoice_amount === null || car.tax_invoice_amount === 0 ? car.real_sell_price : car.tax_invoice_amount
          } else {
              sell_price = car.tax_invoice_amount === null || car.tax_invoice_amount === 0 ? car.rate_finance : car.tax_invoice_amount
          }
      }

      let profit_before_sell = (sell_price) - (total_expenses_before_sell)

      if(PORT === 9005 || PORT === 9008 || PORT === 9063 || PORT === 9046){
          profit_before_sell = car.real_sell_price - (total_expenses_before_sell)
      }

      // rate_finance
      const amount_of_debt = Number(car.amount_of_debt)
      let car_excess_amount_use = getSumCostSellPayByCustomer(car)
      let total_expense_finance = getSumCostFinanceAfterSell(car)

      let use_and_refund_by_customer = 0

      let {refund_difference, refund_cash} = getRefundDifference(car)

      refund_arr.push({
         car_id : car.car_id,
         refund_difference : refund_difference,
         refund_cash : refund_cash,
         car_excess_amount_use : car_excess_amount_use
      })
     //sum_refund_difference += refund_difference


      let profit_after_sell = (vat_invoice + tax_commission_amount + tax_commission_vat) - total_expenses_after_sell

      // roddee ขอปรับ ไม่บวก VAT ซื้อ VAT ขาย 18-10-67
      if(PORT === 9008){
          profit_after_sell = (tax_commission_amount) - total_expenses_after_sell
      }

      if (PORT !== 9048) {
          // ถ้าไม่ใช่ sc ลบยอดตั้งหนี้ค้างดาวน์
          profit_after_sell = profit_after_sell - amount_of_debt
      }

      let gross_profit = (profit_before_sell + profit_after_sell + refund_difference + sum_down) - car_excess_amount_use - refund_cash - total_expense_finance //ช่องคำนวณกำไร

      if(PORT === 9005 || PORT === 9008){
          gross_profit = profit_before_sell + profit_after_sell
      }else if(PORT === 9048){
          if (sum_down !== 0 && sum_down !== financing_amount) {
              gross_profit = profit_before_sell + profit_after_sell + sum_down
          }
      }

      const person_vat_id = car.person_vat_id
      const province_name = car.car_license_plate_new !== '' ||  car.car_license_plate_new === 999 &&  car.car_license_plate_new !==  car.car_license_plate_old ? car.province_name_new : car.province_name_old
      const car_license_plate = car.car_license_plate_new === '' ?  car.car_license_plate_old : car.car_license_plate_new
      const vat_buy = car.vat_buy

        if (PORT === 9005) {
            return(
                <>
                    <td className="text-nowrap" style={{width: 200}}>{car.car_name} <br/>( {car_license_plate} {province_name})</td>
                    <td className="text-center text-nowrap">{car.car_brand_name}</td>
                    <td className="text-nowrap" style={{width: 110}}>{car_sell_date}</td>
                    <td style={{width: 60}} className="text-center text-nowrap">{car.employee_name}</td>

                    <td className="text-right text-nowrap">{sell_price.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{vat_invoice.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{tax_commission_amount === null ? '-' : tax_commission_amount.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.car_cost === null ? '-' : car.car_cost.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.car_cost_repair === null ? '-' : car.car_cost_repair.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.car_cost_tax === null ? '-' : car.car_cost_tax.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.car_cost_act === null ? '-' : car.car_cost_act.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.car_external_commission === null ? '-' : car.car_external_commission.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.sales_commission === null ? '-' : car.sales_commission.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.additional_commission === null ? '-' : car.additional_commission.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.advertising_cost === null ? '-' : car.advertising_cost.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.delivery_fee === null ? '-' : car.delivery_fee.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.promotion_fee === null ? '-' : car.promotion_fee.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.finance_fee === null ? '-' : car.finance_fee.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.transfer_fee === null ? '-' : car.transfer_fee.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{gross_profit.toLocaleString()}</td>
                </>
            )
        } else if(PORT === 9048){
            return(
                <>
                    <td className="text-nowrap" style={{width: 200}}>{car.car_name} <br/>( {car_license_plate} {province_name})</td>
                    <td className="text-center text-nowrap">{CarUtil.convertVatPersonToName(person_vat_id)}</td>
                    <td className="text-nowrap" style={{width: 110}}>{car_sell_date}</td>
                    <td style={{width: 60}} className="text-center text-nowrap">{car.employee_name}</td>
                    <td className="text-center text-nowrap">{car_sell_type_name}</td>{/*sc เพิ่ม ประเภทการขาย*/}
                    <td className="text-center text-nowrap">{bank_name}</td>{/*sc เพิ่ม ไฟแนนซ์*/}
                    <td className="text-right text-nowrap">{car_cost_vat.toLocaleString()}</td> {/* ราคาทุน+VATทุน*/}
                    <td className="text-right text-nowrap">{financing_amount.toLocaleString()}</td> {/*sc เพิ่ม ราคาจัด+VAT*/}
                    <td className="text-right text-nowrap">{sum_down.toLocaleString()}</td> {/*sc เงินดาวน์*/}

                    <td className="text-right text-nowrap">{car.normal_sell_price === null ? '-' : car.normal_sell_price.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{sell_price.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{vat_invoice.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{tax_commission_amount === null ? '-' : tax_commission_amount.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{tax_commission_vat === null ? '-' : tax_commission_vat.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.car_cost === null ? '-' : car.car_cost.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{vat_buy === null ? '-' : vat_buy.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.car_cost_commissions === null ? '-' : car.car_cost_commissions.toLocaleString()}</td>

                    <td className="text-right text-nowrap">{car.car_cost_repair === null ? '-' : car.car_cost_repair.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.car_cost_tax === null ? '-' : car.car_cost_tax.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.car_cost_act === null ? '-' : car.car_cost_act.toLocaleString()}</td>

                    <td className="text-right text-nowrap">{car.car_cost_expenses_other === null ? '-' : car.car_cost_expenses_other.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.cost_operation === null ? '-' : car.cost_operation.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.cost_service === null ? '-' : car.cost_service.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.cost_transport === null ? '-' : car.cost_transport.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.cost_porobor_lan === null ? '-' : car.cost_porobor_lan.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.cost_bangkhab_phasi_lan === null ? '-' : car.cost_bangkhab_phasi_lan.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.cost_bangkhab_xon_lan === null ? '-' : car.cost_bangkhab_xon_lan.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.cost_check_ton === null ? '-' : car.cost_check_ton.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.car_external_commission === null ? '-' : car.car_external_commission.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.sales_commission === null ? '-' : car.sales_commission.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.additional_commission === null ? '-' : car.additional_commission.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.advertising_cost === null ? '-' : car.advertising_cost.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.delivery_fee === null ? '-' : car.delivery_fee.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.promotion_fee === null ? '-' : car.promotion_fee.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.finance_fee === null ? '-' : car.finance_fee.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.transfer_fee === null ? '-' : car.transfer_fee.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.cost_after_sales_service === null ? '-' : car.cost_after_sales_service.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{gross_profit.toLocaleString()}</td>
                </>
            )
        } else if(PORT === 9008){
            return(
                <>
                    <td className="text-nowrap" style={{width: 200}}>{car.car_name} <br/>( {car_license_plate} {province_name})</td>
                    <td className="text-center text-nowrap">{car.car_brand_name}</td>
                    <td className="text-center text-nowrap">{CarUtil.convertVatPersonToName(person_vat_id)}</td>
                    <td className="text-nowrap" style={{width: 110}}>{car_sell_date}</td>
                    <td style={{width: 60}} className="text-center text-nowrap">{car.employee_name}</td>

                    <td className="text-right text-nowrap">{car.normal_sell_price === null ? '-' : car.normal_sell_price.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{sell_price.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{tax_commission_amount === null ? '-' : tax_commission_amount.toLocaleString()}</td>
                    <td className="text-right text-nowrap text-primary">{NumberUtil.addCommasZeroInt(refund_difference)}</td>

                    <td className="text-right text-nowrap">{car.car_cost === null ? '-' : car.car_cost.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.car_cost_commissions === null ? '-' : car.car_cost_commissions.toLocaleString()}</td>

                    <td className="text-right text-nowrap">{car.car_cost_repair === null ? '-' : car.car_cost_repair.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.car_cost_tax === null ? '-' : car.car_cost_tax.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.car_cost_act === null ? '-' : car.car_cost_act.toLocaleString()}</td>

                    <td className="text-right text-nowrap">{car.car_cost_expenses_other === null ? '-' : car.car_cost_expenses_other.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.cost_operation === null ? '-' : car.cost_operation.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.cost_service === null ? '-' : car.cost_service.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.cost_transport === null ? '-' : car.cost_transport.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.cost_porobor_lan === null ? '-' : car.cost_porobor_lan.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.cost_bangkhab_phasi_lan === null ? '-' : car.cost_bangkhab_phasi_lan.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.cost_bangkhab_xon_lan === null ? '-' : car.cost_bangkhab_xon_lan.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.cost_check_ton === null ? '-' : car.cost_check_ton.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.car_external_commission === null ? '-' : car.car_external_commission.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.sales_commission === null ? '-' : car.sales_commission.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.additional_commission === null ? '-' : car.additional_commission.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.advertising_cost === null ? '-' : car.advertising_cost.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.delivery_fee === null ? '-' : car.delivery_fee.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.promotion_fee === null ? '-' : car.promotion_fee.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.finance_fee === null ? '-' : car.finance_fee.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.transfer_fee === null ? '-' : car.transfer_fee.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.cost_after_sales_service === null ? '-' : car.cost_after_sales_service.toLocaleString()}</td>
                    <td  className="text-right text-nowrap text-danger">-{NumberUtil.addCommasZeroInt(amount_of_debt)}</td>
                    <td className="text-right text-nowrap">{gross_profit.toLocaleString()}</td>
                </>
            )
        } else {
            return(
                <>
                    <td className="text-nowrap" style={{width: 200}}>{car.car_name} <br/>( {car_license_plate} {province_name})</td>
                    <td className="text-center text-nowrap">{car.car_brand_name}</td>
                    <td className="text-nowrap" style={{width: 110}}>{car_sell_date}</td>
                    <td style={{width: 60}} className="text-center text-nowrap">{car.employee_name}</td>
                    <td className="text-nowrap" style={{width: 110}}>{car.branch_name}</td>

                    <td className="text-right text-nowrap">{car.normal_sell_price === null ? '-' : car.normal_sell_price.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{sell_price.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{vat_invoice.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{tax_commission_amount === null ? '-' : tax_commission_amount.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{tax_commission_vat === null ? '-' : tax_commission_vat.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{sum_down.toLocaleString()}</td>
                    <td className="text-right text-nowrap text-primary">{NumberUtil.addCommasZeroInt(refund_difference)}</td>

                    <td className="text-right text-nowrap">{car.car_cost === null ? '-' : car.car_cost.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{vat_buy === null ? '-' : vat_buy.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.car_cost_commissions === null ? '-' : car.car_cost_commissions.toLocaleString()}</td>

                    <td className="text-right text-nowrap">{car.car_cost_repair === null ? '-' : car.car_cost_repair.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.car_cost_tax === null ? '-' : car.car_cost_tax.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.car_cost_act === null ? '-' : car.car_cost_act.toLocaleString()}</td>

                    <td className="text-right text-nowrap">{car.car_cost_expenses_other === null ? '-' : car.car_cost_expenses_other.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.cost_operation === null ? '-' : car.cost_operation.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.cost_service === null ? '-' : car.cost_service.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.cost_transport === null ? '-' : car.cost_transport.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.cost_porobor_lan === null ? '-' : car.cost_porobor_lan.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.cost_bangkhab_phasi_lan === null ? '-' : car.cost_bangkhab_phasi_lan.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.cost_bangkhab_xon_lan === null ? '-' : car.cost_bangkhab_xon_lan.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.cost_check_ton === null ? '-' : car.cost_check_ton.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.car_external_commission === null ? '-' : car.car_external_commission.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.sales_commission === null ? '-' : car.sales_commission.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.additional_commission === null ? '-' : car.additional_commission.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.advertising_cost === null ? '-' : car.advertising_cost.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.delivery_fee === null ? '-' : car.delivery_fee.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.promotion_fee === null ? '-' : car.promotion_fee.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.finance_fee === null ? '-' : car.finance_fee.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.transfer_fee === null ? '-' : car.transfer_fee.toLocaleString()}</td>
                    <td className="text-right text-nowrap">{car.cost_after_sales_service === null ? '-' : car.cost_after_sales_service.toLocaleString()}</td>

                    <td className={PORT === 9043 ? 'text-right text-nowrap' : 'hidden'}> {car.cost_phra === null ? '-' : car.cost_phra.toLocaleString()} </td>
                    <td className={PORT === 9043 ? 'text-right text-nowrap' : 'hidden'}> {car.cost_vat_and_property_tax === null ? '-' : car.cost_vat_and_property_tax.toLocaleString()} </td>

                    <td className="text-right text-nowrap">{NumberUtil.addCommasZeroInt(total_expense_finance)}</td>
                    <td  className="text-right text-nowrap text-danger">-{NumberUtil.addCommasZeroInt(amount_of_debt)}</td>
                    <td className="text-right text-nowrap">{gross_profit.toLocaleString()}</td>
                </>
            )
        }
    }

  getSumColumn(refund_arr) {
      const {sum_price_normal,
          sum_price,
          sum_car_cost,
          sum_car_cost_commissions,
          sum_car_cost_repair,
          sum_car_cost_other,
          sum_profit,
          sum_car_cost_tax,
          sum_car_cost_act,
          //
          sum_car_external_commission,
          sum_sales_commission,
          sum_additional_commission,
          sum_advertising_cost,
          sum_delivery_fee,
          sum_promotion_fee,
          sum_finance_fee,
          sum_transfer_fee,
          sum_cost_phra,
          sum_cost_vat_and_property_tax,
          //
          sum_vat_sell,
          sum_finance_commission,
          sum_vat_commission,

          sum_cost_operation,
          sum_cost_service,
          sum_cost_transport,
          sum_cost_porobor_lan,
          sum_cost_bangkhab_phasi_lan,
          sum_cost_bangkhab_xon_lan,
          sum_cost_check_ton,
          sum_cost_after_sales_service,

          sum_tax_invoice_vat,
          sum_vat_buy,

          sum_car_cost_vat,
          sum_financing_amount,

          sum_down_total,
          sum_amount_of_debt,
          sum_expense_finance
      } = this.state

      let sum_refund_difference =  refund_arr.reduce((acc, next)=>{
          return acc + next.refund_difference
      },0)
      let sum_refund_cash =  refund_arr.reduce((acc, next)=>{
          return acc + next.refund_cash
      },0)
      let sum_use_and_refund_by_customer =  refund_arr.reduce((acc, next)=>{
          return acc + next.car_excess_amount_use
      },0)

      let sum_profit_after_refund = (sum_profit + sum_refund_difference) - sum_refund_cash - sum_use_and_refund_by_customer

      if(PORT === 9048){
          sum_profit_after_refund = sum_profit
      }

        if (PORT === 9005) {
            return(
                <>
                    <td className="text-right font-weight-bold">{sum_price.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_tax_invoice_vat.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_finance_commission.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_car_cost.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_car_cost_repair.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_car_cost_tax.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_car_cost_act.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_car_external_commission.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_sales_commission.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_additional_commission.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_advertising_cost.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_delivery_fee.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_promotion_fee.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_finance_fee.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_transfer_fee.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_profit_after_refund.toLocaleString()}</td>
                </>
            )
        } else if(PORT === 9048){
            return(
                <>
                    <td className="text-right font-weight-bold">{sum_car_cost_vat.toLocaleString()}</td>{/* ราคาทุน+VATทุน*/}
                    <td className="text-right font-weight-bold">{sum_financing_amount.toLocaleString()}</td>{/*sc เพิ่ม ยอดจัด+VAT*/}
                    <td className="text-right font-weight-bold">{sum_down_total.toLocaleString()}</td>{/* เงินดาวน์*/}
                    <td className="text-right font-weight-bold">{sum_price_normal.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_price.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_tax_invoice_vat.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_finance_commission.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_vat_commission.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_car_cost.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_vat_buy.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_car_cost_commissions.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_car_cost_repair.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_car_cost_tax.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_car_cost_act.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_car_cost_other.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_cost_operation.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_cost_service.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_cost_transport.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_cost_porobor_lan.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_cost_bangkhab_phasi_lan.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_cost_bangkhab_xon_lan.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_cost_check_ton.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_car_external_commission.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_sales_commission.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_additional_commission.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_advertising_cost.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_delivery_fee.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_promotion_fee.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_finance_fee.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_transfer_fee.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_cost_after_sales_service.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_profit_after_refund.toLocaleString()}</td>
                </>
            )
        } else if(PORT === 9008){
            return(
                <>
                    <td className="text-right font-weight-bold">{sum_price_normal.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_price.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_finance_commission.toLocaleString()}</td>
                    <td className="text-right text-nowrap text-primary font-weight-bold">{NumberUtil.addCommasZeroInt(sum_refund_difference)}</td>
                    <td className="text-right font-weight-bold">{sum_car_cost.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_car_cost_commissions.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_car_cost_repair.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_car_cost_tax.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_car_cost_act.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_car_cost_other.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_cost_operation.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_cost_service.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_cost_transport.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_cost_porobor_lan.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_cost_bangkhab_phasi_lan.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_cost_bangkhab_xon_lan.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_cost_check_ton.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_car_external_commission.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_sales_commission.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_additional_commission.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_advertising_cost.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_delivery_fee.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_promotion_fee.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_finance_fee.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_transfer_fee.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_cost_after_sales_service.toLocaleString()}</td>
                    <td className="text-right text-nowrap text-danger font-weight-bold">-{NumberUtil.addCommasZeroInt(sum_amount_of_debt)}</td>
                    <td className="text-right font-weight-bold">{sum_profit_after_refund.toLocaleString()}</td>
                </>
            )
        } else {
            return(
                <>
                    <td className="text-right font-weight-bold">{sum_price_normal.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_price.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_tax_invoice_vat.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_finance_commission.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_vat_commission.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_down_total.toLocaleString()}</td>
                    <td className="text-right text-nowrap text-primary font-weight-bold">{NumberUtil.addCommasZeroInt(sum_refund_difference)}</td>

                    <td className="text-right font-weight-bold">{sum_car_cost.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_vat_buy.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_car_cost_commissions.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_car_cost_repair.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_car_cost_tax.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_car_cost_act.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_car_cost_other.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_cost_operation.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_cost_service.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_cost_transport.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_cost_porobor_lan.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_cost_bangkhab_phasi_lan.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_cost_bangkhab_xon_lan.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_cost_check_ton.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_car_external_commission.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_sales_commission.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_additional_commission.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_advertising_cost.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_delivery_fee.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_promotion_fee.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_finance_fee.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_transfer_fee.toLocaleString()}</td>
                    <td className="text-right font-weight-bold">{sum_cost_after_sales_service.toLocaleString()}</td>

                    <td className={PORT === 9043 ? 'text-right font-weight-bold' : 'hidden'} >{sum_cost_phra.toLocaleString()}</td>
                    <td className={PORT === 9043 ? 'text-right font-weight-bold' : 'hidden'} >{sum_cost_vat_and_property_tax.toLocaleString()}</td>


                    <td className="text-right font-weight-bold">{NumberUtil.addCommasZeroInt(sum_expense_finance)}</td>
                    <td className="text-right text-nowrap text-danger font-weight-bold">-{NumberUtil.addCommasZeroInt(sum_amount_of_debt)}</td>
                    <td className="text-right font-weight-bold">{sum_profit_after_refund.toLocaleString()}</td>
                </>
            )
        }
    }

  render () {

      const {lists} = this.state;

      let employee_list = this.state.employees.map((employee, index) => {
          return (
              <option key={index} value={employee.employee_id}>{employee.employee_name}&nbsp;&nbsp;{employee.employee_lastname}</option>
          )
      });

      /*let branch_list = this.state.branches.map((branch, index) => {
          return (
              <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
          )
      });*/

      let bank_finance_list = this.state.bank.map((bank, index) => {
          return (
              <option key={index} value={bank.bank_id}>{bank.bank_name}</option>
          )
      })

    const emptyBody = <tr><td colSpan={20} className="text-center text-danger" > ไม่พบข้อมูล </td></tr>

    let refund_arr = []
    const listRows = lists.map((car, index) => {
      return (
        <tr key={index} style={index%2===0? { backgroundColor: ''} : { backgroundColor: '#e4e5e6'}}>
          <td className="text-center text-nowrap">{index+1}</td>
            {this.getValueColumn(car, refund_arr)}
        </tr>
      )
    })

    const tableRows = lists.length === 0 ? emptyBody : listRows

    const tableSum = <tr key={'sum'}>
                  <td className="text-right font-weight-bold" colSpan={PORT === 9005 ? 5 : (PORT === 9048 ? 7 : 6)}>รวม</td>
                    {this.getSumColumn(refund_arr)}
                </tr>

    //let form_controls = this.state.form_controls
    //let additional_commission_label = getFormControlName('additional_commission', form_controls)

    return (
      <div>
        <Loading isLoading={this.state.isLoading} />

        <div className="card-block" style={styles.overflowY}>
          <div className="row">

              <div className="card card-accent-primary">
                <div className="card-header">
                  <strong className="text-title">รายงานการขาย</strong>
                </div>
                <div className="card-block">

                  <div className="row mt-1">
                    <div className="mt-1 ml-3">
                      จาก
                    </div>

                    <div className="col-1" style={{width: 180}}>
                      <DatePicker
                        selected={this.state.date_start}
                        onChange={this.handleDateStart}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                    <div className="mt-1 ml-2">
                      ถึง
                    </div>
                    <div className="col-1" style={{width: 180}}>
                      <DatePicker
                        selected={this.state.date_end}
                        onChange={this.handleDateEnd}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control d-block"
                        placeholder=""
                      />
                    </div>

                      <div className="form-group ml-2 mr-2" style={{width: 160}}>
                          <select
                              className="form-control"
                              id="bank_finance_id"
                              name="bank_finance_id"
                              onChange={(e) => {
                                  this.setState({
                                      bank_finance_id : e.target.value
                                  });
                              }}
                          >
                              <option value=""> เลือกไฟแนนซ์</option>
                              {bank_finance_list}
                          </select>
                      </div>

                      <div className="form-group ml-2 mr-2" style={{width: 160}}>
                          <select className="form-control"
                                  id="person_vat_id"
                                  name="person_vat_id"
                                  value={this.state.person_vat_id}
                                  onChange={(e) => {
                                      this.setState({
                                          person_vat_id : e.target.value
                                      })
                                  }}
                          >
                              <option value="0">ประเภทการซื้อ</option>
                              <option value="1">บุคคล/รถบ้าน</option>
                              <option value="2">รถ VAT</option>
                              <option value="3">รถฝากขาย</option>
                          </select>
                      </div>

                      <div className="form-group ml-2 mr-2" style={{width: 280}}>
                          <Select
                              styles={customStyles}
                              placeholder="เลือกสาขา"
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              defaultValue={[]}
                              isMulti
                              options={this.state.branches}
                              onChange={(val) => {
                                  this.setState({
                                      branches_select: val,
                                  })
                              }}
                          />

                      </div>

                      <div className="form-group ml-2 mr-3" style={{width: 170}}>
                          <select className="form-control"
                                  id="employee_id"
                                  name="employee_id"
                                  value={this.state.employee_id}
                                  onChange={(e) => {

                                      this.setState({
                                          employee_id: e.target.value
                                      })
                                  }}
                          >
                              <option value=''> เลือกพนักงานผู้ขาย </option>
                              {employee_list}
                          </select>
                      </div>

                    <div className="d-flex mr-2">
                      <button type="button" className="btn btn-sm btn-primary" onClick={() => this.loadSellData()} > ตกลง </button>
                    </div>

                    <div className="d-flex">
                      <button type="button" className="btn btn-sm btn-success" onClick={() => this.exportSellData()} > Excel </button>
                    </div>

                    <div className="col-5 text-right pt-2"></div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-12">
                      <table className="" border={1} style={{border: 1}} >
                        <thead>
                        {this.getHeaderColumn()}
                        </thead>
                        <tbody>
                         {tableRows}
                         {tableSum}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    )
  }
}

// eeede8
const styles = {
  headerTable: {
    backgroundColor: '#eeede8',
    verticalAlign: 'middle',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowReceive: {
    backgroundColor: '#bfcad0',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowExpense: {
    backgroundColor: '#ede4e3',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowProfit: {
    backgroundColor: '#95E06C',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  overflowY: {
    overflow: 'scroll',
  }
}

const customStyles = {
    control: (provided) => ({ // class attribute : class=" css-i32vvf-control"
        ...provided,
        background: 'transparent',
        display: 'flex',
        flexWrap: 'nowrap',
        borderRadius: 0,
        height: 5
    }),
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

// export default SellReport;
export default connect(mapStateToProps)(SellReport);
