import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import FinanceTaxInvoice from '../FinanceTaxInvoice';
import FinanceTaxCommission from '../FinanceTaxCommission';
import axios from 'axios';
import { APIURL } from '../../../config/config';
import { AlertError } from '../../Alert/Alert';
import PaymentVoucher from "../../PaymentVoucher/PaymentVoucher";
import {connect} from "react-redux";
import {checkGroupPermission, getGroupPermission} from "../../../services/grouppermission";

class FinanceTaxTabs extends Component{
  constructor (props) {
    super(props)
    this.state = {
      activeTab: '1',
      car_sell_id: null,
      tax_customer_id: null,
      tax_invoice_status: '',
      tax_commission_status: '',
      menuID: [813],
      grouppermissions: [],
      user: JSON.parse(this.props.user),
    };

    this.toggle = this.toggle.bind(this);
    this.loadCarSellById = this.loadCarSellById.bind(this);
    this.onReloadCarSell = this.onReloadCarSell.bind(this);
    this.loadGroupPermission = this.loadGroupPermission.bind(this);
  }

  // componentWillMount() {
  //   this.setState({
  //
  //   });
  // }

  componentDidMount () {
    this.mounted = true
    const queryString = require('query-string')
    let car_sell = queryString.parse(this.props.location.search)
    let car_sell_id = car_sell.car_sell_id
    let activeTab = car_sell.tab === undefined ? '1' : (car_sell.tab === 'finance' ? '1' : '2')
    this.loadGroupPermission()
    this.setState({
      car_sell_id: car_sell_id,
      activeTab: activeTab
    },() => this.loadCarSellById())
  }

  loadGroupPermission() {
    let type = this.state.user.type
    let menuID = this.state.menuID
    // console.log(type, menuID)
    getGroupPermission(type, menuID).then(res => {
      if(res.data.result === 'success') {
        this.setState({
          grouppermissions: res.data.grouppermissions
        })
      }
    })
  }

  componentWillUnmount() {
    this.mounted = false
  }

  loadCarSellById () {
    const { car_sell_id } = this.state

    axios.get(APIURL + '/car_sell/' + car_sell_id)
      .then(res => {
        if (this.mounted) {
          let car_sell = res.data[0]

          this.setState({
            tax_customer_id: car_sell.tax_customer_id,
            tax_invoice_status: car_sell.tax_invoice_status,
            tax_commission_status: car_sell.tax_commission_status
          })

        }
      })

  }

  onReloadCarSell(car_sell, is_load) {
    if (car_sell !== null) {
      // this.loadCarSellById()
      this.setState({
        tax_customer_id: car_sell.tax_customer_id,
        tax_invoice_status: car_sell.tax_invoice_status,
        tax_commission_status: car_sell.tax_commission_status
      })
    } else {
      if (is_load) {
        this.loadCarSellById()
      }
    }
  }


  toggle(tab) {

    if (this.state.tax_customer_id === null && tab==='2') {
      AlertError('กรุณาออกใบกำกับไฟแนนซ์ก่อนทำรายการ')
      return
    }

    if (this.state.tax_invoice_status === 'cancel' && this.state.tax_commission_status === 'nodata' && tab==='2') {
      AlertError('ใบกำกับไฟแนนซ์ถูกยกเลิก ไม่สามารถออกใบกำกับ (ค่าคอมไฟแนนซ์) ได้')
      return
    }

    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render () {
    return (
      <div>

        <Nav tabs>

          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}
            >
              <i className="icon-printer"/> ใบกำกับไฟแนนซ์
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}
            >
              <i className="icon-badge"/> ใบกำกับ (ค่าคอมไฟแนนซ์)
            </NavLink>
          </NavItem>

          {
            checkGroupPermission(813, this.state.grouppermissions).approved ? <>
              <NavItem>
                <NavLink
                    className={classnames({ active: this.state.activeTab === '3' })}
                    onClick={() => { this.toggle('3'); }}
                >
                  <i className="icon-badge"/> ใบสำคัญจ่าย
                </NavLink>
              </NavItem>
            </> : null
          }


        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            {
              this.state.car_sell_id !== null ? <FinanceTaxInvoice
                  car_sell_id={this.state.car_sell_id}
                  onReloadCarSell={this.onReloadCarSell}
                  tax_customer_id = {this.state.tax_customer_id}
                  tax_invoice_status ={this.state.tax_invoice_status}

              /> : 'Loading...'
            }
          </TabPane>

          <TabPane tabId="2">
            {
              this.state.car_sell_id !== null ? <FinanceTaxCommission car_sell_id={this.state.car_sell_id}  /> : 'Loading...'
            }
          </TabPane>

          {
            checkGroupPermission(813, this.state.grouppermissions).approved ? <>
              <TabPane tabId="3">
                {
                  this.state.car_sell_id !== null ?
                      <PaymentVoucher
                          car_sell_id={this.state.car_sell_id}
                          payment_voucher_type = 'car_sell'
                      />
                      :
                      'Loading...'
                }
              </TabPane>
            </> : null
          }


        </TabContent>

      </div>
    )
  }
}

// export default FinanceTaxTabs
const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(FinanceTaxTabs)