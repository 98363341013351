// CarAlbumList
import React, { Component } from 'react';
import axios from 'axios';
import {connect} from 'react-redux';
import * as FileSaver from "file-saver";
import {
  APIURL,
  IMAGE_THUMB_URL,
  IMAGE_NOPHOTO_URL,
  IMAGE_FULL_URL,
  IMAGE_UPLOAD_ERROR, CLOUDFRONT_URL
} from '../../../config/config'
import { checkGroupPermission } from '../../../services/grouppermission'
import { AlertSuccess } from '../../Alert/Alert'
import Swal from "sweetalert2";
import Loading from '../../Loading'
import { NumberUtil } from '../../../utils/number-util'
import { Slide } from 'react-slideshow-image'
import { Modal, ModalBody } from 'reactstrap'
import PreviewImage from '../../../views/PreviewImage'
// import UrlImageDownloader from 'react-url-image-downloader'

// const randomString = require('random-string')

class CarAlbumList extends Component {

  constructor (props){
    super(props)

    this.state = {
      user: {},
      front_car: IMAGE_NOPHOTO_URL,
      front_right: IMAGE_NOPHOTO_URL,
      front_left: IMAGE_NOPHOTO_URL,
      side_car_left: IMAGE_NOPHOTO_URL,
      side_car_right: IMAGE_NOPHOTO_URL,
      back_straight: IMAGE_NOPHOTO_URL,
      condition_in_car: IMAGE_NOPHOTO_URL,
      engine_car: IMAGE_NOPHOTO_URL,
      console_car: IMAGE_NOPHOTO_URL,
      plate_car: IMAGE_NOPHOTO_URL,
      other_car: IMAGE_NOPHOTO_URL,
      console: IMAGE_NOPHOTO_URL,
      gear: IMAGE_NOPHOTO_URL,
      audio_equipment: IMAGE_NOPHOTO_URL,
      dial: IMAGE_NOPHOTO_URL,
      catalyticconverter: IMAGE_NOPHOTO_URL,
      yearoftire: IMAGE_NOPHOTO_URL,
      chassis: IMAGE_NOPHOTO_URL,
      other1: IMAGE_NOPHOTO_URL,
      other2: IMAGE_NOPHOTO_URL,
      other3: IMAGE_NOPHOTO_URL,
      //
      front_car_full: IMAGE_NOPHOTO_URL,
      front_right_full: IMAGE_NOPHOTO_URL,
      front_left_full: IMAGE_NOPHOTO_URL,
      side_car_left_full: IMAGE_NOPHOTO_URL,
      side_car_right_full: IMAGE_NOPHOTO_URL,
      back_straight_full: IMAGE_NOPHOTO_URL,
      condition_in_car_full: IMAGE_NOPHOTO_URL,
      engine_car_full: IMAGE_NOPHOTO_URL,
      console_car_full: IMAGE_NOPHOTO_URL,
      plate_car_full: IMAGE_NOPHOTO_URL,
      other_car_full: IMAGE_NOPHOTO_URL,
      console_full: IMAGE_NOPHOTO_URL,
      gear_full: IMAGE_NOPHOTO_URL,
      audio_equipment_full: IMAGE_NOPHOTO_URL,
      dial_full: IMAGE_NOPHOTO_URL,
      catalyticconverter_full: IMAGE_NOPHOTO_URL,
      yearoftire_full: IMAGE_NOPHOTO_URL,
      chassis_full: IMAGE_NOPHOTO_URL,
      other1_full: IMAGE_NOPHOTO_URL,
      other2_full: IMAGE_NOPHOTO_URL,
      other3_full: IMAGE_NOPHOTO_URL,
      //
      front_car2: IMAGE_NOPHOTO_URL,
      front_right2: IMAGE_NOPHOTO_URL,
      front_left2: IMAGE_NOPHOTO_URL,
      side_car_left2: IMAGE_NOPHOTO_URL,
      side_car_right2: IMAGE_NOPHOTO_URL,
      back_straight2: IMAGE_NOPHOTO_URL,
      condition_in_car2: IMAGE_NOPHOTO_URL,
      engine_car2: IMAGE_NOPHOTO_URL,
      console_car2: IMAGE_NOPHOTO_URL,
      plate_car2: IMAGE_NOPHOTO_URL,
      other_car2: IMAGE_NOPHOTO_URL,
      //
      front_car_id2: '0',
      front_right_id2: '0',
      front_left_id2: '0',
      side_car_left_id2: '0',
      side_car_right_id2: '0',
      back_straight_id2: '0',
      console_car_id2: '0',
      condition_in_car_id2: '0',
      engine_car_id2: '0',
      plate_car_id2: '0',
      other_car_id2: '0',
      //
      photos: [],
      front_car_id: 0,
      front_right_id: 0,
      front_left_id: 0,
      side_car_left_id: 0,
      side_car_right_id: 0,
      back_straight_id: 0,
      condition_in_car_id: 0,
      engine_car_id: 0,
      console_car_id: 0,
      plate_car_id: 0,
      other_car_id: 0,
      console_id: 0,
      gear_id: 0,
      audio_equipment_id: 0,
      dial_id: 0,
      catalyticconverter_id: 0,
      yearoftire_id: 0,
      chassis_id: 0,
      other1_id: 0,
      other2_id: 0,
      other3_id: 0,
      photo_sell: [],
      isLoading: false,
      car_id: '',
      upload_code: '',
      car: null,
      preview_image_url: '',
      preview_image_select: false
    }

    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.handleUploadFile2 = this.handleUploadFile2.bind(this);
    this.saveCarImage = this.saveCarImage.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.downloadAll = this.downloadAll.bind(this);
    this.loadCarById = this.loadCarById.bind(this);
    this.onPreviewSelected = this.onPreviewSelected.bind(this);
    this.togglePreviewImage = this.togglePreviewImage.bind(this);

  }

  togglePreviewImage() {
    this.setState({
      preview_image_select: !this.state.preview_image_select
    });
  }

  componentWillMount() {
    const queryString = require('query-string');
    let car = queryString.parse(this.props.location.search);

    this.setState({
      user: JSON.parse(this.props.user),
      car_id: car.car_id,
      upload_code: car.upload_code
    });
  }

  componentDidMount() {
     // let upload_code = this.props.car.car_upload_code
     // this.getCarUploadPhoto(componentDidMountupload_code);
      // console.log('upload_code=', this.state.upload_code)
      this.loadCarById()
      this.getCarUploadPhoto(this.state.upload_code)

  }

  getCarUploadPhoto(upload_code){

    if (upload_code === '') {
      return
    }

    //alert(APIURL + '/car_upload/'+upload_code)
    axios.get(APIURL + '/car_upload/'+upload_code)
      .then(res => {

        let alfabet = "2"
        let other2 = "other2"
        let other_car = "other_car2" && "other_car22" && "other_car23"

        // ไม่รวมก่อนแต่ง
        let photo_sell = res.data.filter(photo => {
            return photo.car_upload_position.slice(-1) !== alfabet
              && photo.car_upload_position !== other_car
              || photo.car_upload_position === other2
          }
        );

        // console.log(photo_sell)

        this.setState({photos: res.data, photo_sell: photo_sell},()=>{
          this.setCarAlbum();
        })

      }).catch(error => {
      alert("error="+error)
      this.setState({photos: []})
    })
  }

  loadCarById(){
    //var that = this
    axios.get(APIURL + '/car/'+this.state.car_id)
      .then(res => {
        let car =  res.data[0]
        console.log(car)
        this.setState({car: car })
      }).catch(error => {
      // alert("error"+error)
      this.setState({car: {}})
    })
  }

  setCarAlbum(){

    // eslint-disable-next-line
    this.state.photos.map(photo => {

      let image_url = '';

      if (photo.car_upload_filename.includes('https')) {
        // image_url = photo.car_upload_filename
        let car_upload_key = photo.car_upload_key
        image_url = CLOUDFRONT_URL+car_upload_key
      } else {
        image_url =  IMAGE_FULL_URL+photo.car_upload_filename
      }

      if (photo.car_upload_position === 'front_car') {
        this.setState({
          front_car: image_url,
          front_car_full: image_url,
          front_car_id: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'front_right'){
        this.setState({
           front_right: image_url,
           front_right_full: image_url,
           front_right_id: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'front_left'){
        this.setState({
          front_left: image_url,
          front_left_full: image_url,
          front_left_id: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'side_car_left'){
        this.setState({
          side_car_left: image_url,
          side_car_left_full: image_url,
          side_car_left_id: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'side_car_right'){
        this.setState({
          side_car_right: image_url,
          side_car_right_full: image_url,
          side_car_right_id: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'back_straight'){
        this.setState({
          back_straight: image_url,
          back_straight_full: image_url,
          back_straight_id: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'condition_in_car'){
        this.setState({
          condition_in_car: image_url,
          condition_in_car_full: image_url,
          condition_in_car_id: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'engine_car'){
        this.setState({
          engine_car: image_url,
          engine_car_full: image_url,
          engine_car_id: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'console_car'){
        this.setState({
          console_car: image_url,
          console_car_full: image_url,
          console_car_id: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'plate_car'){
        this.setState({
          plate_car: image_url,
          plate_car_full: image_url,
          plate_car_id: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'other_car'){
        this.setState({
          other_car: image_url,
          other_car_full: image_url,
          other_car_id: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'console'){
        this.setState({
          console: image_url,
          console_full: image_url,
          console_id: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'gear'){
        this.setState({
          gear: image_url,
          gear_full: image_url,
          gear_id: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'audio_equipment'){
        this.setState({
          audio_equipment: image_url,
          audio_equipment_full: image_url,
          audio_equipment_id: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'dial'){
        this.setState({
          dial: image_url,
          dial_full: image_url,
          dial_id: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'catalyticconverter'){
        this.setState({
          catalyticconverter: image_url,
          catalyticconverter_full: image_url,
          catalyticconverter_id: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'yearoftire'){
        this.setState({
          yearoftire: image_url,
          yearoftire_full: image_url,
          yearoftire_id: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'chassis'){
        this.setState({
          chassis: image_url,
          chassis_full: image_url,
          chassis_id: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'other1'){
        this.setState({
          other1: image_url,
          other1_full: image_url,
          other1_id: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'other2'){
        this.setState({
          other2: image_url,
          other2_full: image_url,
          other2_id: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'other3'){
        this.setState({
          other3: image_url,
          other3_full: image_url,
          other3_id: photo.car_upload_id
        })
      }

      /// ภาพก่อนแต่ง
      if (photo.car_upload_position === 'front_car2') {
        this.setState({
          front_car2: image_url,
          front_car_id2: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'front_right2'){
        this.setState({
          front_right2: image_url,
          front_right_id2: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'front_left2'){
        this.setState({
          front_left2: image_url,
          front_left_id2: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'side_car_left2'){
        this.setState({
          side_car_left2: image_url,
          side_car_left_id2: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'side_car_right2'){
        // side_car_right2: IMAGE_THUMB_URL+photo.car_upload_filename,
        this.setState({
          side_car_right2: image_url,
          side_car_right_id2: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'back_straight2'){
        this.setState({
          back_straight2: image_url,
          back_straight_id2: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'condition_in_car2'){
        this.setState({
          condition_in_car2: image_url,
          condition_in_car_id2: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'engine_car2'){
        this.setState({
          engine_car2: image_url,
          engine_car_id2: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'console_car2'){
        this.setState({
          console_car2: image_url,
          console_car_id2: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'plate_car2'){
        this.setState({
          plate_car2: image_url,
          plate_car_id2: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'other_car2'){
        this.setState({
          other_car2: image_url,
          other_car_id2: photo.car_upload_id
        })
      }
    });
  }

  handleUploadFile (event){

    // if(checkGroupPermission(this.props.menuID, this.props.grouppermissions).modified === 0 ) {
    //   AlertSuccess('สิทธิ์ของท่านไม่สามารถแก้ไขภาพรถได้');
    //   return
    // }

    let input_file_name = event.target.name
    let that = this;

    const data = new FormData();

    data.append('uploads', event.target.files[0], event.target.files[0].name)
    data.append('upload_code', this.props.upload_code);
    data.append('user_updated', this.state.user.user);

    // data.append('car_upload_position', input_file_name);
    // alert(this.state.upload_code)

    if( input_file_name === 'front_car'){
      this.refs.img_front_car.src='img/loading.gif'
      this.refs.img_front_car.height=32
      data.append('car_upload_position', 'front_car');
      data.append('car_upload_id', this.state.front_car_id)
    }else if(input_file_name === 'front_right'){
      this.refs.img_front_right.src='img/loading.gif'
      data.append('car_upload_position', 'front_right');
      data.append('car_upload_id', this.state.front_right_id )
    }else if(input_file_name === 'front_left'){
      this.refs.img_front_left.src = 'img/loading.gif'
      data.append('car_upload_position', 'front_left');
      data.append('car_upload_id', this.state.front_left_id )
    }else if(input_file_name === 'side_car_left'){
      this.refs.img_side_car_left.src = 'img/loading.gif'
      data.append('car_upload_position', 'side_car_left');
      data.append('car_upload_id', this.state.side_car_left_id )
    }else if(input_file_name === 'side_car_right'){
      this.refs.img_side_car_right.src = 'img/loading.gif'
      data.append('car_upload_position', 'side_car_right');
      data.append('car_upload_id', this.state.side_car_right_id )
    }else if(input_file_name === 'back_straight'){
      this.refs.img_back_straight.src = 'img/loading.gif'
      data.append('car_upload_position', 'back_straight');
      data.append('car_upload_id', this.state.back_straight_id )
    }else if(input_file_name === 'console_car'){
      this.refs.img_console_car.src = 'img/loading.gif'
      data.append('car_upload_position', 'console_car');
      data.append('car_upload_id', this.state.console_car_id )
    }else if(input_file_name === 'condition_in_car'){
      this.refs.img_condition_in_car.src = 'img/loading.gif'
      data.append('car_upload_position', 'condition_in_car');
      data.append('car_upload_id', this.state.condition_in_car_id )
    }else if(input_file_name === 'engine_car'){
      this.refs.img_engine_car.src = 'img/loading.gif'
      data.append('car_upload_position', 'engine_car');
      data.append('car_upload_id', this.state.engine_car_id )
    }else if(input_file_name === 'plate_car'){
      this.refs.img_plate_car.src = 'img/loading.gif'
      data.append('car_upload_position', 'plate_car');
      data.append('car_upload_id', this.state.plate_car_id )
    }else if(input_file_name === 'other_car'){
      this.refs.img_other_car.src = 'img/loading.gif'
      data.append('car_upload_position', 'other_car');
      data.append('car_upload_id', this.state.other_car_id )
      // add เพิ่ม
    } else if (input_file_name === 'console') {
      // 12.คอนโซล
      this.refs.img_console.src = 'img/loading.gif'
      data.append('car_upload_position', 'console')
      data.append('car_upload_id', this.state.console_id )
    } else if (input_file_name === 'gear') {
      // 13.เกียร์
      this.refs.img_gear.src = 'img/loading.gif'
      data.append('car_upload_position', 'gear')
      data.append('car_upload_id', this.state.gear_id )
    } else if (input_file_name === 'audio_equipment') {
      // 14.เครื่องเสียง
      this.refs.img_audio_equipment.src = 'img/loading.gif'
      data.append('car_upload_position', 'audio_equipment')
      data.append('car_upload_id', this.state.audio_equipment_id )
    } else if (input_file_name === 'dial') {
      // 15) หน้าปัด
      this.refs.img_dial.src = 'img/loading.gif'
      data.append('car_upload_position', 'dial')
      data.append('car_upload_id', this.state.dial_id )
    } else if (input_file_name === 'catalyticconverter') {
      // 16) ท่อแคท
      this.refs.img_catalyticconverter.src = 'img/loading.gif'
      data.append('car_upload_position', 'catalyticconverter')
      data.append('car_upload_id', this.state.catalyticconverter_id )
    } else if (input_file_name === 'yearoftire') {
      // 17) ปียางรถ
      this.refs.img_yearoftire.src = 'img/loading.gif'
      data.append('car_upload_position', 'yearoftire')
      data.append('car_upload_id', this.state.yearoftire_id )
    } else if (input_file_name === 'chassis') {
      // 18) คัดซี
      this.refs.img_chassis.src = 'img/loading.gif'
      data.append('car_upload_position', 'chassis')
      data.append('car_upload_id', this.state.chassis_id )
    } else if (input_file_name === 'other1') {
      // 19) อื่นๆ 1
      this.refs.img_other1.src = 'img/loading.gif'
      data.append('car_upload_position', 'other1')
      data.append('car_upload_id', this.state.other1_id )
    } else if (input_file_name === 'other2') {
      // 20) อื่นๆ 2
      this.refs.img_other2.src = 'img/loading.gif'
      data.append('car_upload_position', 'other2')
      data.append('car_upload_id', this.state.other2_id )
    } else if (input_file_name === 'other3') {
      // 21) อื่นๆ 3
      this.refs.img_other3.src = 'img/loading.gif'
      data.append('car_upload_position', 'other3')
      data.append('car_upload_id', this.state.other3_id )
    }

    //alert(input_file_name)
    axios.put(APIURL+'/car_upload', data)
      .then(function(response){

        var data = response.data ;

        let car_upload_id = 0

        if (data.car_upload_id !== undefined) {
          car_upload_id = data.car_upload_id
        }

        var image_url = '';

        if(data.image.includes('https')) {
          image_url = data.image;
        } else {
          image_url = IMAGE_THUMB_URL+data.image;
        }

        if(data.image === undefined) {
          image_url = IMAGE_UPLOAD_ERROR
        } else if (data.image === 'uploaderror') {
          image_url = IMAGE_UPLOAD_ERROR
        }

        var img = new Image();
        img.src= image_url;
        img.addEventListener("load", function () {

          if( input_file_name === 'front_car'){
            that.refs.img_front_car.src = image_url
            that.setState({ front_car_id: car_upload_id })
          }else if(input_file_name === 'front_right'){
            that.refs.img_front_right.src= image_url
            that.setState({ front_right_id: car_upload_id })
            that.setState({ front_right_full: image_url })
          }else if(input_file_name === 'front_left'){
            that.refs.img_front_left.src = image_url
            that.setState({ front_left_id: car_upload_id })
            that.setState({ front_left_full: image_url })
          }else if(input_file_name === 'side_car_left'){
            that.refs.img_side_car_left.src = image_url
            that.setState({ side_car_left_id: car_upload_id })
            that.setState({ side_car_left_full: image_url })
          }else if(input_file_name === 'side_car_right'){
            that.refs.img_side_car_right.src = image_url
            that.setState({ side_car_right_id: car_upload_id })
            that.setState({ side_car_right_full: image_url })
          }else if(input_file_name === 'back_straight'){
            that.refs.img_back_straight.src = image_url
            that.setState({ back_straight_id: car_upload_id })
            that.setState({ back_straight_full: image_url })
          }else if(input_file_name === 'console_car'){
            that.refs.img_console_car.src = image_url
            that.setState({ console_car_id: car_upload_id })
            that.setState({ console_car_full: image_url })
          }else if(input_file_name === 'condition_in_car'){
            that.refs.img_condition_in_car.src = image_url
            that.setState({ condition_in_car_id: car_upload_id })
            that.setState({ condition_in_car_full: image_url })
          }else if(input_file_name === 'engine_car'){
            that.refs.img_engine_car.src = image_url
            that.setState({ engine_car_id: car_upload_id })
            that.setState({ engine_car_full: image_url })
          }else if(input_file_name === 'plate_car'){
            that.refs.img_plate_car.src = image_url
            that.setState({ plate_car_id: car_upload_id })
            that.setState({ plate_car_full: image_url })
          }else if(input_file_name === 'other_car'){
            that.refs.img_other_car.src = image_url
            that.setState({ other_car_id: car_upload_id })
            that.setState({ other_car_full: image_url })
            // add เพิ่ม
          } else if (input_file_name === 'console') {
            // 12
            that.refs.img_console.src = image_url
            that.setState({ console_id: car_upload_id })
            that.setState({ console_full: image_url })
          } else if (input_file_name === 'gear') {
            // 13
            that.refs.img_gear.src = image_url
            that.setState({ gear_id: car_upload_id })
            that.setState({ gear_full: image_url })
          } else if (input_file_name === 'audio_equipment') {
            // 14
            that.refs.img_audio_equipment.src = image_url
            that.setState({ audio_equipment_id: car_upload_id })
            that.setState({ audio_equipment_full: car_upload_id })
          } else if (input_file_name === 'dial') {
            // 15
            that.refs.img_dial.src = image_url
            that.setState({ dial_id: car_upload_id })
            that.setState({ dial_full: car_upload_id })
          } else if (input_file_name === 'catalyticconverter') {
            // 16
            that.refs.img_catalyticconverter.src = image_url
            that.setState({ catalyticconverter_id: car_upload_id })
            that.setState({ catalyticconverter_full: car_upload_id })
          } else if (input_file_name === 'yearoftire') {
            // 17
            that.refs.img_yearoftire.src = image_url
            that.setState({ yearoftire_id: car_upload_id })
            that.setState({ yearoftire_full: car_upload_id })
          } else if (input_file_name === 'chassis') {
            // 18 คัทซี
            that.refs.img_chassis.src = image_url
            that.setState({ chassis_id: car_upload_id })
            that.setState({ chassis_full: car_upload_id })
          } else if (input_file_name === 'other1') {
            // 19 อื่นๆ 1
            that.refs.img_other1.src = image_url
            that.setState({ other1_id: car_upload_id })
            that.setState({ other1_full: car_upload_id })
          } else if (input_file_name === 'other2') {
            // 20 อื่นๆ 2
            that.refs.img_other2.src = image_url
            that.setState({ other2_id: car_upload_id })
            that.setState({ other2_full: car_upload_id })
          } else if (input_file_name === 'other3') {
            // 21 อื่นๆ 3
            that.refs.img_other3.src = image_url
            that.setState({ other3_id: car_upload_id })
            that.setState({ other3_full: car_upload_id })
          }

        });

      })
      .catch(function(error){
        console.log('error:' + error.response);
        // alert("error:"+error.response);
      });

  }

  handleUploadFile2 (event){

    // if(checkGroupPermission(this.props.menuID, this.props.grouppermissions).modified === 0 ) {
    //   AlertSuccess('สิทธิ์ของท่านไม่สามารถแก้ไขภาพรถได้');
    //   return
    // }

    let input_file_name = event.target.name
    let that = this;

    const data = new FormData();

    data.append('uploads', event.target.files[0], event.target.files[0].name)
    data.append('upload_code', this.props.upload_code);
    data.append('user_updated', this.state.user.user);

    // data.append('car_upload_position', input_file_name);
    // alert(this.state.upload_code)

    if( input_file_name === 'front_car2'){
      this.refs.img_front_car2.src='img/loading.gif'
      this.refs.img_front_car2.height=32
      data.append('car_upload_position', 'front_car2');
      data.append('car_upload_id', this.state.front_car_id2)
    }else if(input_file_name === 'front_right2'){
      this.refs.img_front_right2.src='img/loading.gif'
      data.append('car_upload_position', 'front_right2');
      data.append('car_upload_id', this.state.front_right_id2 )
    }else if(input_file_name === 'front_left2'){
      this.refs.img_front_left2.src = 'img/loading.gif'
      data.append('car_upload_position', 'front_left2');
      data.append('car_upload_id', this.state.front_left_id2 )
    }else if(input_file_name === 'side_car_left2'){
      this.refs.img_side_car_left2.src = 'img/loading.gif'
      data.append('car_upload_position', 'side_car_left2');
      data.append('car_upload_id', this.state.side_car_left_id2 )
    }else if(input_file_name === 'side_car_right2'){
      this.refs.img_side_car_right2.src = 'img/loading.gif'
      data.append('car_upload_position', 'side_car_right2');
      data.append('car_upload_id', this.state.side_car_right_id2 )
    }else if(input_file_name === 'back_straight2'){
      this.refs.img_back_straight2.src = 'img/loading.gif'
      data.append('car_upload_position', 'back_straight2');
      data.append('car_upload_id', this.state.back_straight_id2 )
    }else if(input_file_name === 'console_car2'){
      this.refs.img_console_car2.src = 'img/loading.gif'
      data.append('car_upload_position', 'console_car2');
      data.append('car_upload_id', this.state.console_car_id2 )
    }else if(input_file_name === 'condition_in_car2'){
      this.refs.img_condition_in_car2.src = 'img/loading.gif'
      data.append('car_upload_position', 'condition_in_car2');
      data.append('car_upload_id', this.state.condition_in_car_id2 )
    }else if(input_file_name === 'engine_car2'){
      this.refs.img_engine_car2.src = 'img/loading.gif'
      data.append('car_upload_position', 'engine_car2');
      data.append('car_upload_id', this.state.engine_car_id2 )
    }else if(input_file_name === 'plate_car2'){
      this.refs.img_plate_car2.src = 'img/loading.gif'
      data.append('car_upload_position', 'plate_car2');
      data.append('car_upload_id', this.state.plate_car_id2 )
    }else if(input_file_name === 'other_car2'){
      this.refs.img_other_car2.src = 'img/loading.gif'
      data.append('car_upload_position', 'other_car2');
      data.append('car_upload_id', this.state.other_car_id2 )
    }

    //alert(input_file_name)
    axios.put(APIURL+'/car_upload', data)
      .then(function(response){
        var data = response.data ;
        let car_upload_id = 0

        if (data.car_upload_id !== undefined) {
          car_upload_id = data.car_upload_id
        }
        /*var image_url = IMAGE_THUMB_URL+data.image;

        if(data.image === undefined) {
          image_url = IMAGE_UPLOAD_ERROR
        } else if (data.image === 'uploaderror') {
          image_url = IMAGE_UPLOAD_ERROR
        }*/

        var image_url = '';


        if(data.image.includes('https')) {
          image_url = data.image;
        } else {
          image_url = IMAGE_FULL_URL+data.image;
        }

        if(data.image === undefined) {
          image_url = IMAGE_UPLOAD_ERROR
        } else if (data.image === 'uploaderror') {
          image_url = IMAGE_UPLOAD_ERROR
        }

        var img = new Image();
        img.src= image_url;
        img.addEventListener("load", function () {

          if( input_file_name === 'front_car2'){
            that.refs.img_front_car2.src = image_url
            that.setState({
              front_car_id2: car_upload_id,
              front_car2: image_url
            })
          }else if(input_file_name === 'front_right2'){
            that.refs.img_front_right2.src= image_url
            that.setState({
              front_right_id2: car_upload_id,
              front_right2: image_url
            })
          }else if(input_file_name === 'front_left2'){
            that.refs.img_front_left2.src = image_url
            that.setState({
              front_left_id2: car_upload_id,
              front_left2: image_url
            })
          }else if(input_file_name === 'side_car_left2'){
            that.refs.img_side_car_left2.src = image_url
            that.setState({
              side_car_left_id2: car_upload_id,
              side_car_left2: image_url
            })
          }else if(input_file_name === 'side_car_right2'){
            that.refs.img_side_car_right2.src = image_url
            that.setState({
              side_car_right_id2: car_upload_id,
              side_car_right2: image_url
            })
          }else if(input_file_name === 'back_straight2'){
            that.refs.img_back_straight2.src = image_url
            that.setState({
              back_straight_id2: car_upload_id,
              back_straight2: image_url
            })
          }else if(input_file_name === 'console_car2'){
            that.refs.img_console_car2.src = image_url
            that.setState({
              console_car_id2: car_upload_id,
              console_car2: image_url
            })
          }else if(input_file_name === 'condition_in_car2'){
            that.refs.img_condition_in_car2.src = image_url
            that.setState({
              condition_in_car_id2: car_upload_id,
              condition_in_car2: image_url
            })
          }else if(input_file_name === 'engine_car2'){
            that.refs.img_engine_car2.src = image_url
            that.setState({
              engine_car_id2: car_upload_id,
              engine_car2: image_url
            })
          }else if(input_file_name === 'plate_car2'){
            that.refs.img_plate_car2.src = image_url
            that.setState({
              plate_car_id2: car_upload_id,
              plate_car2: image_url
            })
          }else if(input_file_name === 'other_car2'){
            that.refs.img_other_car2.src = image_url
            that.setState({
              other_car_id2: car_upload_id,
              other_car2: image_url
            })
          }
        });

      })
      .catch(function(error){
         console.log(error.response);
        // alert("error:"+error.response);
      });

  }

  async downloadAll () {
    let photo_sell = this.state.photo_sell

    this.setState({
      isLoading: true
    }, async () => {
      await photo_sell.map(async (photo, index) => {
        let image_url = '';
        let file_name = ''
        if (photo.car_upload_filename.includes('https')) {
          // image_url = photo.car_upload_filename
          let car_upload_key = photo.car_upload_key
          image_url = CLOUDFRONT_URL+car_upload_key
          file_name = car_upload_key.split('/')[2]
        } else {
          file_name = photo.car_upload_filename
          image_url =  IMAGE_FULL_URL+photo.car_upload_filename
        }
        let car_upload_id = photo.car_upload_id
        let uri =  APIURL + '/car/image/' + car_upload_id;
        let res = await axios.get(uri)
        let imgBase64 = res.data.body

        await FileSaver.saveAs(imgBase64, file_name);

        if (index === photo_sell.length-1) {
          this.setState({
            isLoading: false
          })
        }
      })

    })

  }

  saveCarImage(e,urlImage){
    e.preventDefault();
    let a = document.createElement('a');
    a.href = urlImage;
    a.target = '_blank';
    a.download = urlImage;
    a.click();
    a.remove();
    return false;

    /*
    e.preventDefault();

    let ex_arr = urlImage.split('.');
    let ext = ex_arr[ex_arr.length - 1];
    let random_code = randomString({
      length: 16,
      numeric: true,
      letters: true,
      special: false,
      exclude: ['o', '0', 'l', '1']
    })

    let file_name = 'nk'+random_code+'.'+ext
    // console.log('file_name=', file_name)
    /*
        let element = document.createElement("a");
        let file = new Blob(
          [
            urlImage
          ],
          { type: "image/*" }
        );
        element.href = URL.createObjectURL(file);
        /// element.download = "image.jpg";
        element.download = file_name;
        element.click();
        */

    // e.target.href
    /*fetch(urlImage, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(function(buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", file_name); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        console.log(err);
      });

    ///
    let disableCache = true
    // const fetchUrl = `${e.target.href}${
    // const fetchUrl = `${urlImage}${
    //   disableCache ? `?dummy=${Math.floor(Date.now())}` : ''
    // }`;
    const fetchUrl = urlImage;
    fetch(fetchUrl, {
      method: 'GET',
      headers: {},
      mode: 'no-cors'
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');

          link.href = url;
          link.setAttribute(
            'download',
            file_name
          );
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
    /////
    */

  }

  deleteImage(image_position){
    let upload_code = this.props.upload_code

    // console.log(image_position)
    // console.log(upload_code)

    Swal.fire({
      title: 'ยืนยันการลบภาพ',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        let url = APIURL + '/car/image'
        let senddata = {
          upload_code,
          upload_position: image_position
        }
        axios.post( url, senddata).then(res => {
          let result = res.data
          if(result === "success") {
            AlertSuccess('ลบข้อมูลเรียบร้อยแล้ว')
            console.log(res.data)
            let full = image_position +"_full"

            var lastChar = image_position.substr(image_position.length - 1);
            let car_upload_name = image_position+"_id"

            if(lastChar === '2'){
              let tmp = image_position.replace('2', '')
              car_upload_name = tmp+"_id2"
            }

            //  data.append('car_upload_id', this.state.front_car_id2)
            // console.log(car_upload_name)
            console.log('image_position = ', image_position)
            console.log('car_upload_name = ', car_upload_name)
            this.setState({
              [full]:IMAGE_NOPHOTO_URL,
              [image_position]:IMAGE_NOPHOTO_URL,
              [car_upload_name]: 0
            }, ()=>{
              if(image_position === 'front_right'){
                this.refs.img_front_right.src= IMAGE_NOPHOTO_URL
              }else if (image_position === 'front_left'){
                this.refs.img_front_left.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'side_car_left'){
                this.refs.img_side_car_left.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'side_car_right'){
                this.refs.img_side_car_right.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'back_straight'){
                this.refs.img_back_straight.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'console_car'){
                this.refs.img_console_car.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'condition_in_car'){
                this.refs.img_condition_in_car.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'engine_car'){
                this.refs.img_engine_car.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'plate_car'){
                this.refs.img_plate_car.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'other_car'){
                this.refs.img_other_car.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'console'){
                this.refs.img_console.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'gear'){
                this.refs.img_gear.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'audio_equipment'){
                this.refs.img_audio_equipment.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'dial'){
                this.refs.img_dial.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'catalyticconverter'){
                this.refs.img_catalyticconverter.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'yearoftire'){
                this.refs.img_yearoftire.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'chassis'){
                this.refs.img_chassis.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'other1'){
                this.refs.img_other1.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'other2'){
                this.refs.img_other2.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'other3'){
                this.refs.img_other3.src = IMAGE_NOPHOTO_URL
              }
            })
            // this.loadCarOwnerData()
          }
        }).catch(error => {
          console.log('error:', error)
        })
      }
    })
  }

  onPreviewSelected(url){
    this.setState({
      preview_image_url: url,
      preview_image_select: true
    });
  }

  render () {

    let car = this.state.car

    const responsiveSettings = [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ];

    const divStyle = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundSize: 'cover',
      height: '200px',
      width: '200px'
    }

    let photo_list = this.state.photo_sell.map((photo, index) => {
      let image_url = ''
      if (photo.car_upload_filename.includes('https')) {
        // image_url = photo.car_upload_filename
        // let car_upload_key = photo.car_upload_key
        image_url = CLOUDFRONT_URL+photo.car_upload_key
      } else {
        image_url = IMAGE_FULL_URL + photo.car_upload_filename
      }

      return(
        <div key={index} style={{textAlign: 'center', padding: '5px'}}>
          <img src={image_url} style={{...divStyle}} onClick={()=>this.onPreviewSelected(image_url)}/>
        </div>
      )
    })

    return (
      <div className="row">
        <Loading isLoading={this.state.isLoading} />

        <Modal isOpen={this.state.preview_image_select}
               toggle={this.togglePreviewImage}
               className="modal-book-lg"
        >
          <ModalBody>
            <PreviewImage
              imageUrl={this.state.preview_image_url}
            />
          </ModalBody>
        </Modal>

        {
          this.state.photo_sell.length >= 6 ? (
            <div className="col-12">
              <div className="card">
                <div className="card-block">
                  <strong> รูปภาพรถ <strong style={{color:'red'}}>* กดที่รูปภาพเพื่อดูรูปขนาดใหญ่ </strong></strong>
                  <div className="mt-3">
                    <Slide slidesToScroll={1} slidesToShow={1} indicators={true} autoplay={false} responsive={responsiveSettings}>
                      {photo_list}
                    </Slide>
                  </div>
                </div>
              </div>
            </div>
          ) : null
        }

        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <strong>รูปรถ
                &nbsp;&nbsp;
                <a rel="noopener noreferrer"
                   style={{cursor: 'pointer'}}
                   onClick={(e) => {
                     this.downloadAll()
                   }}
                >
                  <span className="text-primary"> Download All </span>
                </a>
              </strong>
            </div>
            <div className="card-block">
              <div className="row">
                <div  className="col-12 mb-2">
                  {
                    car !== null ? <>
                      <strong>
                        {car.car_name}
                        &nbsp;&nbsp;
                        <span className="badge"
                              style={{ backgroundColor: car.car_status_color }}>{car.car_status_name}
                        </span>
                      </strong>
                      {
                        car.car_license_plate_new === '' ? null : (
                          <>
                            <strong> ทะเบียนรถ : {car.car_license_plate_new}</strong>
                            &nbsp; <strong>จ.{car.province_name_new}</strong>
                          </>
                        )
                      }

                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span className="badge badge-success">
                         ราคาขาย : {NumberUtil.addCommasZeroInt(car.car_sale_price)}
                        </span>

                    </>
                      : null
                  }

                </div>
              </div>
              <div className="row">
                {
                  this.state.photos.map((photo, index) => {
                    let image_url = '';

                    if (photo.car_upload_filename.includes('https')) {
                      // image_url = photo.car_upload_filename
                      let car_upload_key = photo.car_upload_key
                      image_url = CLOUDFRONT_URL+car_upload_key
                    } else {
                      image_url =  IMAGE_FULL_URL+photo.car_upload_filename
                    }

                    return (
                      <div key={index} className="col-md-4 col-sm-12">
                        <div style={styles.carWrapper}  >
                        <img src={image_url}
                             style={styles.carImage}
                             alt={photo.car_upload_position}
                        />
                        </div>
                      </div>
                    )
                  })
                }


              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }

  /*render(){
     return(
      <div className="row">

        xxx

        <Loading isLoading={this.state.isLoading} />

        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <strong>รูปรถ
                &nbsp;&nbsp;
                <a rel="noopener noreferrer"
                   style={{cursor: 'pointer'}}
                   onClick={(e) => {
                     this.downloadAll()
                   }}
                >
                  <span className="text-primary"> Download All </span>
                </a>
              </strong>
            </div>

            <div className="card-block">
      <div className="row">


        <div className="form-group col-sm-4">

          <label htmlFor="name">1) หน้าตรง
             &nbsp;
            <a rel="noopener noreferrer" href={this.state.front_car_full}
               target="_blank"
               onClick={(e)=>this.saveCarImage(e,this.state.front_car_full)}
               className={this.state.front_car_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span>
            </a>

            {/!*<UrlImageDownloader imageUrl={this.state.front_car_full} />*!/}

          </label>

          <div style={styles.fileInputWrapper}  >
            <button style={styles.btnFileInput}>
              <img src={this.state.front_car}
                   style={styles.btnFileInputImage}
                   ref="img_front_car"
                   alt="หน้าตรง"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="front_car"
              onChange={this.handleUploadFile}
            />
          </div>

        </div>

        <div className="form-group col-sm-4">

          <label htmlFor="name">2) หน้าซ้าย
            &nbsp;

            <a rel="noopener noreferrer"
                     href={this.state.front_right_full}
                     target="_blank"
                     onClick={(e)=>this.saveCarImage(e,this.state.front_right_full)}
                     className={this.state.front_right_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span>
            </a>
            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.front_right_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('front_right')}
            >
              <i className="icon-trash"/>
            </button>
          </label>

          <div style={styles.fileInputWrapper} >
            <button style={styles.btnFileInput}>
              <img src={this.state.front_right}
                   style={styles.btnFileInputImage}
                   ref="img_front_right"
                   alt="มุมขวา"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="front_right"
              onChange={this.handleUploadFile}
            />
          </div>

        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">3) หน้าขวา
            &nbsp;<a rel="noopener noreferrer" href={this.state.front_left_full} target="_blank" onClick={(e)=>this.saveCarImage(e,this.state.front_left_full)} className={this.state.front_left_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span>
          </a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.front_left_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('front_left')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper} >
            <button style={styles.btnFileInput}>
              <img src={this.state.front_left}
                   style={styles.btnFileInputImage}
                   ref="img_front_left"
                   alt="มุมซ้าย"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="front_left"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">4) หลังตรง
            &nbsp;<a rel="noopener noreferrer" href={this.state.side_car_left_full} target="_blank" onClick={(e)=>this.saveCarImage(e,this.state.side_car_left_full)} className={this.state.side_car_left_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span></a>
            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.side_car_left_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('side_car_left')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper} >
            <button style={styles.btnFileInput}>
              <img src={this.state.side_car_left}
                   style={styles.btnFileInputImage}
                   ref="img_side_car_left"
                   alt="ด้านซ้าย"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="side_car_left"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">5) หลังซ้าย
            &nbsp;<a rel="noopener noreferrer" href={this.state.side_car_right_full} target="_blank" onClick={(e)=>this.saveCarImage(e,this.state.side_car_right_full)} className={this.state.side_car_right_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span></a>
            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.side_car_right_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('side_car_right')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper} >
            <button style={styles.btnFileInput}>
              <img src={this.state.side_car_right}
                   style={styles.btnFileInputImage}
                   ref="img_side_car_right"
                   alt="ด้านขวา"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="side_car_right"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">6) หลังขวา
            &nbsp;<a rel="noopener noreferrer" href={this.state.back_straight_full} target="_blank" onClick={(e)=>this.saveCarImage(e,this.state.back_straight_full)} className={this.state.back_straight_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}><span>download</span></a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.back_straight_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('back_straight')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper} >
            <button style={styles.btnFileInput}>
              <img src={this.state.back_straight}
                   style={styles.btnFileInputImage}
                   ref="img_back_straight"
                   alt="มุมตรง"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="back_straight"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">7) ซ้ายตรง
            &nbsp;<a rel="noopener noreferrer" href={this.state.console_car_full} target="_blank" onClick={(e)=>this.saveCarImage(e,this.state.console_car_full)} className={this.state.console_car_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}><span>download</span></a>
            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.console_car_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('console_car')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper} >
            <button style={styles.btnFileInput}>
              <img src={this.state.console_car}
                   style={styles.btnFileInputImage}
                   ref="img_console_car"
                   alt="คอนโซลรถ"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="console_car"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">8) ขวาตรง
            &nbsp;<a rel="noopener noreferrer" href={this.state.condition_in_car_full} target="_blank" onClick={(e)=>this.saveCarImage(e,this.state.condition_in_car_full)} className={this.state.condition_in_car_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}><span>download</span></a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.condition_in_car_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('condition_in_car')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper} >
            <button style={styles.btnFileInput}>
              <img src={this.state.condition_in_car}
                   style={styles.btnFileInputImage}
                   ref="img_condition_in_car"
                   alt="สภาพภายในรถ"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="condition_in_car"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">9) เครื่องยนต์
            &nbsp;<a rel="noopener noreferrer" href={this.state.engine_car_full} target="_blank" onClick={(e)=>this.saveCarImage(e,this.state.engine_car_full)} className={this.state.engine_car_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}><span>download</span></a>
            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.engine_car_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('engine_car')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper} >
            <button style={styles.btnFileInput}>
              <img src={this.state.engine_car}
                   style={styles.btnFileInputImage}
                   ref="img_engine_car"
                   alt="เครื่องยนต์"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="engine_car"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">10) เบาะหน้าขวา
            &nbsp;<a rel="noopener noreferrer" href={this.state.plate_car_full} target="_blank" onClick={(e)=>this.saveCarImage(e,this.state.plate_car_full)} className={this.state.plate_car_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}><span>download</span></a>
            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.plate_car_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('plate_car')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper} >
            <button style={styles.btnFileInput}>
              <img src={this.state.plate_car}
                   style={styles.btnFileInputImage}
                   ref="img_plate_car"
                   alt="เพลทรถยนต์"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="plate_car"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">11) เบาะหลังขวา
            &nbsp;<a rel="noopener noreferrer" href={this.state.other_car_full} target="_blank" onClick={(e)=>this.saveCarImage(e,this.state.other_car_full)} className={this.state.other_car_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}><span>download</span></a>
            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.other_car_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('other_car')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper} >
            <button style={styles.btnFileInput}>
              <img src={this.state.other_car}
                   style={styles.btnFileInputImage}
                   ref="img_other_car"
                   alt="ภาพอื่นๆ"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="other_car"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">12) คอนโซล
            &nbsp;
            <a rel="noopener noreferrer"
               href={this.state.console_full}
               target="_blank"
               onClick={(e)=>this.saveCarImage(e,this.state.console_full)}
               className={this.state.console_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                 <span>download</span>
            </a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.console_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('console')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper} >
            <button style={styles.btnFileInput}>
              <img src={this.state.console}
                   style={styles.btnFileInputImage}
                   ref="img_console"
                   alt="ภาพอื่นๆ"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file"
              name="console"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">13) เกียร์
            &nbsp;<a rel="noopener noreferrer" href={this.state.gear_full} target="_blank" onClick={(e)=>this.saveCarImage(e,this.state.gear_full)} className={this.state.gear_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}><span>download</span></a>
            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.gear_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('gear')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper}>
            <button style={styles.btnFileInput}>
              <img src={this.state.gear}
                   style={styles.btnFileInputImage}
                   ref="img_gear"
                   alt="เกียร์"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="gear"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">14) เครื่องเสียง
            &nbsp;<a rel="noopener noreferrer" href={this.state.audio_equipment_full} target="_blank" onClick={(e)=>this.saveCarImage(e,this.state.audio_equipment_full)} className={this.state.audio_equipment_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}><span>download</span></a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.audio_equipment_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('audio_equipment')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper}>
            <button style={styles.btnFileInput}>
              <img src={this.state.audio_equipment}
                   style={styles.btnFileInputImage}
                   ref="img_audio_equipment"
                   alt="เครื่องเสียง"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="audio_equipment"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">15) หน้าปัด
            &nbsp;
            <a rel="noopener noreferrer" href={this.state.dial_full}
               target="_blank"
               onClick={(e)=>this.saveCarImage(e,this.state.dial_full)}
               className={this.state.dial_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span>
            </a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.dial_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('dial')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper}>
            <button style={styles.btnFileInput}>
              <img src={this.state.dial}
                   style={styles.btnFileInputImage}
                   ref="img_dial"
                   alt="หน้าปัด"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="dial"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">16) ท่อแคท
            &nbsp;
            <a rel="noopener noreferrer" href={this.state.catalyticconverter_full}
               target="_blank"
               onClick={(e)=>this.saveCarImage(e,this.state.catalyticconverter_full)}
               className={this.state.catalyticconverter_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span>
            </a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.catalyticconverter_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('catalyticconverter')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper}>
            <button style={styles.btnFileInput}>
              <img src={this.state.catalyticconverter}
                   style={styles.btnFileInputImage}
                   ref="img_catalyticconverter"
                   alt="ท่อแคท"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="catalyticconverter"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">17) ปียางรถ
            &nbsp;
            <a rel="noopener noreferrer" href={this.state.yearoftire_full}
               target="_blank"
               onClick={(e)=>this.saveCarImage(e,this.state.yearoftire_full)}
               className={this.state.yearoftire_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span>
            </a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.yearoftire_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('yearoftire')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper}>
            <button style={styles.btnFileInput}>
              <img src={this.state.yearoftire}
                   style={styles.btnFileInputImage}
                   ref="img_yearoftire"
                   alt="ท่อแคท"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="yearoftire"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">18) คัทซี
            &nbsp;
            <a rel="noopener noreferrer" href={this.state.chassis_full}
               target="_blank"
               onClick={(e)=>this.saveCarImage(e,this.state.chassis_full)}
               className={this.state.chassis_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span>
            </a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.chassis_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('chassis')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper}>
            <button style={styles.btnFileInput}>
              <img src={this.state.chassis}
                   style={styles.btnFileInputImage}
                   ref="img_chassis"
                   alt="คัทซี"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="chassis"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">19) อื่นๆ 1
            &nbsp;
            <a rel="noopener noreferrer" href={this.state.other1_full}
               target="_blank"
               onClick={(e)=>this.saveCarImage(e,this.state.other1_full)}
               className={this.state.other1_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span>
            </a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.other1_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('other1')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper}>
            <button style={styles.btnFileInput}>
              <img src={this.state.other1}
                   style={styles.btnFileInputImage}
                   ref="img_other1"
                   alt="อื่นๆ 1"
              />
            </button>
            <input
                style={styles.fileInput}
                type="file" name="other1"
                onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">20) อื่นๆ 2
            &nbsp;
            <a rel="noopener noreferrer" href={this.state.other2_full}
               target="_blank"
               onClick={(e)=>this.saveCarImage(e,this.state.other2_full)}
               className={this.state.other2_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span>
            </a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.other2_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('other2')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper}>
            <button style={styles.btnFileInput}>
              <img src={this.state.other2}
                   style={styles.btnFileInputImage}
                   ref="img_other2"
                   alt="อื่นๆ 2"
              />
            </button>
            <input
                style={styles.fileInput}
                type="file" name="other2"
                onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">21) อื่นๆ 3
            &nbsp;
            <a rel="noopener noreferrer" href={this.state.other3_full}
               target="_blank"
               onClick={(e)=>this.saveCarImage(e,this.state.other3_full)}
               className={this.state.other3_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span>
            </a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.other3_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('other3')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper}>
            <button style={styles.btnFileInput}>
              <img src={this.state.other3}
                   style={styles.btnFileInputImage}
                   ref="img_other3"
                   alt="อื่นๆ 3"
              />
            </button>
            <input
                style={styles.fileInput}
                type="file" name="other3"
                onChange={this.handleUploadFile}
            />
          </div>
        </div>

      </div>
            </div>
          </div>
        </div>


        {/!*<div className={PORT === '9006' || PORT === '9000' ? "col-lg-12" : "hidden" } >*!/}

        <div className="col-lg-12" >
          <div className="card">
            <div className="card-header">
              <strong>รูปรถ(ก่อนแต่ง)</strong>
            </div>

            <div className="card-block">
              <div className="row">
                <div className="form-group col-sm-4">
                  <label htmlFor="name">1) หน้ารถ หน้าตรง (ก่อนแต่ง)
                    &nbsp;&nbsp;
                    <button type="button"
                            className={this.state.front_car2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('front_car2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper}  >
                    <button style={styles.btnFileInput}>
                      <img src={this.state.front_car2}
                           style={styles.btnFileInputImage}
                           ref="img_front_car2"
                           alt="หน้าตรง"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file" name="front_car2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

                <div className="form-group col-sm-4">

                  <label htmlFor="name">2) หน้ารถ มุมขวา (ก่อนแต่ง)
                    &nbsp;&nbsp;
                    <button type="button"
                            className={this.state.front_right2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('front_right2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper} >
                    <button style={styles.btnFileInput}>
                      <img src={this.state.front_right2}
                           style={styles.btnFileInputImage}
                           ref="img_front_right2"
                           alt="มุมขวา"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file" name="front_right2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>

                </div>

                <div className="form-group col-sm-4">
                  <label htmlFor="name">3) หน้ารถ มุมซ้าย (ก่อนแต่ง)
                    &nbsp;&nbsp;
                    <button type="button"
                            className={this.state.front_left2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('front_left2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper} >
                    <button style={styles.btnFileInput}>
                      <img src={this.state.front_left2}
                           style={styles.btnFileInputImage}
                           ref="img_front_left2"
                           alt="มุมซ้าย"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file" name="front_left2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

                <div className="form-group col-sm-4">
                  <label htmlFor="name">4) ข้างรถ ด้านซ้าย (ก่อนแต่ง)
                    &nbsp;&nbsp;
                    <button type="button"
                            className={this.state.side_car_left2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('side_car_left2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper} >
                    <button style={styles.btnFileInput}>
                      <img src={this.state.side_car_left2}
                           style={styles.btnFileInputImage}
                           ref="img_side_car_left2"
                           alt="ด้านซ้าย"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file" name="side_car_left2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

                <div className="form-group col-sm-4">
                  <label htmlFor="name">5) ข้างรถ ด้านขวา (ก่อนแต่ง)
                    &nbsp;&nbsp;
                    <button type="button"
                            className={this.state.side_car_right2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('side_car_right2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper} >
                    <button style={styles.btnFileInput}>
                      <img src={this.state.side_car_right2}
                           style={styles.btnFileInputImage}
                           ref="img_side_car_right2"
                           alt="ด้านขวา"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file" name="side_car_right2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

                <div className="form-group col-sm-4">
                  <label htmlFor="name">6) หลังรถ มุมตรง (ก่อนแต่ง)
                    &nbsp;&nbsp;
                    <button type="button"
                            className={this.state.back_straight2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('back_straight2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper} >
                    <button style={styles.btnFileInput}>
                      <img src={this.state.back_straight2}
                           style={styles.btnFileInputImage}
                           ref="img_back_straight2"
                           alt="มุมตรง"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file" name="back_straight2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

                <div className="form-group col-sm-4">
                  <label htmlFor="name">7) คอนโซลรถ (ก่อนแต่ง)
                    &nbsp;&nbsp;
                    <button type="button"
                            className={this.state.console_car2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('console_car2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper} >
                    <button style={styles.btnFileInput}>
                      <img src={this.state.console_car2}
                           style={styles.btnFileInputImage}
                           ref="img_console_car2"
                           alt="คอนโซลรถ"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file" name="console_car2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

                <div className="form-group col-sm-4">
                  <label htmlFor="name">8) สภาพภายในรถ (ก่อนแต่ง)
                    &nbsp;&nbsp;
                    <button type="button"
                            className={this.state.condition_in_car2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('condition_in_car2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper} >
                    <button style={styles.btnFileInput}>
                      <img src={this.state.condition_in_car2}
                           style={styles.btnFileInputImage}
                           ref="img_condition_in_car2"
                           alt="สภาพภายในรถ"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file" name="condition_in_car2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

                <div className="form-group col-sm-4">
                  <label htmlFor="name">9) เครื่องยนต์ (ก่อนแต่ง)
                    &nbsp;&nbsp;
                    <button type="button"
                            className={this.state.engine_car2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('engine_car2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper} >
                    <button style={styles.btnFileInput}>
                      <img src={this.state.engine_car2}
                           style={styles.btnFileInputImage}
                           ref="img_engine_car2"
                           alt="เครื่องยนต์"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file" name="engine_car2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

                <div className="form-group col-sm-4">
                  <label htmlFor="name">10) เพลทรถยนต์ (ก่อนแต่ง)
                    &nbsp;&nbsp;
                    <button type="button"
                            className={this.state.plate_car2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('plate_car2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper} >
                    <button style={styles.btnFileInput}>
                      <img src={this.state.plate_car2}
                           style={styles.btnFileInputImage}
                           ref="img_plate_car2"
                           alt="เพลทรถยนต์"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file" name="plate_car2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

                <div className="form-group col-sm-4">
                  <label htmlFor="name">11) ภาพอื่นๆ (ก่อนแต่ง)
                    &nbsp;&nbsp;
                    <button type="button"
                            className={this.state.other_car2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('other_car2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper} >
                    <button style={styles.btnFileInput}>
                      <img src={this.state.other_car2}
                           style={styles.btnFileInputImage}
                           ref="img_other_car2"
                           alt="ภาพอื่นๆ"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file" name="other_car2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }*/
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarAlbumList);

const styles = {
  carWrapper: {
    position: 'relative',
    overflow: 'hidden',
    height: 165,
    width: 260,
  },

  carImage:{
    position: 'absolute',
    top: 0,
    left: 0,
    height: 165,
    width: 260,
  },
}
