import React, { Component } from 'react';
import {APIURL, CLOUDFRONT_URL, IMAGE_FULL_URL, IMAGE_NOPHOTO_URL, PORT} from '../../../config/config'
import * as moment from 'moment';
import 'moment/locale/th';
import PreviewImage from '../../../views/PreviewImage/';
import NumberFormat from 'react-number-format';
import {  Modal, ModalBody } from 'reactstrap';
import { checkGroupPermission } from '../../../services/grouppermission';
import ReactToPrint, {PrintContextConsumer} from "react-to-print";
import axios from "axios";
import { AlertError } from '../../Alert/Alert'
import {Link, Redirect} from "react-router-dom";


const port = Number(window.env.PORT);

class CarRepairListItem extends Component{
  constructor (props){
    super(props)

    this.state = {
        preview_image_url: '',
        preview_image_select: false,
        lightboxState: true,
        preview_ligthbox: false,
        repair_types: [],
        repair_type_id: '',
        car_repair_id : this.props.lists
    }
    this.onCarRepairDelete  = this.onCarRepairDelete.bind(this)
    this.togglePreviewImage = this.togglePreviewImage.bind(this)

    this.onPreviewLightbox = this.onPreviewLightbox.bind(this)
    this.handlePrintImage = this.handlePrintImage.bind(this)
    this.loadRepairType = this.loadRepairType.bind(this)
  }

    componentDidMount() {
      this.loadRepairType()
  }

    onCarRepairDelete(car_repair){
    // console.log(car_repair)
    // return
    this.props.onCarRepairDelete(car_repair)
  }


  loadRepairType() {
      axios.get(APIURL + '/repair_type')
          .then(res => {
              this.setState({repair_types: res.data })
          }).catch(error => {
          // alert("error"+error)
          this.setState({repair_types: []})
      })
  }


  onPreviewSelected(url){
    this.setState({
      preview_image_url: url,
      preview_image_select: true
    });
  }

  togglePreviewImage() {
    this.setState({
      preview_image_select: !this.state.preview_image_select
    });
  }

    onPreviewLightbox(url,e){
        // console.log(url)
        let docId = e.target.name
        this.setState({
            preview_image_url: url,
            preview_ligthbox: true
        }, () => {
        });

    }

    handlePrintImage(url, handlePrint) {
        this.setState({
            preview_image_url: url
        },() => handlePrint());
    }

  render(){

    let user_type = this.props.user_type
    // console.log('user_type=', user_type)
    let sell_status_check = this.props.sell_status_check
    let sum_repair_cost = this.props.lists.length === 0 ? 0 : this.props.lists.reduce((acc, repair) => acc + repair.car_repair_cost, 0)
    let sum_repair_vat = this.props.lists.length === 0 ? 0 : this.props.lists.reduce((acc, repair) => acc + repair.car_repair_vat, 0)

    let car_repair_list = this.props.lists.map((car_repair,index)=>{
      let image_url = ''
      if( car_repair.car_repair_file === 'nophoto.png') {
        image_url = IMAGE_NOPHOTO_URL
      } else {
        // check https
        if (car_repair.car_repair_file !== null) {
          if (car_repair.car_repair_file.includes('https')) {
            // image_url = car_repair.car_repair_file
            // console.log('car_repair_key=',)
            image_url = CLOUDFRONT_URL+car_repair.car_repair_key
          }else{
            image_url = IMAGE_FULL_URL + car_repair.car_repair_file
          }
        }else{
          image_url = IMAGE_NOPHOTO_URL
        }
      }

      let image_url2 = ''
      if( car_repair.car_repair_file2 === 'nophoto.png' || car_repair.car_repair_file2 === null) {
        image_url2 = IMAGE_NOPHOTO_URL
      } else {
        // check https
        if (car_repair.car_repair_file2 !== null) {
          if (car_repair.car_repair_file2.includes('https')) {
            // image_url2 = car_repair.car_repair_file2
            image_url2 = CLOUDFRONT_URL+car_repair.car_repair_key2
          }else{
            image_url2 = IMAGE_FULL_URL + car_repair.car_repair_file2
          }
        }else{
          image_url2 = IMAGE_NOPHOTO_URL
        }
      }

      let spare_part_url = car_repair.spare_part_url
      // console.log('spare_part_url=', spare_part_url)
        if(image_url === IMAGE_NOPHOTO_URL && spare_part_url !== null) {
            image_url = car_repair.spare_part_url
        }

        let is_after_sell= Number(car_repair.is_after_sell)
        let is_after_sell_text = Number(car_repair.is_after_sell) === 0 ? 'ซ่อมก่อนขาย' : 'ซ่อมหลังขาย'

        let cost_with_vat = Number(car_repair.car_repair_cost)+Number(car_repair.car_repair_vat)

      return(
        <tr key={index} >
          <td className="text-center">{index+1}</td>
          <td className="text-left">
             รายการซ่อม: {car_repair.car_repair_name} <br/>
             ประเภท: {car_repair.repair_type_name}
            <br/>
            <span className="badge badge-danger" style={{backgroundColor: car_repair.repair_type_color}}>
              {car_repair.repair_type_name}
            </span>



              {car_repair.car_repair_note !== null && car_repair.car_repair_note !== '' ?
                  (
                     <> <br/><font color='red'> หมายเหตุ : {car_repair.car_repair_note} </font> </>
                  )
                  : null }
              <br/>
              {
                  car_repair.center_name === 'เบิกอะไหล่' ?
                      (
                        <> <br/>  <font color='red'> *** หมายเหตุเบิกอะไหล่ : {car_repair.out_note === '' ? '-' : car_repair.out_note} , *ผู้เบิก : {car_repair.requestor} </font> </>
                      ): null
              }

          </td>
          <td className="text-center text-nowrap">
            &nbsp;&nbsp;ส่งซ่อม : {moment(car_repair.car_repair_send).locale('th').format('LL')} <br/>
            ซ่อมเสร็จ : {moment(car_repair.car_repair_complete).locale('th').format('LL')} <br/>
            &nbsp;&nbsp;&nbsp;อู่ &nbsp;: {car_repair.center_name}
              <br/>
              <span className={is_after_sell === 0 ? "badge badge-danger" : "badge badge-primary" }>
                  {is_after_sell_text}
              </span>
          </td>

          <td className={port === 9040 && this.props.user_type !== 'admin'? 'hidden' : "text-center"}>
            <div className="row">
              <div className="col-12">เอกสาร 1</div>
            </div>

            <div className="row">
              <div className="col-12">
                <img
                  src={image_url}
                  style={{'cursor' : 'pointer', 'width': '120px', 'height': 'auto'}}
                  onClick={()=>this.onPreviewSelected(image_url)}
                  alt="เอกสาร"
                />
              </div>
            </div>

              {
                  image_url2 === IMAGE_NOPHOTO_URL ? null : <>
                      <br/>
                      <div className="row text-center">
                          <div className="col-12">
                              เอกสาร 2
                          </div>
                      </div>
                      <div className="row text-center">
                          <div className="col-12">
                              <img
                                  src={image_url2}
                                  style={{'cursor' : 'pointer', 'width': '120px', 'height': 'auto'}}
                                  onClick={()=>this.onPreviewSelected(image_url2)}
                                  alt="เอกสาร"
                              />
                          </div>
                      </div>
                  </>
              }

          </td>

          <td className={port === 9040 && this.props.user_type !== 'admin'? 'hidden' : "text-center"}>
             ค่าซ่อม : <NumberFormat value={car_repair.car_repair_cost} displayType={'text'} thousandSeparator={true} prefix={'฿'} />
             <br/>
             VAT: <NumberFormat value={car_repair.car_repair_vat} displayType={'text'} thousandSeparator={true} prefix={'฿'} />
              <hr/>
             ยอดรวม VAT: <NumberFormat value={cost_with_vat} displayType={'text'} thousandSeparator={true} prefix={'฿'} />
          </td>

          <td className="text-center text-nowrap">
            {car_repair.fullname}
            <br/>
            {moment(car_repair.updated_at).locale('th').format('DD-MM-YYYY HH:mm')}

            <br/>
              <span className="badge badge-danger">
                {car_repair.car_repair_type}
              </span>

            <br/><br/>
            {
              car_repair.center_name === 'เบิกอะไหล่' ? (<font color='red'> *** เบิกอะไหล่ลบได้ที่เมนูข้อมูลอะไหล่ </font>): null
            }
          </td>
          <td className="text-center">

            {
              checkGroupPermission(3, this.props.grouppermissions).modified ? (
                <>
                  <button className="btn btn-sm btn-warning btn-md-width" style={{width: 120}}
                          onClick={()=>{
                            if (sell_status_check) {
                              AlertError('ไม่สามารถแก้ไขค่าซ่อมได้ เนื่องจากปิดการขายแล้ว');
                              return
                            }
                            this.props.onCarRepairEditSelected(car_repair)
                          }}
                  >
                    <i className="fa fa-pencil"/> แก้ไข
                  </button>
                  <br/>
                </>
              ) : null
            }

            {
              checkGroupPermission(3, this.props.grouppermissions).approved ? (
                <>
                  <button className="btn btn-sm btn-success btn-md-width  mt-2" style={{width: 120}}
                          onClick={()=>this.props.onCarRepairUploadFile(car_repair)}
                  >
                    <i className="fa fa-file"/> แนบไฟล์
                  </button>
                  <br/>
                </>
              ) : null
            }

            {
              checkGroupPermission(3, this.props.grouppermissions).deleted ? (
                <>
                    <button className="btn btn-sm btn-danger btn-md-width mt-2" style={{width: 120}}
                            onClick={()=> {
                                if (sell_status_check) {
                                    AlertError('ไม่สามารถลบค่าซ่อมได้ เนื่องจากปิดการขายแล้ว');
                                    return
                                }
                                this.onCarRepairDelete(car_repair)
                            }}
                            disabled={car_repair.car_repair_type === 'เบิกอะไหล่'}
                    >
                        <i className="fa fa-remove"/> ลบ
                    </button>
                    <br/>
                </>
              ): null
            }



              {
                  checkGroupPermission(3, this.props.grouppermissions).printed ? (
                      <>
                          <ReactToPrint
                              content={() => this.componentRef}
                          >
                              <PrintContextConsumer>
                                  {({ handlePrint }) => (
                                      <button className={port === 9040 && this.props.user_type !== 'admin'? 'hidden' : "btn btn-sm btn-primary btn-md-width mt-2" }
                                              style={{width: 120}} onClick={()=> this.handlePrintImage(image_url, handlePrint)}>
                                          <i className="fa fa-print"/> พิมพ์
                                      </button>
                                  )}
                              </PrintContextConsumer>
                          </ReactToPrint>
                          <br/>
                      </>
                  ) : null

              }


              {
                  checkGroupPermission(3, this.props.grouppermissions).created ? (
                      <button className="btn btn-sm btn-primary btn-md-width mt-2"
                              style={{width: 120}}
                      >

                          <Link to={'/payment-voucher/?car_repair_id=' +car_repair.car_repair_id}
                                target='_blank' style={{color: 'white'}}> ใบสำคัญจ่าย
                          </Link>
                      </button>
                  ) : null
              }

              {
                  checkGroupPermission(3, this.props.grouppermissions).modified === 0
                  && checkGroupPermission(3, this.props.grouppermissions).deleted === 0
                  && checkGroupPermission(3, this.props.grouppermissions).printed === 0
                      ? <i className="icon-close"/> : null
              }

          </td>
        </tr>
      )
    })

    let repair_type_list = this.state.repair_types.map((repair_type, index) => {
        return( <option key={index} value={repair_type.repair_type_id}>{repair_type.repair_type_name}</option> )
    })

    return(
      <div>

          <div style={{ display: "none" }}>
              <PreviewImage
                  imageUrl={this.state.preview_image_url} ref={el => (this.componentRef = el)}
              />
          </div>

          <div className="row">
              <div className="col-6">
                  <div className={PORT === 9021 && user_type === 'sale'?'hidden': "form-inline mb-1" }>
                      <label htmlFor="center_id">ประเภทการซ่อม</label>
                      <select
                          className="form-control ml-2"
                          name="repair_type_id"
                          value={this.state.repair_type_id}
                          onChange={(e) => {
                              let value = e.target.value
                              // console.log(value, typeof value)
                              this.setState({
                                  repair_type_id: value
                              }, () => {
                                  this.props.onSearchByRepairType(value)
                              })
                          }}
                      >
                          <option value="">ทั้งหมด</option>
                          {repair_type_list}
                      </select>
                  </div>
              </div>
              <div className="col-6">
                {
                    sum_repair_cost === 0 ? null : (
                    <>
                      <h5 className={port === 9040 && this.props.user_type !== 'admin'? 'hidden' : "text-right"}>
                      รวมค่าซ่อม &nbsp;&nbsp;
                        <NumberFormat
                          value={sum_repair_cost.toFixed(2)}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'฿'}
                        />
                          &nbsp;&nbsp;
                          <br/>
                         VAT &nbsp;&nbsp;
                          <NumberFormat
                              value={sum_repair_vat.toFixed(2)}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'฿'}
                          />
                         &nbsp;&nbsp;
                      </h5>
                    </>
                  )
                }
              </div>

          </div>

        <table className={(this.props.lists.length>0)?'table table-striped table-responsive ' : 'table table-striped hidden'}>
          <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-center" style={{width: '35%'}}>ชื่อรายการ</th>
            <th className="text-center text-nowrap">วันที่ส่งซ่อม/ซ่อมเสร็จ</th>
            <th className={port === 9040 && this.props.user_type !== 'admin'? 'hidden' : "text-center"}>เอกสารแนบ</th>

            <th className={port === 9040 && this.props.user_type !== 'admin'? 'hidden' : "text-center"}>ราคา</th>
            <th className="text-center text-nowrap">ผู้ทำรายการ</th>
            <th className={port === 9040 && this.props.user_type !== 'admin'? 'hidden' : "text-center"}>จัดการ</th>
          </tr>
          </thead>
          <tbody>
          {car_repair_list}
          </tbody>
        </table>

        <div className={this.props.lists.length ===0 ?' text-center  ' : 'hidden'} >
          <strong className="text-danger">ไม่พบรายการซ่อม</strong>
        </div>

        <Modal isOpen={this.state.preview_image_select} toggle={this.togglePreviewImage} className={'modal-lg '}>
          <ModalBody>
            <PreviewImage
              imageUrl={this.state.preview_image_url}
            />
          </ModalBody>
        </Modal>

      </div>
    )
  }
}

export default CarRepairListItem
