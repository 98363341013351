import {PORT} from "../../config/config";


export const CustomerFollowupUtils = {
  getFollowupStatusName(customer_type) {
    let followupStatusName = ''
    if(PORT === 9068){
      switch (customer_type) {
        case 'lead':
          followupStatusName = 'เคสติดตาม';
          break;
        case 'cancel':
          followupStatusName = 'เคสไม่ติดตาม';
          break;
        case 'deposit':
          followupStatusName = 'นัด-ฝาก';
          break;
        case 'waitprocess':
          followupStatusName = 'รอดำเนินการ';
          break;
        case 'reject':
          followupStatusName = 'ลูกค้ายกเลิก';
          break;
        case 'notcreditapproved':
          followupStatusName = 'ลูกค้าเครดิตไม่ผ่าน';
          break;
        case 'notbankcriteria':
          followupStatusName = 'ลูกค้าไม่ผ่านเกณฑ์ธนาคาร';
          break;
        case 'approved':
          followupStatusName = 'อนุมัติ';
          break;
        case 'complete':
          followupStatusName = 'ปิดการขาย';
          break;
        default:
          followupStatusName = ''
      }
    }else {
      switch (customer_type) {
        case 'lead':
          followupStatusName = 'ติดตามลูกค้า';
          break;
        case 'reject':
          followupStatusName = 'ลูกค้ายกเลิก';
          break;
        case 'cancel':
          followupStatusName = 'ยกเลิกไม่ติดตาม';
          break;
        case 'complete':
          followupStatusName = 'ปิดการขายได้';
          break;
        default:
          followupStatusName = ''
      }
    }

    return followupStatusName
  },
  getCustomerTypes() {
    let customer_types = []
    if(PORT === 9000){
      customer_types = [
        {customer_type: 'lead', customer_label: 'เคสติดตาม'},
        {customer_type: 'cancel', customer_label: 'เคสไม่ติดตาม'},
        {customer_type: 'deposit', customer_label: 'นัด-ฝาก'},
        {customer_type: 'waitprocess', customer_label: 'รอดำเนินการ'},
        {customer_type: 'reject', customer_label: 'ลูกค้ายกเลิก'},
        {customer_type: 'notcreditapproved', customer_label: 'ลูกค้าเครดิตไม่ผ่าน'},
        {customer_type: 'notbankcriteria', customer_label: 'ลูกค้าไม่ผ่านเกณฑ์ธนาคาร'},
        {customer_type: 'approved', customer_label: 'อนุมัติ'},
        {customer_type: 'complete', customer_label: 'ปิดการขาย'},
      ]
    }else {
      customer_types = [
        {customer_type: 'lead', customer_label: 'ติดตามลูกค้า'},
        {customer_type: 'reject', customer_label: 'ลูกค้ายกเลิก'},
        {customer_type: 'cancel', customer_label: 'ยกเลิกไม่ติดตาม'},
        {customer_type: 'complete', customer_label: 'ปิดการขายได้'},
      ]
    }

    return customer_types
  },
  getClassBadge(customer_type) {
    let followupClassName = ''
    if(PORT === 9000){
      switch (customer_type) {
        case 'lead':
          followupClassName = 'badge badge-primary float-right';
          break;
        case 'reject':
          followupClassName = "badge badge-danger float-right";
          break;
        case 'cancel':
          followupClassName = "badge badge-warning float-right";
          break;
        case 'complete':
          followupClassName = 'badge badge-success float-right'
          break;
        case 'deposit':
          followupClassName = 'badge badge-secondary float-right'
          break;
        case 'waitprocess':
          followupClassName = 'badge badge-info float-right'
          break;
        case 'notcreditapproved':
          followupClassName = 'badge badge-warning float-right'
          break;
        case 'notbankcriteria':
          followupClassName = 'badge badge-warning float-right'
          break;
        case 'approved':
          followupClassName = 'badge badge-success float-right'
          break;
        default:
          followupClassName = 'badge badge-info float-right'
      }
    }else {
      switch (customer_type) {
        case 'lead':
          followupClassName = 'badge badge-primary float-right';
          break;
        case 'reject':
          followupClassName = "badge badge-danger float-right";
          break;
        case 'cancel':
          followupClassName = "badge badge-warning float-right";
          break;
        case 'complete':
          followupClassName = 'badge badge-success float-right'
          break;
        default:
          followupClassName = 'badge badge-info float-right'
      }
    }

    return followupClassName
  }
}
