import React, { Component } from 'react'
import { APIURL } from '../../config/config'
import axios from 'axios'
import { AlertSuccess } from '../Alert/Alert'
import Switch from 'react-switch'
import Swal from 'sweetalert2'
import {connect} from "react-redux";

class InsuranceCompanySetting extends Component{
  constructor (props) {
    super(props);

    this.state = {
      actionType: "บันทึก",
      insurance_company_list: [],
      insurance_company_id: '',
      insurance_company_name: '',
      user: {} ,
    }

    this.loadInsuranceCompanyData = this.loadInsuranceCompanyData.bind(this);
    this.saveInsuranceCompany = this.saveInsuranceCompany.bind(this);
    this.setEditForm = this.setEditForm.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.onChangeSwitch = this.onChangeSwitch.bind(this);
    this.deleteInsuranceCompany = this.deleteInsuranceCompany.bind(this);
  }

  loadInsuranceCompanyData () {
    let url = APIURL+'/insurance_company'
    axios.get(url).then(res => {
      this.setState({
        insurance_company_list: res.data
      })
    })
  }

  deleteInsuranceCompany(insurance_company){
    let insurance_company_id = insurance_company.insurance_company_id
    let insurance_company_name = insurance_company.insurance_company_name

    let data = {
      insurance_company_id: insurance_company_id,
      status_field: 'delete'
    }

    Swal.fire({
      title: 'ยืนยันการลบ '+insurance_company_name+' ?',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        let url = APIURL+'/insurance_company/status'
        axios.post(url, data).then(res => {
          if(res.data === 'success') {
            AlertSuccess('ลบข้อมูลแล้ว')
            this.loadInsuranceCompanyData()
          }
        })
      }
    });

  }

  saveInsuranceCompany() {
    //let username = this.state.user.user
    let actionType = this.state.actionType
    let insurance_company_name = this.state.insurance_company_name

    let data= {
      insurance_company_name,
      user_created: this.state.user.user,
      user_updated: this.state.user.user
    }

    if(actionType === "บันทึก") {
      let url = APIURL+'/insurance_company'
      axios.post(url, data).then(res => {
        if(res.data === 'success') {
          AlertSuccess('บันทึกข้อมูลแล้ว')
          this.clearForm()
          this.loadInsuranceCompanyData()
        }
      })
    } else if (actionType === "แก้ไข") {
      let insurance_company_id = this.state.insurance_company_id
      data = {...data, insurance_company_id: insurance_company_id }
      let url = APIURL+'/insurance_company/'+insurance_company_id
      axios.put(url, data).then(res => {
        if(res.data === 'success') {
          AlertSuccess('แก้ไขข้อมูลแล้ว')
          this.clearForm()
          this.loadInsuranceCompanyData()
        }
      })
    }
  }

  setEditForm(insurance_company) {
    this.setState({
      actionType: 'แก้ไข',
      insurance_company_name: insurance_company.insurance_company_name,
      insurance_company_id: insurance_company.insurance_company_id,
    })
  }

  clearForm() {
    this.setState({
      actionType: "บันทึก",
      insurance_company_name: '',
      insurance_company_id: '',
    })
  }

  onChangeSwitch(checked, insurance_company){
    //let username = this.props.username
    let item_update = insurance_company
    let insurance_companys = this.state.insurance_company_list
    let item_index = this.state.insurance_company_list.findIndex(data => data.insurance_company_id=== insurance_company.insurance_company_id)

    if(checked === false){
      item_update.status_field = 'hidden'
    } else {
      item_update.status_field = 'show'
    }

    let data = {
      insurance_company_id: item_update.insurance_company_id,
      status_field: item_update.status_field
      //username: username
    }

    let url = APIURL+'/insurance_company/status'
      axios.post(url, data).then(res => {
    })

    insurance_companys[item_index] = item_update
    this.setState({
      insurance_company_list: insurance_companys
    })
  }

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user)
    });
  }

  componentDidMount () {
    this.loadInsuranceCompanyData()
  }

  render () {

    return (
      <div className="row">
        <div className="col-sm-7">
          <div className="card card-accent-info">
            <div className="card-header">
              <strong className="text-title">
                บริษัทประกันภัย
              </strong>
            </div>
            <div className="mr-5 mt-3 text-right"><span className="text-danger">กดปิดการใช้งาน หากไม่ต้องการแสดงรายการ</span></div>

            <div className="card-block" style={styles.overflowY}>
              <table className="table table-striped table-responsive">
                <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th className="text-center text-nowrap">ชื่อบริษัทประกันภัย</th>
                  <th className="text-center">ใช้งาน</th>
                  <th className="text-center">จัดการ</th>
                </tr>
                </thead>
                <tbody>
                {
                  this.state.insurance_company_list.map((insurance_company, index) => (
                    <tr key={index}>
                      <td className="text-center" style={{width: 10}}>{ index+1 }</td>
                      <td className="text-center text-nowrap" style={{width: 80}}>{ insurance_company.insurance_company_name }</td>
                      <td className="text-nowrap text-center" style={{width: 80}}>

                        { insurance_company.status_field === 'show' ? 'ใช้งาน' : 'ไม่ใช้งาน' }

                        <br/>

                        <Switch height = {20} width ={48}
                                onChange={(checked) => this.onChangeSwitch(checked, insurance_company)}
                                checked={insurance_company.status_field === 'show'}
                        />

                      </td>
                      <td className="text-center" style={{width: 120}}>
                        <button type="button"
                                className="btn btn-sm btn-warning btn-md-width mr-2 mb-2"
                                onClick={() => this.setEditForm(insurance_company)}
                        >
                          <i className="fa fa-edit"/> แก้ไข
                        </button>
                              {/*<button type="button"
                                      className="btn btn-sm btn-danger btn-md-width mr-2 mb-2"
                                      onClick={() => this.deleteInsuranceCompany(insurance_company)}
                              >
                                <i className="fa fa-remove"/> ลบ
                              </button>*/}
                      </td>
                    </tr>
                  ))
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-sm-5">
          <div className="card card-accent-success">
            <div className="card-header card-customer">
              <strong className="text-title">เพิ่ม/แก้ไขชื่อบริษัทประกันภัย</strong>
            </div>

            <div className="card-block">
              <div className="form-group">
                <label htmlFor="insurance_company_name">ชื่อบริษัทประกันภัย</label>
                <input
                  value={this.state.insurance_company_name}
                  type="text"
                  className="form-control"
                  name="insurance_company_name"
                  placeholder=""
                  style={styles.inputSearch}
                  onChange={(e) => {
                    this.setState({
                      insurance_company_name: e.target.value
                    })
                  }}
                />
              </div>
            </div>

            <div className="card-footer text-right">
              <button type="button"
                      className="btn btn-sm btn-danger mr-2"
                      style={styles.button}
                      onClick={()=> this.clearForm()}
              >
                <i className="fa fa-refresh"/> ยกเลิก
              </button>

              <button type="button"
                      className={this.state.actionType==="แก้ไข"?  "btn btn-sm btn-warning" : "btn btn-sm btn-primary"}
                      style={styles.button}
                      onClick={() => {
                        this.saveInsuranceCompany()
                      }}
              >
                <i className={this.state.actionType === "แก้ไข" ? "fa fa-edit" : "fa fa-save"}/> {this.state.actionType}
              </button>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})


const styles = {
  button: {
    borderRadius:5,
    height: 36,
  },
  inputSearch: {
    borderRadius:5,
  },
  overflowY: {
    overflow: 'scroll',
  }
}

export default connect(mapStateToProps)(InsuranceCompanySetting)
