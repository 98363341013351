import React, { Component } from 'react';
import axios from 'axios';
import { APIURL, IMAGE_NOPHOTO_URL, IMAGE_UPLOAD_ERROR } from '../../../config/config';
import { connect } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import DatePicker from "react-datepicker";
import * as moment from 'moment'
import NumberFormat from "react-number-format";
import {DateUtil} from "../../../utils/dateUtil";
import Loading from "../../Loading";
import DatePickerIcon from "../../DatePickerIcon";
import {AlertSuccess, AlertWarning} from "../../Alert/Alert";
import {format} from "date-fns";
import {NumberUtil} from "../../../utils/number-util";
import "./notice.css"


pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
    THSarabunNew: {
        normal: 'THSarabunNew-Regular.ttf',
        bold: 'THSarabunNew-Bold.ttf',
        italics: 'THSarabunNew-Italic.ttf',
        bolditalics: 'THSarabunNew-BoldItalic.ttf'
    },
    Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
    }
}

class DebtorsNotice extends Component {

    constructor(props) {
        super(props)

        this.state = {
            user: {},
            lists: [],
            load_data: true,
            isLoading: false,
            debtors: {},
            debtors_id: '',
            amount_of_debt: 0,
            amount_pay_period : 0,

            notice_id: null,
            notice_no: '',
            notice_subject: 'บอกกล่าวให้ชำระหนี้/บอกเลิกสัญญา',

            startNoticeDate: new Date(),
            isOpenNoticeDate: false,
            notice_date_thai: null,
            notice_date: null,

            startPaymentForDate: new Date(),
            isOpenPaymentForDate: false,
            payment_for_date_thai: null,
            payment_for_date: null,

            startPaymentToDate: new Date(),
            isOpenPaymentToDate: false,
            payment_to_date_thai: null,
            payment_to_date: null,

            startPayStartDate: new Date(),
            isOpenPayStartDate: false,
            date_pay_start_thai: null,
            date_pay_start: null,

            startPromiseDate: new Date(),
            isOpenPromiseDate: false,
            date_promise_thai: null,
            date_promise: null,

            startSellDate: new Date(),
            isOpenSellDate: false,
            car_sell_date_thai: null,
            car_sell_date: null,

            failure_pay_installment: '',
            total_period: '',
            notice_creditor: '',
            notice_tel_creditor: '',
            within_days: '',
            notice_payee: '',

            branch_id: '',
            branch_name : '',
            signatory : '',
            contract_number : '',

            customer_id: '',
            customer_name: '',
            customer_lastname: '',
            car_sell_id: '',
            company_setting: null,

            duration_period_id : '',
            duration_period_month : '',

            pay_every_day: '',
            car_brand_name: '',
            car_model_name: '',
            car_license_plate: '',
            province_name : '',
            car_color_name: '',

            sell_guarantor_name:'',
            sell_guarantor_name2:'',

            guarantor_name:'',
        }

        this.handleNoticeDate = this.handleNoticeDate.bind(this);
        this.handlePaymentForDate = this.handlePaymentForDate.bind(this);
        this.handlePaymentToDate = this.handlePaymentToDate.bind(this);
        this.loadCompanySetting = this.loadCompanySetting.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handlePromiseDate = this.handlePromiseDate.bind(this);
        this.handleSellDate = this.handleSellDate.bind(this);
        this.handlePayStartDate = this.handlePayStartDate.bind(this);
        this.loadDebtorsNoticeData = this.loadDebtorsNoticeData.bind(this);
        this.printDebtorsBuyer = this.printDebtorsBuyer.bind(this);
        this.printDebtorsGuarantor1 = this.printDebtorsGuarantor1.bind(this);
        this.printDebtorsGuarantor2 = this.printDebtorsGuarantor2.bind(this);
    }

    handleNoticeDate (date) {
        let notice_date =  moment(date).format('YYYY-MM-DD')
        let notice_date_thai = DateUtil.convertLongDateThai(notice_date)

        this.setState({
            startNoticeDate: date,
            isOpenNoticeDate: false,
            notice_date: notice_date,
            notice_date_thai: notice_date_thai
        })
    }

    handlePaymentForDate (date) {
        let payment_for_date =  moment(date).format('YYYY-MM-DD')
        let payment_for_date_thai = DateUtil.convertLongDateThai(payment_for_date)

        this.setState({
            startPaymentForDate: date,
            isOpenPaymentForDate: false,
            payment_for_date: payment_for_date,
            payment_for_date_thai: payment_for_date_thai
        })
    }

    handlePaymentToDate (date) {
        let payment_to_date =  moment(date).format('YYYY-MM-DD')
        let payment_to_date_thai = DateUtil.convertLongDateThai(payment_to_date)

        this.setState({
            startPaymentToDate: date,
            isOpenPaymentToDate: false,
            payment_to_date: payment_to_date,
            payment_to_date_thai: payment_to_date_thai
        })
    }

    handlePromiseDate (date) {
        let date_promise =  moment(date).format('YYYY-MM-DD')
        let date_promise_thai = DateUtil.convertLongDateThai(date_promise)

        this.setState({
            startPromiseDate: date,
            isOpenPromiseDate: false,
            date_promise: date_promise,
            date_promise_thai: date_promise_thai
        })
    }

    handleSellDate (date) {
        let car_sell_date =  moment(date).format('YYYY-MM-DD')
        let car_sell_date_thai = DateUtil.convertLongDateThai(car_sell_date)

        this.setState({
            startSellDate: date,
            isOpenSellDate: false,
            car_sell_date: car_sell_date,
            car_sell_date_thai: car_sell_date_thai
        })
    }

    handlePayStartDate(date){
        let date_pay_start =  moment(date).format('YYYY-MM-DD')
        let date_pay_start_thai = DateUtil.convertLongDateThai(date_pay_start)

        this.setState({
            startPayStartDate: date,
            isOpenPayStartDate: false,
            date_pay_start: date_pay_start,
            date_pay_start_thai: date_pay_start_thai
        })
    }

    async loadCompanySetting () {
        await axios.get(APIURL + '/company_setting/1')
            .then(res => {
                let data = res.data
                let company_setting = data.company_setting
                this.setState({
                    company_name: company_setting.company_name,
                    company_address: company_setting.company_address,
                    company_tel: company_setting.company_tel,
                    juristic_number: company_setting.juristic_number,
                })
            })
    }

    handleOnChange(e) {
        let name = e.target.name;
        let val = e.target.value;

        this.setState({
            [name]: val
        })
    }

    async loadDebtorsDataById (debtors_id) {
        if (this.state.notice_id !== null) {
            // มีรายการใบแจ้งเตือนแล้ว ไม่ต้องโหลดข้อมูล
            return
        }

        await axios.get(APIURL + '/debtors/' + debtors_id)
            .then(res => {
                let debtors = res.data[0];
                let date_pay_start_thai = DateUtil.convertLongDateThai(debtors.date_pay_start)
                let date_promise_thai = DateUtil.convertLongDateThai(debtors.date_promise)
                let car_sell_date_thai = DateUtil.convertLongDateThai(debtors.car_sell_date)
                let car_license_plate = debtors.car_license_plate_new !== '' &&  debtors.car_license_plate_new !==  debtors.car_license_plate_old ? debtors.car_license_plate_new : debtors.car_license_plate_old
                let province_name = debtors.car_license_plate_new !== '' &&  debtors.car_license_plate_new !==  debtors.car_license_plate_old ? debtors.province_name_new : debtors.province_name_old
                let guarantor = debtors.sell_guarantor_name !== '' &&  debtors.sell_guarantor_name !== null ? debtors.sell_guarantor_name : debtors.guarantor_name

                this.setState({
                    debtors: debtors,
                    amount_of_debt: debtors.amount_of_debt,
                    amount_pay_period: debtors.amount_pay_period,
                    signatory: debtors.signatory,
                    date_pay_start_thai,
                    car_brand_name: debtors.car_brand_name,
                    car_model_name: debtors.car_model_name,
                    duration_period_month: debtors.duration_period_month,
                    customer_name: debtors.customer_name,
                    customer_lastname: debtors.customer_lastname,
                    car_color_name: debtors.car_color_name,
                    pay_every_day: debtors.pay_every_day,
                    contract_number: debtors.contract_number,
                    car_license_plate,
                    province_name,
                    date_promise_thai,
                    car_sell_date_thai,
                    customer_id: debtors.customer_id,
                    car_sell_id: debtors.car_sell_id,
                    debtors_id: debtors.debtors_id,
                    car_sell_date: debtors.car_sell_date,
                    date_promise: debtors.date_promise,
                    date_pay_start: debtors.date_pay_start,
                    sell_guarantor_name: debtors.sell_guarantor_name,
                    sell_guarantor_name2: debtors.sell_guarantor_name2,
                    guarantor

                });
            }).catch(error => {
            console.log('error: ', error)
            this.setState({debtors: {}})
        })
    }

    //โหลดใบแจ้งเตือนหลังจากบันทึกแล้ว
    async loadDebtorsNoticeData(debtors_id){
        await axios.get(APIURL + '/debtors_notice/debtors/' + debtors_id)
            .then(res => {
                let data = res.data
                if(data.length === 1){
                    let notice = data[0];

                    let notice_date =  moment(notice.notice_date).format('YYYY-MM-DD')
                    let notice_date_thai = DateUtil.convertLongDateThai(notice_date)

                    let payment_for_date =  moment(notice.payment_for_date).format('YYYY-MM-DD')
                    let payment_for_date_thai = DateUtil.convertLongDateThai(payment_for_date)

                    let payment_to_date =  moment(notice.payment_to_date).format('YYYY-MM-DD')
                    let payment_to_date_thai = DateUtil.convertLongDateThai(payment_to_date)

                    let date_pay_start =  moment(notice.date_pay_start).format('YYYY-MM-DD')
                    let date_pay_start_thai = DateUtil.convertLongDateThai(date_pay_start)

                    let date_promise =  moment(notice.date_promise).format('YYYY-MM-DD')
                    let date_promise_thai = DateUtil.convertLongDateThai(date_promise)

                    let car_sell_date =  moment(notice.car_sell_date).format('YYYY-MM-DD')
                    let car_sell_date_thai = DateUtil.convertLongDateThai(car_sell_date)

                    let car_license_plate = notice.car_license_plate_new !== '' &&  notice.car_license_plate_new !==  notice.car_license_plate_old ? notice.car_license_plate_new : notice.car_license_plate_old
                    let province_name = notice.car_license_plate_new !== '' &&  notice.car_license_plate_new !==  notice.car_license_plate_old ? notice.province_name_new : notice.province_name_old
                    let guarantor = notice.sell_guarantor_name !== '' &&  notice.sell_guarantor_name !== null ? notice.sell_guarantor_name : notice.guarantor_name

                    this.setState({
                        notice_date_thai: notice_date_thai,
                        payment_for_date_thai: payment_for_date_thai,
                        payment_to_date_thai: payment_to_date_thai,
                        date_pay_start_thai: date_pay_start_thai,
                        date_promise_thai: date_promise_thai,
                        car_sell_date_thai: car_sell_date_thai,

                        notice_id: notice.notice_id,
                        notice_no: notice.notice_no,
                        notice_date: notice.notice_date,
                        notice_subject: notice.notice_subject,
                        failure_pay_installment: notice.failure_pay_installment,
                        payment_for_date: notice.payment_for_date,
                        payment_to_date: notice.payment_to_date,
                        total_period: notice.total_period,
                        notice_creditor: notice.notice_creditor,
                        notice_tel_creditor: notice.notice_tel_creditor,
                        within_days: notice.within_days,
                        notice_payee: notice.notice_payee,
                        car_sell_date: notice.car_sell_date,
                        date_promise: notice.date_promise,
                        duration_period_month: notice.duration_period_month,
                        pay_every_day: notice.pay_every_day,
                        date_pay_start: notice.date_pay_start,
                        signatory: notice.signatory,
                        amount_of_debt: notice.amount_of_debt,
                        amount_pay_period: notice.amount_pay_period,
                        customer_id : notice.customer_id,
                        car_sell_id : notice.car_sell_id,
                        debtors_id : notice.debtors_id,
                        contract_number : notice.contract_number,
                        car_brand_name: notice.car_brand_name,
                        car_model_name: notice.car_model_name,
                        customer_name: notice.customer_name,
                        customer_lastname: notice.customer_lastname,
                        car_color_name: notice.car_color_name,
                        sell_guarantor_name: notice.sell_guarantor_name,
                        sell_guarantor_name2: notice.sell_guarantor_name2,
                        guarantor,
                        car_license_plate,
                        province_name
                    })
                }
            })
    }

    SaveDebtorsNotice(action){
            let notice_no = this.state.notice_no
            let notice_date = this.state.notice_date
            let notice_subject = this.state.notice_subject
            let failure_pay_installment = this.state.failure_pay_installment
            let payment_for_date = this.state.payment_for_date
            let payment_to_date = this.state.payment_to_date
            let total_period = this.state.total_period
            let notice_creditor = this.state.notice_creditor
            let notice_tel_creditor = this.state.notice_tel_creditor
            let within_days = this.state.within_days
            let notice_payee = this.state.notice_payee
            let contract_number = this.state.contract_number
            let car_sell_date = this.state.car_sell_date
            let date_promise = this.state.date_promise
            let duration_period_month = this.state.duration_period_month
            let pay_every_day = this.state.pay_every_day
            let date_pay_start = this.state.date_pay_start
            let signatory = this.state.signatory
            let amount_of_debt = this.state.amount_of_debt
            let amount_pay_period = this.state.amount_pay_period
            let customer_id = this.state.customer_id
            let car_sell_id = this.state.car_sell_id
            let debtors_id = this.state.debtors_id
            let notice_date_thai = this.state.notice_date_thai

            if (notice_date_thai === '') {
                AlertWarning('กรุณาเลือกวันที่ออกใบแจ้งเตือน');
                return
            }

        let data = {
            notice_no : notice_no,
            notice_date : notice_date,
            notice_subject : notice_subject,
            failure_pay_installment : failure_pay_installment,
            payment_for_date : payment_for_date,
            payment_to_date : payment_to_date,
            total_period : total_period,
            notice_creditor : notice_creditor,
            notice_tel_creditor : notice_tel_creditor,
            within_days : within_days,
            notice_payee : notice_payee,
            contract_number : contract_number,
            car_sell_date : car_sell_date,
            date_promise : date_promise,
            duration_period_month : duration_period_month,
            pay_every_day : pay_every_day,
            date_pay_start : date_pay_start,
            signatory : signatory,
            amount_of_debt : amount_of_debt,
            amount_pay_period : amount_pay_period,
            customer_id : customer_id,
            car_sell_id : car_sell_id,
            debtors_id : debtors_id,
            user_created: this.state.user.user,
            user_updated: this.state.user.user,
        }
            //console.log('dataDeb==', data)
            //return;
        this.setState({
            isLoading: true,
        }, () => {
            if (action === 'add') {
                let url = APIURL + '/debtors_notice';
                axios.post( url, data)
                    .then(res => {
                        if (res.status === 200) {
                            let resData = res.data
                            let notice_id = resData.notice_id;
                            AlertSuccess('บันทึกข้อมูลเรียบร้อยแล้ว');
                            this.setState({
                                isLoading: false,
                                notice_id: notice_id
                                // isExist: true,
                            }, () => this.loadDebtorsNoticeData(debtors_id))
                        }
                    }).catch(error => {
                    console.log('error:', error)
                });
            } else if (action === 'edit') {
                let notice_id = this.state.notice_id
                let editData = {...data, notice_id: notice_id }
                let url = APIURL + '/debtors_notice';
                axios.put( url, editData)
                    .then(res => {
                        if (res.data === 'success') {
                            AlertSuccess('แก้ไขข้อมูลเรียบร้อยแล้ว');
                            this.setState({
                                isLoading: false,
                            }, () => this.loadDebtorsNoticeData(debtors_id))
                        }
                    }).catch(error => {
                    console.log('error:', error)
                });
            }
        });
    }

    printDebtorsBuyer(){
        let notice_no = this.state.notice_no
        let notice_subject = this.state.notice_subject
        let failure_pay_installment = this.state.failure_pay_installment
        let total_period = this.state.total_period
        let notice_creditor = this.state.notice_creditor
        let notice_tel_creditor = this.state.notice_tel_creditor
        let within_days = this.state.within_days
        let notice_payee = this.state.notice_payee
        let contract_number = this.state.contract_number
        let duration_period_month = this.state.duration_period_month
        let pay_every_day = this.state.pay_every_day
        let signatory = this.state.signatory
        let amount_of_debt = this.state.amount_of_debt
        let amount_pay_period = this.state.amount_pay_period

        let notice_date_thai = this.state.notice_date_thai
        let payment_for_date_thai = this.state.payment_for_date_thai
        let payment_to_date_thai = this.state.payment_to_date_thai
        let date_pay_start_thai = this.state.date_pay_start_thai
        let date_promise_thai = this.state.date_promise_thai
        let car_sell_date_thai = this.state.car_sell_date_thai

        let car_license_plate = this.state.car_license_plate
        let province_name = this.state.province_name
        let car_license = car_license_plate + ' ' + province_name

        let customer_name = this.state.customer_name
        let customer_lastname = this.state.customer_lastname
        let buyer_name = customer_name + ' ' + customer_lastname

        let car_brand_name = this.state.car_brand_name
        let car_model_name = this.state.car_model_name
        let car_color_name = this.state.car_color_name

        let company_name = this.state.company_name
        let company_address = this.state.company_address
        let juristic_number = this.state.juristic_number === '' ? '-' : this.state.juristic_number
        let company_tel = this.state.company_tel === '' ? '-' : this.state.company_tel

        let headerCenter
        headerCenter = company_name+'\n'+ company_address + ' โทร. ' + company_tel + ' ' + ' ' + 'เลขประจำตัวผู้เสียภาษี ' + juristic_number

        let debtorsNoticePayee
        if(notice_payee === null || notice_payee === ''){
            debtorsNoticePayee = [
                {text: '(.........................................................)',
                    style: ['normalCenter'],
                }
            ]
        }else{
            debtorsNoticePayee = [
                {text: '( '+ notice_payee +' )', style: ['normalCenter'],}
            ]
        }

        let docDefinition = {
            pageOrientation: 'portrait',
            pageSize: 'A4',
            pageMargins: [35, 25, 35, 0],
            content: [
                {
                    columns: [
                        { width: '100%', style: 'normal',
                            text: '',
                        },
                    ],
                    columnGap: 0,
                    lineHeight:1
                },
                {
                    table: {
                        widths: [ '30%', '40%', '30%' ],
                        body: [
                            [
                                {text: headerCenter, style: 'textBold2', colSpan: 2, margin: [15, 0, 0, 30]},
                                '',
                                '',
                            ],
                            [
                                {text: '', style: 'normal'},
                                {
                                    text: 'หนังสือบอกกล่าวทวงถาม',
                                    style: 'bodyhead',
                                    margin: [0, 0, 0, 12]
                                },
                                {text: '', style: 'normal'},
                            ],
                            [
                                {
                                    columns: [
                                        { width: '*', style: 'normalRight', text: 'เขียนที่ ',},
                                        { width: 'auto', style: ['normalRight'], text: company_name},
                                    ],
                                    colSpan: 3,
                                    alignment: 'right',
                                    columnGap: 8
                                },
                                '',
                                ''
                            ],
                            [
                                {
                                    columns: [
                                        { width: '*', style: 'normalRight', text: 'เลขที่ ',},
                                        { width: 'auto', style: ['normalRight'], text: notice_no + ' / 1'},
                                    ],
                                    colSpan: 3,
                                    alignment: 'right',
                                    columnGap: 8
                                },
                                '',
                                ''
                            ],
                            [
                                {
                                    columns: [
                                        { width: '*', style: 'normalRight', text: 'วันที่ ',},
                                        { width: 'auto', style: ['normalRight'], text: notice_date_thai,},
                                    ],
                                    colSpan: 2,
                                    alignment: 'right',
                                    columnGap: 8
                                },
                                '',
                                ''
                            ],
                            [
                                {text: '', style: 'normalRight', colSpan: 3},
                                '',
                                ''
                            ],
                        ],
                    },
                    layout: 'noBorders',
                    margin: [0, 0, 0, 20]
                },
                {
                    columns: [
                        { width: 15, style: 'normal', text: '',},
                        { width: 40, style: 'normal', text: 'เรื่อง',},
                        { width: '*', style: ['normalLeft'], text: notice_subject},
                    ],
                    columnGap: 0,
                    lineHeight:1.2
                },
                {
                    columns: [
                        { width: 15, style: 'normal', text: '',},
                        { width: 40, style: 'normal', text: 'เรียน',},
                        { width: '28%', style: ['normalLeft'], text: buyer_name,},
                        { width: '*', style: 'normal', text: 'ผู้เช่าซื้อ'},
                    ],
                    columnGap: 0,
                    lineHeight:1.2
                },
                {
                    columns: [
                        { width: 15, style: 'normal', text: '',},
                        { width: 40, style: 'normal', text: 'อ้างถึง',},
                        { width: '28%', style: 'normal', text: 'สัญญาเช่าซื้อรถยนต์ ฉบับลงวันที่',},
                        { width: '*', style: ['normalLeft'], text: car_sell_date_thai},
                    ],
                    columnGap: 0,
                    lineHeight:1.2
                },
                {
                    columns: [
                        { width: 15, style: 'normal', text: '',},
                        { width: 40, style: 'normal', text: '',},
                        { width: '28%', style: 'normal', text: 'สัญญาค้ำประกัน ฉบับลงวันที่',},
                        { width: '*', style: ['normalLeft'], text: date_promise_thai},
                    ],
                    columnGap: 0,
                    lineHeight:1.2,
                    margin: [0, 0, 0, 20]
                },

                {
                    columns: [
                        { width: 55, style: 'normal', text: ' ',},
                        { width: '*', style: 'normal', text: 'ตามที่ท่านได้เช่าซื้อ รถยนต์คันหมายเลขทะเบียน ' + car_license + 'ยี่ห้อ ' + car_brand_name + ' ' + ' แบบ ' + car_model_name + ' ' + ' สี'+ car_color_name},
                    ],
                    columnGap: 0,
                    lineHeight:1.2,
                },
                {
                    columns: [
                        { width: 15, style: 'normal', text: ' ',},
                        { width: '*', style: 'normal', text: 'จาก ' + signatory + ' ' + 'ผู้ให้เช่าซื้อ ในราคาเช่าซื้อ ' + NumberUtil.addCommas(String(amount_of_debt)) + ' บาท' + ' โดยแบ่งชำระเป็นรายงวดจำนวน ' + duration_period_month +
                                ' งวด ' + '\n' + 'งวดละ ' + NumberUtil.addCommas(String(amount_pay_period)) + ' บาท กำหนดชำระทุกวันที่ ' + pay_every_day + ' ของทุกเดือน โดยกำหนดชำระงวดแรกวันที่ ' + date_pay_start_thai +
                                ' ดังรายละเอียดในสัญญาเช่าซื้อ ตามเอกสารที่อ้างถึง และต่อมาท่านได้ผิดนัดชำระค่าเช่าซื้อตั้งแต่งวดที่ ' + failure_pay_installment + ' งวดประจำวันที่ ' + '\n' + payment_for_date_thai +
                                ' - งวดประจำวันที่ ' + payment_to_date_thai + ' และจนถึงปัจจุบัน เป็นระยะเวลา ' + total_period + ' งวดติดต่อกัน ซึ่งรายละเอียดท่านทราบดีอยู่แล้วนั้น '},
                    ],
                    columnGap: 0,
                    lineHeight:1.2,
                    margin: [0, 0, 0, 10]
                },

                {
                    columns: [
                        { width: 55, style: 'normal', text: ' ',},
                        { width: '*', style: 'normal', text: 'ฉะนั้นโดยหนังสือฉบับนี้ ข้าพเจ้า ' + notice_creditor + ' จึงมีความประสงค์ให้ท่านชำระเงินค่าเช่าซื้อให้กับ'},
                    ],
                    columnGap: 0,
                    lineHeight:1.2,
                },
                {
                    columns: [
                        { width: 15, style: 'normal', text: ' ',},
                        { width: '*', style: 'normal', text: signatory + ' ภายในกำหนด ' + within_days + ' วัน นับตั้งแต่วันที่ท่านได้รับหนังสือบอกกล่าวฉบับนี้ หากท่านไม่ชำระภายใน' + '\n' +
                                'กำหนดระยะเวลาดังกล่าว ' + signatory + ' ขอถือเอาหนังสือฉบับนี้ เป็นการบอกเลิกสัญญาเช่าซื้อ พร้อมกับให้ท่าน' + '\n' + 'ส่งมอบรถยนต์ที่เช่าซื้อคืนให้กับ ' + signatory + ' ณ ภูมิลำเนาของ ' + signatory +
                                ' ในสภาพเรียบร้อย หากท่านยังคงเพิกเฉยไม่ปฏิบัติตาม ' + signatory + ' มีความจำเป็นที่จะต้องดำเนินการตามขั้นตอนของกฏหมายต่อไป และหากท่านมีข้อสงสัยหรือมีเหตุประการใดกรุณาติดต่อกลับมาที่ ' +
                                signatory + ' หมายเลขโทรศัพท์ ' + notice_tel_creditor + ' หวังว่าคงได้รับความร่วมมือจากท่านเป็นอย่างดี '},
                    ],
                    columnGap: 0,
                    lineHeight:1.2,
                    margin: [0, 0, 0, 20]
                },
                {
                    columns: [
                        { width: '50%', style: 'normal', text: '',},
                        { width: '50%', style: ['normalCenter'], text: 'ขอแสดงความนับถือ',},
                    ],
                    columnGap: 0,
                    lineHeight:1.8,
                },
                {
                    columns: [
                        { width: '50%', style: 'normal', text: '',},
                        { width: '50%', style: ['normalCenter'], text: '..............................................................'},
                    ],
                    columnGap: 0,
                    lineHeight:1.1,
                },
                {
                    columns: [
                        { width: '50%', style: 'normal', text: '',},
                        { width: '50%', style: ['normalCenter'], text: debtorsNoticePayee,},
                    ],
                    columnGap: 0,
                    lineHeight:1.2,
                },
            ],
            styles: {
                bodyhead: {
                    fontSize: 16,
                    bold: true,
                    alignment: 'center'
                },
                normal: {
                    fontSize: 15,
                },
                normalRight: {
                    fontSize: 15,
                    alignment: 'right'
                },
                normalLeft: {
                    fontSize: 15,
                    alignment: 'left',
                },
                normalCenter: {
                    fontSize: 15,
                    alignment: 'center',
                },
                textBold2: {
                    fontSize: 12,
                    bold: true,
                },
                bottomLine: {
                    decoration: 'underline',
                    decorationStyle: 'dotted',
                },
                normalWhite: {
                    fontSize: 15,
                    color : '#ffffff',
                },
            },
            defaultStyle: {
                font: 'THSarabunNew'
            }
        };
        pdfMake.createPdf(docDefinition).open();
    }

    //พิมพ์คนค้ำ1
    printDebtorsGuarantor1(){
        let notice_no = this.state.notice_no
        let notice_subject = this.state.notice_subject
        let failure_pay_installment = this.state.failure_pay_installment
        let total_period = this.state.total_period
        let notice_creditor = this.state.notice_creditor
        let notice_tel_creditor = this.state.notice_tel_creditor
        let within_days = this.state.within_days
        let notice_payee = this.state.notice_payee
        let contract_number = this.state.contract_number
        let duration_period_month = this.state.duration_period_month
        let pay_every_day = this.state.pay_every_day
        let signatory = this.state.signatory
        let amount_of_debt = this.state.amount_of_debt
        let amount_pay_period = this.state.amount_pay_period
        let guarantor = this.state.guarantor

        let notice_date_thai = this.state.notice_date_thai
        let payment_for_date_thai = this.state.payment_for_date_thai
        let payment_to_date_thai = this.state.payment_to_date_thai
        let date_pay_start_thai = this.state.date_pay_start_thai
        let date_promise_thai = this.state.date_promise_thai
        let car_sell_date_thai = this.state.car_sell_date_thai

        let car_license_plate = this.state.car_license_plate
        let province_name = this.state.province_name
        let car_license = car_license_plate + ' ' + province_name

        let customer_name = this.state.customer_name
        let customer_lastname = this.state.customer_lastname
        let buyer_name = customer_name + ' ' + customer_lastname

        let car_brand_name = this.state.car_brand_name
        let car_model_name = this.state.car_model_name
        let car_color_name = this.state.car_color_name

        let company_name = this.state.company_name
        let company_address = this.state.company_address
        let juristic_number = this.state.juristic_number === '' ? '-' : this.state.juristic_number
        let company_tel = this.state.company_tel === '' ? '-' : this.state.company_tel

        let headerCenter
        headerCenter = company_name+'\n'+ company_address + ' โทร. ' + company_tel + ' ' + ' ' + 'เลขประจำตัวผู้เสียภาษี ' + juristic_number

        let debtorsNoticePayee
        if(notice_payee === null || notice_payee === ''){
            debtorsNoticePayee = [
                {text: '(.........................................................)',
                    style: ['normalCenter'],
                }
            ]
        }else{
            debtorsNoticePayee = [
                {text: '( '+ notice_payee +' )', style: ['normalCenter'],}
            ]
        }

        let docDefinition = {
            pageOrientation: 'portrait',
            pageSize: 'A4',
            pageMargins: [35, 25, 35, 0],
            content: [
                {
                    columns: [
                        { width: '100%', style: 'normal',
                            text: '',
                        },
                    ],
                    columnGap: 0,
                    lineHeight:1
                },
                {
                    table: {
                        widths: [ '30%', '40%', '30%' ],
                        body: [
                            [
                                {text: headerCenter, style: 'textBold2', colSpan: 2, margin: [15, 0, 0, 30]},
                                '',
                                '',
                            ],
                            [
                                {text: '', style: 'normal'},
                                {
                                    text: 'หนังสือบอกกล่าวทวงถาม',
                                    style: 'bodyhead',
                                    margin: [0, 0, 0, 12]
                                },
                                {text: '', style: 'normal'},
                            ],
                            [
                                {
                                    columns: [
                                        { width: '*', style: 'normalRight', text: 'เขียนที่ ',},
                                        { width: 'auto', style: ['normalRight'], text: company_name},
                                    ],
                                    colSpan: 3,
                                    alignment: 'right',
                                    columnGap: 8
                                },
                                '',
                                ''
                            ],
                            [
                                {
                                    columns: [
                                        { width: '*', style: 'normalRight', text: 'เลขที่ ',},
                                        { width: 'auto', style: ['normalRight'], text: notice_no + ' / 2'},
                                    ],
                                    colSpan: 3,
                                    alignment: 'right',
                                    columnGap: 8
                                },
                                '',
                                ''
                            ],
                            [
                                {
                                    columns: [
                                        { width: '*', style: 'normalRight', text: 'วันที่ ',},
                                        { width: 'auto', style: ['normalRight'], text: notice_date_thai,},
                                    ],
                                    colSpan: 2,
                                    alignment: 'right',
                                    columnGap: 8
                                },
                                '',
                                ''
                            ],
                            [
                                {text: '', style: 'normalRight', colSpan: 3},
                                '',
                                ''
                            ],
                        ],
                    },
                    layout: 'noBorders',
                    margin: [0, 0, 0, 20]
                },
                {
                    columns: [
                        { width: 15, style: 'normal', text: '',},
                        { width: 40, style: 'normal', text: 'เรื่อง',},
                        { width: '*', style: ['normalLeft'], text: notice_subject},
                    ],
                    columnGap: 0,
                    lineHeight:1.2
                },
                {
                    columns: [
                        { width: 15, style: 'normal', text: '',},
                        { width: 40, style: 'normal', text: 'เรียน',},
                        { width: '28%', style: ['normalLeft'], text: guarantor,},
                        { width: '*', style: 'normal', text: 'ผู้ค้ำประกัน'},
                    ],
                    columnGap: 0,
                    lineHeight:1.2
                },
                {
                    columns: [
                        { width: 15, style: 'normal', text: '',},
                        { width: 40, style: 'normal', text: 'อ้างถึง',},
                        { width: '28%', style: 'normal', text: 'สัญญาเช่าซื้อรถยนต์ ฉบับลงวันที่',},
                        { width: '*', style: ['normalLeft'], text: car_sell_date_thai},
                    ],
                    columnGap: 0,
                    lineHeight:1.2
                },
                {
                    columns: [
                        { width: 15, style: 'normal', text: '',},
                        { width: 40, style: 'normal', text: '',},
                        { width: '28%', style: 'normal', text: 'สัญญาค้ำประกัน ฉบับลงวันที่',},
                        { width: '*', style: ['normalLeft'], text: date_promise_thai},
                    ],
                    columnGap: 0,
                    lineHeight:1.2,
                    margin: [0, 0, 0, 20]
                },

                {
                    columns: [
                        { width: 55, style: 'normal', text: ' ',},
                        { width: '*', style: 'normal', text: 'ตามที่ท่านได้ทำสัญญาค้ำประกันการเช่าซื้อ รถยนต์คันหมายเลขทะเบียน ' + car_license + 'ยี่ห้อ ' + car_brand_name},
                    ],
                    columnGap: 0,
                    lineHeight:1.2,
                },
                {
                    columns: [
                        { width: 15, style: 'normal', text: ' ',},
                        { width: '*', style: 'normal', text: ' แบบ ' + car_model_name + ' ' + ' สี'+ car_color_name + ' ของ ' + buyer_name +
                                ' ผู้เช่าซื้อ ไว้กับ ' + signatory + ' ' + 'ผู้ให้เช่าซื้อ ในราคาเช่าซื้อ'},
                    ],
                    columnGap: 0,
                    lineHeight:1.2,
                },
                {
                    columns: [
                        { width: 15, style: 'normal', text: ' ',},
                        { width: '*', style: 'normal', text:  NumberUtil.addCommas(String(amount_of_debt)) + ' บาท ' + ' โดยแบ่งชำระเป็นรายงวดจำนวน ' + duration_period_month +
                                ' งวด ' + 'งวดละ ' + NumberUtil.addCommas(String(amount_pay_period)) + ' บาท กำหนดชำระทุกวันที่ ' + pay_every_day + ' ของทุกเดือน โดยกำหนดชำระงวดแรกวันที่ ' + date_pay_start_thai +
                                ' ดังรายละเอียดในสัญญาเช่าซื้อ, สัญญาค้ำประกัน ตามเอกสารที่อ้างถึง และต่อมา ' + buyer_name +' ผู้เช่าซื้อ ได้ผิดนัดชำระค่าเช่าซื้อตั้งแต่งวดที่ ' + failure_pay_installment + ' งวดประจำวันที่ ' + payment_for_date_thai +
                                ' - งวดประจำวันที่ ' + '\n' + payment_to_date_thai + ' และจนถึงปัจจุบัน เป็นระยะเวลา ' + total_period + ' งวดติดต่อกัน ซึ่งรายละเอียดท่านทราบดีอยู่แล้วนั้น '},
                    ],
                    columnGap: 0,
                    lineHeight:1.2,
                    margin: [0, 0, 0, 10]
                },

                {
                    columns: [
                        { width: 55, style: 'normal', text: ' ',},
                        { width: '*', style: 'normal', text: 'ฉะนั้นโดยหนังสือฉบับนี้ ข้าพเจ้า ' + notice_creditor + ' จึงมีความประสงค์ให้ท่านในฐานะผู้ค้ำประกันชำระเงิน'},
                    ],
                    columnGap: 0,
                    lineHeight:1.2,
                },
                {
                    columns: [
                        { width: 15, style: 'normal', text: ' ',},
                        { width: '*', style: 'normal', text: 'ค่าเช่าซื้อตามสัญญาให้กับ ' + signatory + ' ภายในกำหนด ' + within_days + ' วัน นับตั้งแต่วันที่ท่านได้รับหนังสือบอกกล่าวฉบับนี้ หากท่านไม่ชำระภายใน' +
                                'กำหนดระยะเวลาดังกล่าว ' + signatory + ' ขอถือเอาหนังสือฉบับนี้ เป็นการบอกเลิกสัญญา' + '\n' + 'เช่าซื้อ พร้อมกับให้ท่านส่งมอบรถยนต์ที่เช่าซื้อคืนให้กับ' + 'ส่งมอบรถยนต์ที่เช่าซื้อคืนให้กับ ' + signatory + ' ณ ภูมิลำเนาของ ' + signatory +
                                ' ในสภาพเรียบร้อย หากท่านยังคงเพิกเฉยไม่ปฏิบัติตาม ' + signatory + ' มีความจำเป็นที่จะต้องดำเนินการตามขั้นตอนของกฏหมายต่อไป และหากท่านมีข้อสงสัยหรือมีเหตุประการใดกรุณาติดต่อกลับมาที่ ' +
                                signatory + ' หมายเลขโทรศัพท์ ' + notice_tel_creditor + ' หวังว่าคงได้รับความร่วมมือจากท่านเป็นอย่างดี '},
                    ],
                    columnGap: 0,
                    lineHeight:1.2,
                    margin: [0, 0, 0, 20]
                },
                {
                    columns: [
                        { width: '50%', style: 'normal', text: '',},
                        { width: '50%', style: ['normalCenter'], text: 'ขอแสดงความนับถือ',},
                    ],
                    columnGap: 0,
                    lineHeight:1.8,
                },
                {
                    columns: [
                        { width: '50%', style: 'normal', text: '',},
                        { width: '50%', style: ['normalCenter'], text: '..............................................................'},
                    ],
                    columnGap: 0,
                    lineHeight:1.1,
                },
                {
                    columns: [
                        { width: '50%', style: 'normal', text: '',},
                        { width: '50%', style: ['normalCenter'], text: debtorsNoticePayee,},
                    ],
                    columnGap: 0,
                    lineHeight:1.2,
                },
            ],
            styles: {
                bodyhead: {
                    fontSize: 16,
                    bold: true,
                    alignment: 'center'
                },
                normal: {
                    fontSize: 15,
                },
                normalRight: {
                    fontSize: 15,
                    alignment: 'right'
                },
                normalLeft: {
                    fontSize: 15,
                    alignment: 'left',
                },
                normalCenter: {
                    fontSize: 15,
                    alignment: 'center',
                },
                textBold2: {
                    fontSize: 12,
                    bold: true,
                },
                bottomLine: {
                    decoration: 'underline',
                    decorationStyle: 'dotted',
                },
                normalWhite: {
                    fontSize: 15,
                    color : '#ffffff',
                },
            },
            defaultStyle: {
                font: 'THSarabunNew'
            }
        };
        pdfMake.createPdf(docDefinition).open();
    }

    //พิมพ์คนค้ำ2
    printDebtorsGuarantor2(){
        let notice_no = this.state.notice_no
        let notice_subject = this.state.notice_subject
        let failure_pay_installment = this.state.failure_pay_installment
        let total_period = this.state.total_period
        let notice_creditor = this.state.notice_creditor
        let notice_tel_creditor = this.state.notice_tel_creditor
        let within_days = this.state.within_days
        let notice_payee = this.state.notice_payee
        let contract_number = this.state.contract_number
        let duration_period_month = this.state.duration_period_month
        let pay_every_day = this.state.pay_every_day
        let signatory = this.state.signatory
        let amount_of_debt = this.state.amount_of_debt
        let amount_pay_period = this.state.amount_pay_period
        let sell_guarantor_name2 = this.state.sell_guarantor_name2

        let notice_date_thai = this.state.notice_date_thai
        let payment_for_date_thai = this.state.payment_for_date_thai
        let payment_to_date_thai = this.state.payment_to_date_thai
        let date_pay_start_thai = this.state.date_pay_start_thai
        let date_promise_thai = this.state.date_promise_thai
        let car_sell_date_thai = this.state.car_sell_date_thai

        let car_license_plate = this.state.car_license_plate
        let province_name = this.state.province_name
        let car_license = car_license_plate + ' ' + province_name

        let customer_name = this.state.customer_name
        let customer_lastname = this.state.customer_lastname
        let buyer_name = customer_name + ' ' + customer_lastname

        let car_brand_name = this.state.car_brand_name
        let car_model_name = this.state.car_model_name
        let car_color_name = this.state.car_color_name

        let company_name = this.state.company_name
        let company_address = this.state.company_address
        let juristic_number = this.state.juristic_number === '' ? '-' : this.state.juristic_number
        let company_tel = this.state.company_tel === '' ? '-' : this.state.company_tel

        let headerCenter
        headerCenter = company_name+'\n'+ company_address + ' โทร. ' + company_tel + ' ' + ' ' + 'เลขประจำตัวผู้เสียภาษี ' + juristic_number

        let debtorsNoticePayee
        if(notice_payee === null || notice_payee === ''){
            debtorsNoticePayee = [
                {text: '(.........................................................)',
                    style: ['normalCenter'],
                }
            ]
        }else{
            debtorsNoticePayee = [
                {text: '( '+ notice_payee +' )', style: ['normalCenter'],}
            ]
        }

        let docDefinition = {
            pageOrientation: 'portrait',
            pageSize: 'A4',
            pageMargins: [35, 25, 35, 0],
            content: [
                {
                    columns: [
                        { width: '100%', style: 'normal',
                            text: '',
                        },
                    ],
                    columnGap: 0,
                    lineHeight:1
                },
                {
                    table: {
                        widths: [ '30%', '40%', '30%' ],
                        body: [
                            [
                                {text: headerCenter, style: 'textBold2', colSpan: 2, margin: [15, 0, 0, 30]},
                                '',
                                '',
                            ],
                            [
                                {text: '', style: 'normal'},
                                {
                                    text: 'หนังสือบอกกล่าวทวงถาม',
                                    style: 'bodyhead',
                                    margin: [0, 0, 0, 12]
                                },
                                {text: '', style: 'normal'},
                            ],
                            [
                                {
                                    columns: [
                                        { width: '*', style: 'normalRight', text: 'เขียนที่ ',},
                                        { width: 'auto', style: ['normalRight'], text: company_name},
                                    ],
                                    colSpan: 3,
                                    alignment: 'right',
                                    columnGap: 8
                                },
                                '',
                                ''
                            ],
                            [
                                {
                                    columns: [
                                        { width: '*', style: 'normalRight', text: 'เลขที่ ',},
                                        { width: 'auto', style: ['normalRight'], text: notice_no + ' / 3'},
                                    ],
                                    colSpan: 3,
                                    alignment: 'right',
                                    columnGap: 8
                                },
                                '',
                                ''
                            ],
                            [
                                {
                                    columns: [
                                        { width: '*', style: 'normalRight', text: 'วันที่ ',},
                                        { width: 'auto', style: ['normalRight'], text: notice_date_thai,},
                                    ],
                                    colSpan: 2,
                                    alignment: 'right',
                                    columnGap: 8
                                },
                                '',
                                ''
                            ],
                            [
                                {text: '', style: 'normalRight', colSpan: 3},
                                '',
                                ''
                            ],
                        ],
                    },
                    layout: 'noBorders',
                    margin: [0, 0, 0, 20]
                },
                {
                    columns: [
                        { width: 15, style: 'normal', text: '',},
                        { width: 40, style: 'normal', text: 'เรื่อง',},
                        { width: '*', style: ['normalLeft'], text: notice_subject},
                    ],
                    columnGap: 0,
                    lineHeight:1.2
                },
                {
                    columns: [
                        { width: 15, style: 'normal', text: '',},
                        { width: 40, style: 'normal', text: 'เรียน',},
                        { width: '28%', style: ['normalLeft'], text: sell_guarantor_name2,},
                        { width: '*', style: 'normal', text: 'ผู้ค้ำประกัน'},
                    ],
                    columnGap: 0,
                    lineHeight:1.2
                },
                {
                    columns: [
                        { width: 15, style: 'normal', text: '',},
                        { width: 40, style: 'normal', text: 'อ้างถึง',},
                        { width: '28%', style: 'normal', text: 'สัญญาเช่าซื้อรถยนต์ ฉบับลงวันที่',},
                        { width: '*', style: ['normalLeft'], text: car_sell_date_thai},
                    ],
                    columnGap: 0,
                    lineHeight:1.2
                },
                {
                    columns: [
                        { width: 15, style: 'normal', text: '',},
                        { width: 40, style: 'normal', text: '',},
                        { width: '28%', style: 'normal', text: 'สัญญาค้ำประกัน ฉบับลงวันที่',},
                        { width: '*', style: ['normalLeft'], text: date_promise_thai},
                    ],
                    columnGap: 0,
                    lineHeight:1.2,
                    margin: [0, 0, 0, 20]
                },

                {
                    columns: [
                        { width: 55, style: 'normal', text: ' ',},
                        { width: '*', style: 'normal', text: 'ตามที่ท่านได้ทำสัญญาค้ำประกันการเช่าซื้อ รถยนต์คันหมายเลขทะเบียน ' + car_license + 'ยี่ห้อ ' + car_brand_name},
                    ],
                    columnGap: 0,
                    lineHeight:1.2,
                },
                {
                    columns: [
                        { width: 15, style: 'normal', text: ' ',},
                        { width: '*', style: 'normal', text: ' แบบ ' + car_model_name + ' ' + ' สี'+ car_color_name + ' ของ ' + buyer_name +
                                ' ผู้เช่าซื้อ ไว้กับ ' + signatory + ' ' + 'ผู้ให้เช่าซื้อ ในราคาเช่าซื้อ'},
                    ],
                    columnGap: 0,
                    lineHeight:1.2,
                },
                {
                    columns: [
                        { width: 15, style: 'normal', text: ' ',},
                        { width: '*', style: 'normal', text:  NumberUtil.addCommas(String(amount_of_debt)) + ' บาท ' + ' โดยแบ่งชำระเป็นรายงวดจำนวน ' + duration_period_month +
                                ' งวด ' + 'งวดละ ' + NumberUtil.addCommas(String(amount_pay_period)) + ' บาท กำหนดชำระทุกวันที่ ' + pay_every_day + ' ของทุกเดือน โดยกำหนดชำระงวดแรกวันที่ ' + date_pay_start_thai +
                                ' ดังรายละเอียดในสัญญาเช่าซื้อ, สัญญาค้ำประกัน ตามเอกสารที่อ้างถึง และต่อมา ' + buyer_name +' ผู้เช่าซื้อ ได้ผิดนัดชำระค่าเช่าซื้อตั้งแต่งวดที่ ' + failure_pay_installment + ' งวดประจำวันที่ ' + payment_for_date_thai +
                                ' - งวดประจำวันที่ ' + '\n' + payment_to_date_thai + ' และจนถึงปัจจุบัน เป็นระยะเวลา ' + total_period + ' งวดติดต่อกัน ซึ่งรายละเอียดท่านทราบดีอยู่แล้วนั้น '},
                    ],
                    columnGap: 0,
                    lineHeight:1.2,
                    margin: [0, 0, 0, 10]
                },

                {
                    columns: [
                        { width: 55, style: 'normal', text: ' ',},
                        { width: '*', style: 'normal', text: 'ฉะนั้นโดยหนังสือฉบับนี้ ข้าพเจ้า ' + notice_creditor + ' จึงมีความประสงค์ให้ท่านในฐานะผู้ค้ำประกันชำระเงิน'},
                    ],
                    columnGap: 0,
                    lineHeight:1.2,
                },
                {
                    columns: [
                        { width: 15, style: 'normal', text: ' ',},
                        { width: '*', style: 'normal', text: 'ค่าเช่าซื้อตามสัญญาให้กับ ' + signatory + ' ภายในกำหนด ' + within_days + ' วัน นับตั้งแต่วันที่ท่านได้รับหนังสือบอกกล่าวฉบับนี้ หากท่านไม่ชำระภายใน' +
                                'กำหนดระยะเวลาดังกล่าว ' + signatory + ' ขอถือเอาหนังสือฉบับนี้ เป็นการบอกเลิกสัญญา' + '\n' + 'เช่าซื้อ พร้อมกับให้ท่านส่งมอบรถยนต์ที่เช่าซื้อคืนให้กับ' + 'ส่งมอบรถยนต์ที่เช่าซื้อคืนให้กับ ' + signatory + ' ณ ภูมิลำเนาของ ' + signatory +
                                ' ในสภาพเรียบร้อย หากท่านยังคงเพิกเฉยไม่ปฏิบัติตาม ' + signatory + ' มีความจำเป็นที่จะต้องดำเนินการตามขั้นตอนของกฏหมายต่อไป และหากท่านมีข้อสงสัยหรือมีเหตุประการใดกรุณาติดต่อกลับมาที่ ' +
                                signatory + ' หมายเลขโทรศัพท์ ' + notice_tel_creditor + ' หวังว่าคงได้รับความร่วมมือจากท่านเป็นอย่างดี '},
                    ],
                    columnGap: 0,
                    lineHeight:1.2,
                    margin: [0, 0, 0, 20]
                },
                {
                    columns: [
                        { width: '50%', style: 'normal', text: '',},
                        { width: '50%', style: ['normalCenter'], text: 'ขอแสดงความนับถือ',},
                    ],
                    columnGap: 0,
                    lineHeight:1.8,
                },
                {
                    columns: [
                        { width: '50%', style: 'normal', text: '',},
                        { width: '50%', style: ['normalCenter'], text: '..............................................................'},
                    ],
                    columnGap: 0,
                    lineHeight:1.1,
                },
                {
                    columns: [
                        { width: '50%', style: 'normal', text: '',},
                        { width: '50%', style: ['normalCenter'], text: debtorsNoticePayee,},
                    ],
                    columnGap: 0,
                    lineHeight:1.2,
                },
            ],
            styles: {
                bodyhead: {
                    fontSize: 16,
                    bold: true,
                    alignment: 'center'
                },
                normal: {
                    fontSize: 15,
                },
                normalRight: {
                    fontSize: 15,
                    alignment: 'right'
                },
                normalLeft: {
                    fontSize: 15,
                    alignment: 'left',
                },
                normalCenter: {
                    fontSize: 15,
                    alignment: 'center',
                },
                textBold2: {
                    fontSize: 12,
                    bold: true,
                },
                bottomLine: {
                    decoration: 'underline',
                    decorationStyle: 'dotted',
                },
                normalWhite: {
                    fontSize: 15,
                    color : '#ffffff',
                },
            },
            defaultStyle: {
                font: 'THSarabunNew'
            }
        };
        pdfMake.createPdf(docDefinition).open();
    }


    componentWillMount() {
        this.setState({
            user: JSON.parse(this.props.user),
        })
    }

    async componentDidMount () {
        this.mounted = true;
        const queryString = require('query-string');
        let debtors = queryString.parse(this.props.location.search);
        let debtors_id = debtors.debtors_id;
        await this.loadCompanySetting();
        await this.loadDebtorsNoticeData(debtors_id);
        await this.loadDebtorsDataById(debtors_id);

        let notice_id = this.state.notice_id

        if(notice_id === null){
            let startNoticeDate = new Date()
            let notice_date = format(startNoticeDate, 'yyyy-MM-dd')
            let notice_date_thai = DateUtil.convertLongDateThai(notice_date)

            this.setState({
                notice_date,
                notice_date_thai
            })
        }

    }

    render() {
        let customer_name2 = this.state.customer_name + ' ' + this.state.customer_lastname
        let license_plate = this.state.car_license_plate + '  ' + this.state.province_name

        return (
            <div className="row">
                <Loading isLoading={this.state.isLoading} />

                <div className="col-12">
                    <div className="card" style={styles.overflowY}>
                        <div className="card-header">
                            <strong className="text-title">ใบแจ้งเตือน </strong>
                        </div>

                        <div className="card-block">
                            <div className="row" style={{lineHeight: 2.0}}>
                                <table style={styles.table}>
                                    <tbody>
                                    <tr>
                                        <td width={'30%'} style={styles.tdContentCenter} >
                                            &nbsp;
                                        </td>
                                        <td style={styles.tdContentCenter}>
                                            <strong style={{fontSize: 18}}> หนังสือบอกกล่าวทวงถาม </strong>
                                        </td>
                                        <td width={'30%'}>&nbsp;</td>
                                    </tr>

                                    <tr>
                                        <td colSpan={3} style={styles.tdContentCenter}>
                                            <div className="mt-3">
                                                {this.state.company_name}<br/>
                                                {this.state.company_address}<br/>
                                                เบอร์โทรศัพท์ &nbsp;&nbsp;{this.state.company_tel}
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;หมายเลขผู้เสียภาษี &nbsp;&nbsp; {this.state.juristic_number === '' ? '-' : this.state.juristic_number}
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td style={styles.tdContentRight}>
                                            <div className="row mt-5 mb-2">
                                                เลขที่
                                                <input className="form-control ml-4"
                                                       style={styles.inputDataNum}
                                                       value={this.state.notice_no || ''}
                                                       onChange={(e) => {
                                                           this.setState({
                                                               notice_no: e.target.value
                                                           })
                                                       }}
                                                /> &nbsp; /
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td style={styles.tdContentRight}>
                                            <div className="row mb-5">
                                            วันที่ &nbsp;
                                            <input readOnly={true}
                                                   name="notice_date_thai"
                                                   className="form-control ml-4"
                                                   style={{width: 160}}
                                                   value={this.state.notice_date_thai === null ? '' : this.state.notice_date_thai}
                                            />
                                            <div>
                                                <DatePicker
                                                    selected={this.state.startNoticeDate}
                                                    onChange={this.handleNoticeDate}
                                                    dateFormat="yyyy-MM-dd"
                                                    customInput={<DatePickerIcon />}
                                                />
                                            </div>
                                            {
                                                this.state.isOpenNoticeDate && (
                                                    <DatePicker
                                                        selected={this.state.startNoticeDate}
                                                        onChange={this.handleNoticeDate}
                                                        dateFormat="yyyy-MM-dd"
                                                        inline
                                                    />
                                                )
                                            }
                                        </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="row" style={{lineHeight: 3.0, paddingLeft: 60}}>
                                <table style={styles.table}>
                                    <tbody>
                                    <tr>
                                        <td colSpan={3}>
                                            <div className="form-inline mt-3">
                                                เรื่อง&nbsp;
                                                <input type="text" style={styles.inputData}
                                                       name="notice_subject"
                                                       className="form-control ml-5"
                                                       placeholder=""
                                                       value={this.state.notice_subject}
                                                       onChange={this.handleOnChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={3}>
                                            <div className="form-inline">
                                                เรียน
                                                <input type="text" readOnly={true}
                                                       style={styles.inputData}
                                                       name="customer_name2"
                                                       className="form-control ml-5"
                                                       placeholder=""
                                                       value={customer_name2}
                                                />
                                                &nbsp; &nbsp; ผู้เช่าซื้อ
                                                &nbsp;&nbsp;
                                                <div  className="mt-1" data-toggle="tooltip" data-placement="top"
                                                      title="แก้ไขที่เมนูข้อมูลลูกค้า">
                                                    <i className="icon-exclamation"/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={3}>
                                            <div className="form-inline">
                                                อ้างถึง &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;สัญญาเช่าซื้อรถยนต์ ฉบับลงวันที่&nbsp;
                                                <input readOnly={true}
                                                       name="car_sell_date_thai"
                                                       className="form-control ml-4"
                                                       style={{width: 160}}
                                                       value={this.state.car_sell_date_thai === null ? '' : this.state.car_sell_date_thai}
                                                />
                                                <div>
                                                    <DatePicker
                                                        selected={this.state.startSellDate}
                                                        onChange={this.handleSellDate}
                                                        dateFormat="yyyy-MM-dd"
                                                        customInput={<DatePickerIcon />}
                                                    />
                                                </div>
                                                {
                                                    this.state.isOpenSellDate && (
                                                        <DatePicker
                                                            selected={this.state.startSellDate}
                                                            onChange={this.handleSellDate}
                                                            dateFormat="yyyy-MM-dd"
                                                            inline
                                                        />
                                                    )
                                                } &nbsp;&nbsp;
                                                <div  className="mt-1" data-toggle="tooltip" data-placement="top"
                                                      title="วันที่ขายรถยนต์">
                                                    <i className="icon-exclamation"/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={3}>
                                            <div className="form-inline ml-5">
                                                &nbsp; &nbsp; &nbsp; &nbsp; สัญญาค้ำประกัน ฉบับลงวันที่
                                                <input readOnly={true}
                                                       className="form-control ml-5"
                                                       name="date_promise_thai"
                                                       style={{width: 160}}
                                                       onChange={() => {}}
                                                       value={this.state.date_promise_thai === null ? '' : this.state.date_promise_thai}
                                                />
                                                <div>
                                                    <DatePicker
                                                        selected={this.state.startPromiseDate}
                                                        onChange={this.handlePromiseDate}
                                                        dateFormat="yyyy-MM-dd"
                                                        customInput={<DatePickerIcon />}
                                                    />
                                                </div>
                                                {
                                                    this.state.isOpenPromiseDate && (
                                                        <DatePicker
                                                            selected={this.state.startPromiseDate}
                                                            onChange={this.handlePromiseDate}
                                                            dateFormat="yyyy-MM-dd"
                                                            inline
                                                        />
                                                    )
                                                }&nbsp;&nbsp;
                                                <div  className="mt-1" data-toggle="tooltip" data-placement="top"
                                                      title="วันที่ทำสัญญา-รายละเอียดการขาย">
                                                    <i className="icon-exclamation"/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="row mt-3" style={{lineHeight: 3.5, paddingLeft: 60, paddingRight:60}}>
                                <table style={styles.table}>
                                    <tbody>
                                    <tr>
                                        <td colSpan={3}>
                                            <div className="form-inline">
                                            ตามที่ท่านได้เช่าซื้อ รถยนต์คันหมายเลขทะเบียน
                                                <input type="text" style={styles.inputTax}
                                                       readOnly={true}
                                                       name="license_plate"
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                       value={license_plate}
                                                />
                                                ยี่ห้อ
                                                <input type="text" style={styles.inputData2}
                                                       readOnly={true}
                                                       name="car_brand_name"
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                       value={this.state.car_brand_name || ''}
                                                       onChange={this.handleOnChange}
                                                />
                                                แบบ
                                                <input type="text" style={styles.inputData2}
                                                       readOnly={true}
                                                       name="car_model_name"
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                       value={this.state.car_model_name || ''}
                                                       onChange={this.handleOnChange}
                                                />
                                                สี
                                                <input type="text" style={styles.inputData2}
                                                       readOnly={true}
                                                       name="car_color_name"
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                       value={this.state.car_color_name}
                                                       onChange={this.handleOnChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={3}>
                                            <div className="form-inline">
                                            จาก
                                                <input type="text" style={styles.inputDataDetail}
                                                       name="signatory"
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                       value={this.state.signatory || ''}
                                                       onChange={this.handleOnChange}
                                                />&nbsp;
                                                <div  data-toggle="tooltip" data-placement="top"
                                                      title="ข้อมูลจากชื่อผู้มีอำนาจลงนามตามสาขา แก้ไขได้">
                                                    <i className="icon-exclamation"/>
                                                </div>
                                                &nbsp;&nbsp;
                                                ผู้ให้เช่าซื้อ&nbsp;&nbsp;ในราคาเช่าซื้อ
                                                <NumberFormat
                                                    className="form-control ml-2 mr-2"
                                                    decimalScale={2} fixedDecimalScale
                                                    style={styles.inputDataNum}
                                                    thousandSeparator={true}
                                                    prefix={''}
                                                    placeholder=""
                                                    min={0}
                                                    max={1000000000}
                                                    step={1000}
                                                    size={10}
                                                    allowNegative={false}
                                                    value={this.state.amount_of_debt}
                                                    onValueChange = {(values) => {
                                                        const {value} = values;
                                                        this.setState({amount_of_debt: value},()=>{
                                                        })
                                                    }}
                                                />บาท&nbsp;&nbsp;โดยแบ่งชำระเป็นรายงวดจำนวน
                                                <input type="text" style={styles.inputDataNo}
                                                       name="duration_period_month"
                                                       className="form-control ml-2 mr-2 text-center"
                                                       placeholder=""
                                                       value={this.state.duration_period_month || ''}
                                                       onChange={this.handleOnChange}
                                                />&nbsp;&nbsp;งวด
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={3}>
                                            <div className="form-inline">
                                                งวดละ
                                                <NumberFormat
                                                    className="form-control ml-2 mr-2"
                                                    decimalScale={2} fixedDecimalScale
                                                    style={styles.inputDataNum}
                                                    thousandSeparator={true}
                                                    prefix={''}
                                                    placeholder=""
                                                    min={0}
                                                    max={1000000000}
                                                    step={1000}
                                                    size={10}
                                                    allowNegative={false}
                                                    value={this.state.amount_pay_period}
                                                    onValueChange = {(values) => {
                                                        const {value} = values;
                                                        this.setState({amount_pay_period: value},()=>{
                                                        })
                                                    }}
                                                />บาท&nbsp;&nbsp;กำหนดชำระทุกวันที่
                                                <input type="text" style={styles.inputDataNo}
                                                       name="pay_every_day"
                                                       className="form-control ml-2 mr-2 text-center"
                                                       placeholder=""
                                                       value={this.state.pay_every_day || ''}
                                                       onChange={this.handleOnChange}
                                                />ของทุกเดือน&nbsp;&nbsp;
                                                โดยกำหนดชำระงวดแรกวันที่
                                                <input readOnly={true}
                                                       name="date_pay_start_thai"
                                                       className="form-control ml-2"
                                                       style={{width: 160}}
                                                       value={this.state.date_pay_start_thai === null ? '' : this.state.date_pay_start_thai}
                                                />
                                                <div>
                                                    <DatePicker
                                                        selected={this.state.startPayStartDate}
                                                        onChange={this.handlePayStartDate}
                                                        dateFormat="yyyy-MM-dd"
                                                        style={{height: 0}}
                                                        customInput={<DatePickerIcon />}
                                                    />
                                                </div>
                                                {
                                                    this.state.isOpenPayStartDate && (
                                                        <DatePicker
                                                            selected={this.state.startPayStartDate}
                                                            onChange={this.handlePayStartDate}
                                                            dateFormat="yyyy-MM-dd"
                                                            inline
                                                        />
                                                    )
                                                }
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={3}>
                                            <div className="form-inline">
                                                ดังรายละเอียดในสัญญาเช่าซื้อ ตามเอกสารที่อ้างอิง และต่อมาท่านได้ผิดนัดชำระค่าเช่าซื้อตั้งแต่งวดที่
                                                <input type="text" style={styles.inputDataNo}
                                                       name="failure_pay_installment"
                                                       className="form-control ml-2 mr-2 text-center"
                                                       placeholder=""
                                                       value={this.state.failure_pay_installment || ''}
                                                       onChange={this.handleOnChange}
                                                />งวดประจำวันที่
                                                <input readOnly={true}
                                                       name="payment_for_date_thai"
                                                       className="form-control ml-2"
                                                       style={{width: 160}}
                                                       value={this.state.payment_for_date_thai === null ? '' : this.state.payment_for_date_thai}
                                                />
                                                <div>
                                                    <DatePicker
                                                        selected={this.state.startPaymentForDate}
                                                        onChange={this.handlePaymentForDate}
                                                        dateFormat="yyyy-MM-dd"
                                                        customInput={<DatePickerIcon />}
                                                    />
                                                </div>
                                                {
                                                    this.state.isOpenPaymentForDate && (
                                                        <DatePicker
                                                            selected={this.state.startPaymentForDate}
                                                            onChange={this.handlePaymentForDate}
                                                            dateFormat="yyyy-MM-dd"
                                                            inline
                                                        />
                                                    )
                                                }&nbsp;&nbsp; - งวดประจำวันที่
                                                <input readOnly={true}
                                                       name="payment_to_date_thai"
                                                       className="form-control ml-2"
                                                       style={{width: 160}}
                                                       value={this.state.payment_to_date_thai === null ? '' : this.state.payment_to_date_thai}
                                                />
                                                <div>
                                                    <DatePicker
                                                        selected={this.state.startPaymentToDate}
                                                        onChange={this.handlePaymentToDate}
                                                        dateFormat="yyyy-MM-dd"
                                                        customInput={<DatePickerIcon />}
                                                    />
                                                </div>
                                                {
                                                    this.state.isOpenPaymentToDate && (
                                                        <DatePicker
                                                            selected={this.state.startPaymentToDate}
                                                            onChange={this.handlePaymentToDate}
                                                            dateFormat="yyyy-MM-dd"
                                                            inline
                                                        />
                                                    )
                                                }&nbsp;&nbsp;และจนถึงปัจจุบัน เป็นระยะเวลา
                                                <input type="text" style={styles.inputDataNo}
                                                       name="total_period"
                                                       className="form-control ml-2 mr-2 text-center"
                                                       placeholder=""
                                                       value={this.state.total_period || ''}
                                                       onChange={this.handleOnChange}
                                                />งวด ติดต่อกัน ซึ่งรายละเอียดท่านทราบดีอยู่แล้วนั้น
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="row mb-5" style={{lineHeight: 3.5, paddingLeft: 60, paddingRight:60}}>
                                <table style={styles.table}>
                                    <tbody>
                                    <tr>
                                        <td colSpan={3}>
                                            <div className="form-inline">
                                                ฉะนั้นโดยหนังสือฉบับนี้ ข้าพเจ้า
                                                <input type="text" style={styles.inputDataDetail}
                                                       name="notice_creditor"
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                       value={this.state.notice_creditor || ''}
                                                       onChange={this.handleOnChange}
                                                />
                                                จึงมีความประสงค์ให้ท่านชำระเงินค่าเช่าซื้อให้กับ
                                                <input type="text" readOnly={true}
                                                       style={styles.inputDataDetail}
                                                       name="signatory"
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                       value={this.state.signatory}
                                                />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={3}>
                                            <div className="form-inline">
                                                ภายในกำหนด
                                                <input type="text" style={styles.inputDataNo}
                                                       name="within_days"
                                                       className="form-control ml-2 mr-2 text-center"
                                                       placeholder="จน.วัน"
                                                       value={this.state.within_days || ''}
                                                       onChange={this.handleOnChange}
                                                />วัน นับตั้งแต่วันที่ท่านได้รับหนังสือบอกกล่าวฉบับนี้ หากท่านไม่ชำระภายในกำหนดระยะเวลาดังกล่าว
                                                <input type="text" readOnly={true}
                                                       style={styles.inputDataDetail}
                                                       name="signatory"
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                       value={this.state.signatory}
                                                />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={3}>
                                            <div className="form-inline">
                                                ขอถือเอาหนังสือฉบับนี้ เป็นการบอกเลิกสัญญาเช่าซื้อ พร้อมกับให้ท่านส่งมอบรถยนต์ที่เช่าซื้อคืนให้กับ
                                                <input type="text" readOnly={true}
                                                       style={styles.inputDataDetail}
                                                       name="signatory"
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                       value={this.state.signatory}
                                                /> ณ ภูมิลำเนาของ
                                                <input type="text" readOnly={true}
                                                       style={styles.inputDataDetail}
                                                       name="signatory"
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                       value={this.state.signatory}
                                                />ในสภาพเรียบร้อย หากท่านยังคงเพิกเฉยไม่ปฏิบัติตาม
                                                <input type="text" readOnly={true}
                                                       style={styles.inputDataDetail}
                                                       name="signatory"
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                       value={this.state.signatory}
                                                /> มีความจำเป็นที่จะต้องดำเนินการตามขั้นตอนของกฏหมายต่อไป
                                                &nbsp;&nbsp;&nbsp;&nbsp;และหากท่านมีข้อสงสัยหรือมีเหตุประการใดกรุณาติดต่อกลับมาที่
                                                <input type="text" readOnly={true}
                                                       style={styles.inputDataDetail}
                                                       name="signatory"
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                       value={this.state.signatory}
                                                />หมายเลขโทรศัพท์
                                                <input type="text" style={styles.inputDataDetail}
                                                       name="notice_tel_creditor"
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                       value={this.state.notice_tel_creditor || ''}
                                                       onChange={this.handleOnChange}
                                                /> หวังว่าคงได้รับความร่วมมือจากท่านเป็นอย่างดี
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="row mt-3">
                                <table style={styles.table}>
                                    <tbody>
                                    <tr>
                                        <td width={'30%'}>&nbsp;</td>
                                        <td width={'30%'}>&nbsp;</td>
                                        <td className="text-center">ขอแสดงความนับถือ</td>
                                        <td width={'20%'}>&nbsp;</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="row mt-3">
                                <table style={styles.table}>
                                    <tbody>
                                    <tr>
                                        <td width={'30%'}>&nbsp;</td>
                                        <td width={'32%'}>&nbsp;</td>
                                        <td className="text-center">
                                            <input type="text" style={{ ...styles.inputDataDetail}}
                                                   name="notice_payee"
                                                   className="form-control text-center"
                                                   placeholder=""
                                                   value={this.state.notice_payee || ''}
                                                   onChange={this.handleOnChange}
                                            />
                                        </td>
                                        <td width={'20%'}>&nbsp;</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="col-12 text-right mt-3 mb-5">
                                <div className="card-block">
                                    {
                                        this.state.notice_id === null ? (
                                            <button type="button"
                                                    className="btn btn-success btn-md mr-2"
                                                    style={{width: 120}}
                                                    onClick={() => this.SaveDebtorsNotice('add')}
                                            >
                                                <i className="icon-check"/>
                                                &nbsp;บันทึก
                                            </button>
                                        ) : <button type="button"
                                                    className="btn btn-warning btn-md mr-2"
                                                    style={{width: 120}}
                                                    onClick={() => this.SaveDebtorsNotice('edit')}
                                        >
                                            <i className="icon-pencil"/>
                                            &nbsp;แก้ไข
                                        </button>
                                    }

                                    {
                                        this.state.notice_id === null ? null : (
                                            <button type="button"
                                                    className="btn btn-primary btn-md mr-2"
                                                    style={{width: 130}}
                                                    onClick={() => this.printDebtorsBuyer()}
                                            >
                                                <i className="icon-printer"/>  พิมพ์ผู้เช่าซื้อ
                                            </button>

                                        )
                                    }
                                    {
                                        this.state.notice_id === null ? null : (
                                            <button type="button"
                                                    className="btn btn-primary btn-md mr-2"
                                                    style={{width: 120}}
                                                    onClick={() => this.printDebtorsGuarantor1()}
                                            >
                                                <i className="icon-printer"/>  พิมพ์ผู้ค้ำ 1
                                            </button>

                                        )
                                    }
                                    {
                                        this.state.notice_id !== null && this.state.sell_guarantor_name2 !== '' ? (
                                            <button type="button"
                                                    className="btn btn-primary btn-md mr-2"
                                                    style={{width: 120}}
                                                    onClick={() => this.printDebtorsGuarantor2()}
                                            >
                                                <i className="icon-printer"/>  พิมพ์ผู้ค้ำ 2
                                            </button>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


}

const styles = {
    table: {
        width: '100%',
        maxWidth: '100%'
    },
    tdContentCenter: {
        textAlign: 'center',
        verticalAlign: 'middle',
    },
    tdContentRight: {
        textAlign: 'right',
        verticalAlign: 'middle',
        justifyContent: 'right',
    },
    tdContentLeft: {
        textAlign: 'left',
        verticalAlign: 'middle'
    },
    buttonHeight: {
        height: 32,
    },
    inputData:{
        borderRadius: '5px',
        width: '450px',
    },
    inputData2:{
        borderRadius: '5px',
        width: '160px',
    },
    inputTax:{
        borderRadius: '5px',
        width: '220px',
    },
    inputDataNo:{
        borderRadius: '5px',
        width: '60px'
    },
    inputDataNum:{
        borderRadius: '5px',
        width: '156px'
    },
    inputDataDetail:{
        borderRadius: '5px',
        width: '280px',
    },
    overflowY: {
        overflow: 'scroll',
    },
}


const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(DebtorsNotice)