import React, { Component } from 'react'
import { APIURL } from '../../config/config'
import axios from 'axios'
import { AlertSuccess } from '../Alert/Alert'
import Switch from 'react-switch'
import Swal from 'sweetalert2'
import {connect} from "react-redux";

class DurationPeriodSetting extends Component{
  constructor (props) {
    super(props);

    this.state = {
      actionType: "บันทึก",
      duration_period_list: [],
      duration_period_id: '',
      duration_period_name: '',
      duration_period_month: '',
      user: {} ,
    }

    this.loadDurationPeriodData = this.loadDurationPeriodData.bind(this);
    this.saveDurationPeriod = this.saveDurationPeriod.bind(this);
    this.setEditForm = this.setEditForm.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.onChangeSwitch = this.onChangeSwitch.bind(this);
    this.deleteDurationPeriod = this.deleteDurationPeriod.bind(this);
  }

  loadDurationPeriodData () {
    let url = APIURL+'/duration_period'
    axios.get(url).then(res => {
      this.setState({
        duration_period_list: res.data
      })
    })
  }

  deleteDurationPeriod(duration_period){
    let duration_period_id = duration_period.duration_period_id
    let duration_period_name = duration_period.duration_period_name

    let data = {
      duration_period_id: duration_period_id,
      status_field: 'delete'
    }

    Swal.fire({
      title: 'ยืนยันการลบ '+duration_period_name+' ?',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        let url = APIURL+'/duration_period/status'
        axios.post(url, data).then(res => {
          if(res.data === 'success') {
            AlertSuccess('ลบข้อมูลแล้ว')
            this.loadDurationPeriodData()
          }
        })
      }
    });

  }

  saveDurationPeriod() {
    //let username = this.state.user.user
    let actionType = this.state.actionType
    let duration_period_name = this.state.duration_period_name
    let duration_period_month = this.state.duration_period_month

    let data= {
      duration_period_name,
      duration_period_month,
      user_created: this.state.user.user,
      user_updated: this.state.user.user
    }

    if(actionType === "บันทึก") {
      let url = APIURL+'/duration_period'
      axios.post(url, data).then(res => {
        if(res.data === 'success') {
          AlertSuccess('บันทึกข้อมูลแล้ว')
          this.clearForm()
          this.loadDurationPeriodData()
        }
      })
    } else if (actionType === "แก้ไข") {
      let duration_period_id = this.state.duration_period_id
      data = {...data, duration_period_id: duration_period_id }
      let url = APIURL+'/duration_period/'+duration_period_id
      axios.put(url, data).then(res => {
        if(res.data === 'success') {
          AlertSuccess('แก้ไขข้อมูลแล้ว')
          this.clearForm()
          this.loadDurationPeriodData()
        }
      })
    }
  }

  setEditForm(duration_period) {
    this.setState({
      actionType: 'แก้ไข',
      duration_period_name: duration_period.duration_period_name,
      duration_period_month: duration_period.duration_period_month,
      duration_period_id: duration_period.duration_period_id,
    })
  }

  clearForm() {
    this.setState({
      actionType: "บันทึก",
      duration_period_name: '',
      duration_period_month: '',
      duration_period_id: '',
    })
  }

  onChangeSwitch(checked, duration_period){
    //let username = this.props.username
    let item_update = duration_period
    let duration_periods = this.state.duration_period_list
    let item_index = this.state.duration_period_list.findIndex(data => data.duration_period_id=== duration_period.duration_period_id)

    if(checked === false){
      item_update.status_field = 'hidden'
    } else {
      item_update.status_field = 'show'
    }

    let data = {
      duration_period_id: item_update.duration_period_id,
      status_field: item_update.status_field
      //username: username
    }

    let url = APIURL+'/duration_period/status'
      axios.post(url, data).then(res => {
    })

    duration_periods[item_index] = item_update
    this.setState({
      duration_period_list: duration_periods
    })
  }

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user)
    });
  }

  componentDidMount () {
    this.loadDurationPeriodData()
  }

  render () {

    return (
      <div className="row">
        <div className="col-sm-7">
          <div className="card card-accent-info">
            <div className="card-header">
              <strong className="text-title">
                จำนวนงวดที่ลูกค้าต้องชำระ
              </strong>
            </div>
            <div className="mr-5 mt-3 text-right"><span className="text-danger">กดปิดการใช้งาน หากไม่ต้องการแสดงรายการ</span></div>

            <div className="card-block" style={styles.overflowY}>
              <table className="table table-striped table-responsive">
                <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th className="text-center text-nowrap">จำนวนงวด</th>
                  <th className="text-center">ใช้งาน</th>
                  <th className="text-center">จัดการ</th>
                </tr>
                </thead>
                <tbody>
                {
                  this.state.duration_period_list.map((duration_period, index) => (
                    <tr key={index}>
                      <td className="text-center" style={{width: 10}}>{ index+1 }</td>
                      <td className="text-center" style={{width: 100}}>{ duration_period.duration_period_name }</td>
                      <td className="text-nowrap text-center" style={{width: 80}}>

                        { duration_period.status_field === 'show' ? 'ใช้งาน' : 'ไม่ใช้งาน' }

                        <br/>

                        <Switch height = {20} width ={48}
                                onChange={(checked) => this.onChangeSwitch(checked, duration_period)}
                                checked={duration_period.status_field === 'show'}
                        />

                      </td>
                      <td className="text-center" style={{width: 120}}>
                        <button type="button"
                                className="btn btn-sm btn-warning btn-md-width mr-2 mb-2"
                                onClick={() => this.setEditForm(duration_period)}
                        >
                          <i className="fa fa-edit"/> แก้ไข
                        </button>
                              {/*<button type="button"
                                      className="btn btn-sm btn-danger btn-md-width mr-2 mb-2"
                                      onClick={() => this.deleteDurationPeriod(duration_period)}
                              >
                                <i className="fa fa-remove"/> ลบ
                              </button>*/}
                      </td>
                    </tr>
                  ))
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-sm-5">
          <div className="card card-accent-success">
            <div className="card-header card-customer">
              <strong className="text-title">เพิ่ม/แก้ไขจำนวนงวด</strong>
            </div>

            <div className="card-block">
              <div className="form-group">
                <label htmlFor="duration_period_name">ชื่อรายการจำนวนงวด</label>
                <input
                  value={this.state.duration_period_name}
                  type="text"
                  className="form-control"
                  name="duration_period_name"
                  placeholder="ตัวอย่าง เช่น 15 งวด"
                  style={styles.inputSearch}
                  onChange={(e) => {
                    this.setState({
                      duration_period_name: e.target.value
                    })
                  }}
                />
              </div>

              <div className="form-group">
                <label htmlFor="duration_period_name">จำนวนงวด (กรอกตัวเลข)</label>
                <input
                    value={this.state.duration_period_month}
                    type="number"
                    className="form-control"
                    name="duration_period_name"
                    placeholder="ตัวอย่าง เช่น 15"
                    style={styles.inputSearch}
                    onChange={(e) => {
                      this.setState({
                        duration_period_month: e.target.value
                      })
                    }}
                />
              </div>
            </div>

            <div className="card-footer text-right">
              <button type="button"
                      className="btn btn-sm btn-danger mr-2"
                      style={styles.button}
                      onClick={()=> this.clearForm()}
              >
                <i className="fa fa-refresh"/> ยกเลิก
              </button>

              <button type="button"
                      className={this.state.actionType==="แก้ไข"?  "btn btn-sm btn-warning" : "btn btn-sm btn-primary"}
                      style={styles.button}
                      onClick={() => {
                        this.saveDurationPeriod()
                      }}
              >
                <i className={this.state.actionType === "แก้ไข" ? "fa fa-edit" : "fa fa-save"}/> {this.state.actionType}
              </button>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})


const styles = {
  button: {
    borderRadius:5,
    height: 36,
  },
  inputSearch: {
    borderRadius:5,
  },
  overflowY: {
    overflow: 'scroll',
  }
}

export default connect(mapStateToProps)(DurationPeriodSetting)
