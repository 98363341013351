import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import axios from "axios";
import {APIURL} from "../../config/config";
import {AlertError, AlertSuccess} from "../Alert/Alert";
import BDatePicker from "../BDatePicker";

class CustomerInstallmentHistoryForm extends Component{
    constructor (props) {
        super(props)

        this.state = {
            customer_list: [],
            customer_select: false,
            customer: {},
            customer_id: '',
            bank: [],
            installment_history: [],
            finance_id: 0,
            installment_history_id: 0,
            finance_score: '',
            finance_credit: '',

            credit_options: [],
            credit_types: [],
            credit_periods: [],
            credit_option_id: 0,
            credit_type_id: 0,
            credit_period_id: 0,
            date_oneyear_credit: null,
        }

        this.searchCustomer = this.searchCustomer.bind(this);
        this.getDataCustomerSearch = this.getDataCustomerSearch.bind(this);
        this.onCustomerSelect = this.onCustomerSelect.bind(this);
        this.loadBankDataIsLeasing = this.loadBankDataIsLeasing.bind(this);
        this.onSubmitCustomerInstallmentHistory = this.onSubmitCustomerInstallmentHistory.bind(this);
        this.loadCustomerInstallmentHistoryOptions = this.loadCustomerInstallmentHistoryOptions.bind(this);
        this.isFillData = this.isFillData.bind(this);
    }

    componentDidMount () {
        this.loadBankDataIsLeasing()
        this.loadInstallmentHistory()
        this.loadCustomerInstallmentHistoryOptions()

        let form_status = this.props.form_status
        if(form_status === 'edit'){
            let history_edit = this.props.customer_installment_history_edit
            let customer = {
                customer_id: history_edit.customer_id,
                customer_name: history_edit.customer_name,
                customer_lastname: history_edit.customer_lastname,
                customer_mobile: history_edit.customer_mobile,
                customer_card_id: history_edit.customer_card_id,
                customer_address: history_edit.customer_address,
                // credit_option_id: history_edit.credit_option_id,
                // credit_type_id: history_edit.credit_type_id,
                // credit_period_id: history_edit.credit_period_id,
                // date_oneyear_credit: history_edit.date_oneyear_credit,
            };
            this.setState({
                customer_id: history_edit.customer_id,
                finance_id: history_edit.finance_id,
                installment_history_id: history_edit.installment_history_id,
                finance_score: history_edit.finance_score,
                finance_credit: history_edit.finance_credit,
                credit_option_id: history_edit.credit_option_id,
                credit_type_id: history_edit.credit_type_id,
                credit_period_id: history_edit.credit_period_id,
                date_oneyear_credit: history_edit.date_oneyear_credit,
                customer_select: true,
                customer: customer
            });
        } else {
            // add
            let customer = this.props.customer
            this.setState({
                customer_select: true,
                customer: customer,
                customer_id: customer.customer_id,
            })
        }
    }

    searchCustomer () {
        let search_text = this.refs.search_customer_name.value
        //alert(search_text);
        this.getDataCustomerSearch(search_text)
    }

    getDataCustomerSearch (search_text) {
        axios.get(APIURL + '/customer/search/' + search_text)
            .then(res => {
                this.setState({customer_list: res.data})
                // alert (res.data)
            }).catch(error => {
            this.setState({customer_list: []})
        })
    }

    onCustomerSelect (customer, event) {
        this.setState({
            customer_select: true,
            customer: customer,
            customer_id: customer.customer_id,
            customer_list: []
        });
    }

    loadBankDataIsLeasing () {
        // axios.get(APIURL + '/bank/')
        axios.get(APIURL + '/bank/get/is_leasing')
            .then(res => {
                this.setState({bank: res.data})
                //alert (res.data)
            }).catch(error => {
            this.setState({bank: []})
        })
    }

    loadCustomerInstallmentHistoryOptions(){
        axios.get(APIURL + '/customer_installment_history/option/data')
            .then(res => {
                // this.setState({installment_history: res.data})
                let data = res.data
                let credit_options = data.credit_options
                let credit_types = data.credit_types
                let credit_periods = data.credit_periods
                this.setState({
                    credit_options,
                    credit_types,
                    credit_periods
                })

            }).catch(error => {
                this.setState({
                    credit_options: [],
                    credit_types: [],
                    credit_periods: [],
                })
        })
    }

    loadInstallmentHistory(){
        axios.get(APIURL + '/installment_history')
            .then(res => {
                this.setState({installment_history: res.data})
                //alert (res.data)
            }).catch(error => {
            this.setState({installment_history: []})
        })
    }

    isFillData(data) {
        let emptyData = {
            "finance_id": 0,
            "installment_history_id": 0,
            "finance_score": "",
            "finance_credit": "",
            "credit_option_id": 0,
            "credit_type_id": 0,
            "credit_period_id": 0,
            "date_oneyear_credit": null
        }

        // delete data.customer_id
        // delete data.username

        let strEmpty = JSON.stringify(emptyData)
        let strNew = JSON.stringify(data)

        return strEmpty !== strNew

    }

    onSubmitCustomerInstallmentHistory(e) {
        e.preventDefault();

        let finance_id = this.state.finance_id
        let customer_id = this.state.customer_id
        let installment_history_id = this.state.installment_history_id
        let finance_score = this.state.finance_score
        let finance_credit = this.state.finance_credit
        let username = this.props.username

        // carok
        let credit_option_id =  this.state.credit_option_id
        let credit_type_id =  this.state.credit_type_id
        let credit_period_id =  this.state.credit_period_id
        let date_oneyear_credit =  this.state.date_oneyear_credit

        if(customer_id === '') {
            AlertError('กรุณาเลือกลูกค้า');
            return
        }

        /*if(finance_id === 0) {
            AlertError('กรุณาเลือกไฟแนนซ์');
            return
        }

        if(installment_history_id === 0) {
            AlertError('กรุณาเลือกประวัติการผ่อน');
            return
        }*/

        let data = {
            finance_id,
            installment_history_id,
            finance_score,
            finance_credit,
            credit_option_id,
            credit_type_id,
            credit_period_id,
            date_oneyear_credit
        }

        if (!this.isFillData(data)) {
            AlertError('กรุณากรอกข้อมูล');
            return;
        }

        data = {...data, customer_id: customer_id, username: username}

        let form_status = this.props.form_status

        if(form_status === 'add'){
            let url = APIURL +'/customer_installment_history'
            axios.post(url, data).then(res => {
                let result = res.data;
                if(result === 'success') {
                    AlertSuccess('บันทึกข้อมูลเรียบร้อยแล้ว');
                    this.props.closeAndReload()
                }
            })
        } else if(form_status === 'edit') {
            let customer_installment_history_id = this.props.customer_installment_history_id
            data = {...data, customer_installment_history_id: customer_installment_history_id}
            let url = APIURL +'/customer_installment_history'
            axios.put(url, data).then(res => {
                let result = res.data;
                if(result === 'success') {
                    AlertSuccess('แก้ไขบ้อมูลเรียบร้อยแล้ว');
                    this.props.closeAndReload()
                }
            })
        }

    }

    render() {
        let width = this.props.width
        const {
            credit_options,
            credit_types,
            credit_periods
        } = this.state

        let search_customer_list = this.state.customer_list.map((customer_list, index) => {
            return (
                <tr key={index}>
                    <td><strong>ชื่อ</strong> &nbsp;&nbsp; {customer_list.customer_name}</td>
                    <td><strong>นามสกุล</strong> &nbsp;&nbsp; {customer_list.customer_lastname}</td>
                    <td><strong>เลขประจำตัวประชาชน</strong> &nbsp;&nbsp; {customer_list.customer_card_id}</td>
                    <td><strong>เบอร์โทรศัพท์</strong> &nbsp;&nbsp; {customer_list.customer_mobile}</td>
                    <td>
                        <button onClick={() => this.onCustomerSelect(customer_list, this)} type="button"
                                className="btn btn-primary">
                            <i className="fa fa-hand-pointer-o"/>&nbsp;&nbsp; เลือก
                        </button>
                    </td>
                </tr>
            )
        });

        let bank_finance_list = this.state.bank.map((bank, index) => {
            return (
                <option key={index} value={bank.bank_id}>{bank.bank_name}</option>
            )
        });

        let installment_history_list = this.state.installment_history.map((installment_history, index) => {
            return (
                <option key={index} value={installment_history.installment_history_id}>{installment_history.installment_history_name}</option>
            )
        });

        let credit_option_list = credit_options.map((c, index) => {
            return (
                <option key={index} value={c.credit_option_id}>{c.credit_option_name}</option>
            )
        });

        let credit_type_list = credit_types.map((c, index) => {
            return (
                <option key={index} value={c.credit_type_id}>{c.credit_type_name}</option>
            )
        });

        let credit_period_list = credit_periods.map((c, index) => {
            return (
                <option key={index} value={c.credit_period_id}>{c.credit_period_name}</option>
            )
        });

        return (
            <>
             <Modal
                 isOpen={this.props.is_modal}
                 toggle={this.props.toggle}
                 className={'modal-lg '}
                 style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
             >
                 <ModalBody>
                     <div className="card card-accent-primary ">
                         <div className="card-header">
                             <strong className="text-title">
                                 {this.props.form_status==='add'? ' เพิ่ม' : ' แก้ไข'}ประวัติการผ่อน(เครดิตลูกค้า)
                             </strong>
                         </div>

                         <form onSubmit={this.onSubmitCustomerInstallmentHistory}>

                             <div className="card-block">

                             <div className={this.state.customer_select === true ? 'hidden' : 'row'}>
                                 <div className="controls col-md-12">
                                     <div className="input-group has-success ">
                                         <input
                                             ref="search_customer_name"
                                             placeholder="ค้นหาชื่อลูกค้า"
                                             className="form-control"
                                             size="16"
                                             type="text"/>

                                         <span className="input-group-btn ">
                                   <button className="btn btn-success"
                                           type="button"
                                           onClick={this.searchCustomer.bind(this)}>ค้นหา
                                   </button>
                              </span>
                                     </div>
                                     <div id="show_customer" className="row">
                                         <div className="controls col-md-12">
                                             <table className="table table-striped">
                                                 <tbody>
                                                 {search_customer_list}
                                                 </tbody>
                                             </table>
                                         </div>
                                     </div>
                                 </div>
                             </div>

                             <div className={this.state.customer_select ? 'row ' : ' hidden'}>

                                 <div className="card-header controls col-md-12 hidden ">
                                     <strong className="text-title">ข้อมูลลูกค้า</strong>
                                 </div>

                                 <div className="card-block has-success">
                                     <div className="row">
                                         <div className="col-lg-12">
                                             <div className=" form-group row" style={styles.bottomLine}>
                                                 <label className="col-md-3"><strong> ชื่อลูกค้า </strong></label>
                                                 <div className="col-md-3">
                                                     <p className="text-justify text-success ">
                                                         <strong>{this.state.customer.customer_name}</strong></p>
                                                 </div>

                                                 <label className="col-md-3"><strong> นามสกุล </strong></label>
                                                 <div className="col-md-3">
                                                     <p className="text-justify text-success">
                                                         <strong> {this.state.customer.customer_lastname} </strong></p>
                                                 </div>
                                             </div>
                                         </div>

                                         <div className="col-lg-12">
                                             <div className=" form-group row" style={styles.bottomLine}>
                                                 <label className="col-md-3"><strong>
                                                     เลขบัตรประชาชน </strong></label>
                                                 <div className="col-md-3">
                                                     <p className="text-justify text-success">
                                                         <strong>{this.state.customer.customer_card_id}</strong></p>
                                                 </div>

                                                 <label className="col-md-3"><strong>
                                                     เบอร์โทรศัพท์ </strong></label>
                                                 <div className="col-md-3">
                                                     <p className="text-justify text-success">
                                                         <strong> {this.state.customer.customer_mobile} </strong></p>
                                                 </div>
                                             </div>
                                         </div>

                                         <div className="col-lg-12">
                                             <div className=" form-group row" style={styles.bottomLine}>
                                                 <label className="col-md-3"><strong>
                                                     ที่อยู่ปัจจุบัน </strong></label>
                                                 <div className="col-md-9">
                                                     <p className="text-justify text-success">
                                                         <strong>{this.state.customer.customer_address}</strong></p>
                                                 </div>
                                             </div>
                                         </div>

                                     </div>
                                 </div>
                             </div>


                             <div className="row">

                                 <div className="form-group col-md-3">
                                     <label htmlFor="credit_option_id">เครดิตลูกค้า</label>
                                     <select className="form-control"
                                             name="credit_option_id"
                                             value={this.state.credit_option_id}
                                             onChange={(e) => {
                                                 this.setState({
                                                     credit_option_id: e.target.value
                                                 })
                                             }}
                                     >
                                         {credit_option_list}
                                     </select>
                                 </div>

                                 <div className="form-group col-md-3">
                                     <label htmlFor="credit_type_id">ประเภทเครดิต</label>
                                     <select className="form-control"
                                             name="credit_type_id"
                                             value={this.state.credit_type_id}
                                             onChange={(e) => {
                                                 this.setState({
                                                     credit_type_id: e.target.value
                                                 })
                                             }}
                                     >
                                         {credit_type_list}
                                     </select>
                                 </div>

                                 <div className="form-group col-md-3">
                                     <label htmlFor="credit_period_id">ระยะเวลาเครดิต</label>
                                     <select className="form-control"
                                             name="credit_period_id"
                                             value={this.state.credit_period_id}
                                             onChange={(e) => {
                                                 this.setState({
                                                     credit_period_id: e.target.value
                                                 })
                                             }}
                                     >
                                         {credit_period_list}
                                     </select>
                                 </div>



                                 <div className="form-group col-sm-3">
                                     <BDatePicker
                                         isDateFill={0}
                                         name={'date_oneyear_credit'}
                                         value={this.state.date_oneyear_credit}
                                         label={'วันที่เครดิตครบ 1 ปี'}
                                         onUpdateDate={(buddhist_date, christ_date) => {
                                             this.setState({
                                                 date_oneyear_credit: christ_date
                                             })
                                         }}
                                     />
                                 </div>

                                 <div className="form-group col-md-6">
                                     <label htmlFor="finance_id">ชื่อไฟแนนซ์</label>
                                     <select className="form-control"
                                             name="finance_id"
                                             value={this.state.finance_id}
                                            onChange={(e) => {
                                                this.setState({
                                                    finance_id: e.target.value
                                                })
                                            }}
                                     >
                                         <option key={0} value={0}>--กรุณาเลือก--</option>
                                         {bank_finance_list}
                                     </select>
                                 </div>
                                 <div className="form-group col-md-6">
                                     <label htmlFor="installment_history_id">ประวัติการผ่อน </label>
                                     <select className="form-control"
                                             name="installment_history_id"
                                             value={this.state.installment_history_id}
                                             onChange={(e) => {
                                                 this.setState({
                                                     installment_history_id: e.target.value
                                                 })
                                             }}
                                     >
                                         <option key={0} value={0}>--กรุณาเลือก--</option>
                                         {installment_history_list}
                                     </select>
                                 </div>
                             </div>

                             <div className="row">
                                 <div className="form-group col-md-6">
                                     <label htmlFor="finance_score">สกอร์ไฟแนนซ์ </label>
                                     <input type="text"
                                            className="form-control"
                                            name="finance_score"
                                            placeholder=""
                                            maxLength={50}
                                            value={this.state.finance_score}
                                            onChange={(e) => {
                                                this.setState({
                                                    finance_score: e.target.value
                                                })
                                            }}
                                     />
                                 </div>
                                 <div className="form-group col-md-6">
                                     <label htmlFor="finance_score">เครดิตปัจจุบัน </label>
                                     <input type="text"
                                            className="form-control"
                                            name="finance_credit"
                                            placeholder=""
                                            maxLength={50}
                                            value={this.state.finance_credit}
                                            onChange={(e) => {
                                                this.setState({
                                                    finance_credit: e.target.value
                                                })
                                            }}
                                     />
                                 </div>
                             </div>

                         </div>

                             <div className="card-footer text-right">

                                 <button type="button"
                                         onClick={()=> this.props.toggle()}
                                         className="btn btn-danger mr-2">
                                     <i className="fa fa-close"/>  &nbsp;&nbsp; ปิด &nbsp;&nbsp;
                                 </button>

                                 {
                                     this.props.form_status === 'add' ?
                                         <button type="submit" className="btn btn-success">
                                             <i className="fa fa-save"/> &nbsp;&nbsp; เพิ่ม &nbsp;&nbsp;
                                         </button>
                                         :
                                         <button type="submit" className="btn btn-warning">
                                             <i className="fa fa-pencil"/> &nbsp;&nbsp; แก้ไข &nbsp;&nbsp;
                                         </button>
                                 }

                             </div>
                         </form>
                     </div>
                 </ModalBody>
             </Modal>
            </>
        )
    }
}

const styles = {
    bottomLine: {
        borderBottomColor: '#ccc',
        borderBottomStyle: 'solid',
        borderWidth: 0.5,
    }
}

export default CustomerInstallmentHistoryForm
