import React , { Component } from 'react';
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import {connect} from "react-redux";
import PriceList from "./PriceList";

class PriceListTabs extends Component{
    constructor (props) {
        super(props);
        this.state = {
            activeTab: '1',
            user: JSON.parse(this.props.user)
        }

    }

    render() {
        return (
            <div className="col-md-12 mb-4">

                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '1' })}
                            onClick={() => { this.toggle('1'); }}
                        >
                            <i className="icons-list"/> Price List
                        </NavLink>
                    </NavItem>

                </Nav>

                <TabContent activeTab={this.state.activeTab}>

                    <TabPane tabId="1">
                       <PriceList />
                    </TabPane>

                </TabContent>


            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(PriceListTabs)