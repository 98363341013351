import React, { Component } from 'react';
import { Progress, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import {APIURL, PORT} from '../../config/config';
import CalendarList from '../../components/Calendar/CalendarList';
import ProfileBySale from '../../components/Profile/ProfileBySale';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux';
import { AlertError } from '../../components/Alert/Alert';
import CurrentOutstandingCarList from '../../components/Dashboard/CurrentOutstandingCarList';
import CurrentCarSummary from '../../components/Dashboard/CurrentCarSummary';
import { CarSellUtil, CarsUtil } from '../../utils/carSellUtil';
import {format} from 'date-fns';
import CurrentCarPersonVat from "../../components/Dashboard/CurrentCarPersonVat";

// import Charts from '../Charts'
// import * as moment from 'moment';
// import 'moment/locale/th';
// Random Numbers
// import { Bar, Line } from 'react-chartjs-2';
// Dropdown, DropdownMenu, DropdownItem,
// import { Progress } from 'reactstrap'

function random (min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

let elements = 27
let data1 = []
let data2 = []
let data3 = []

for (var i = 0; i <= elements; i++) {
  data1.push(random(50, 200))
  data2.push(random(80, 100))
  data3.push(65)
}


class Dashboard extends Component {

  constructor (props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      car_status_lists: [],
      car_pay_list: [],
      expense_list: [],
      car_book_count: 0,
      car_sell_count: 0,
      car_pay_sum: 0,
      expense_sum: 0,
      car_repair_sum: 0,
      report_by: 'today',
      dropdownOpen: false,
      token: localStorage.getItem('token'),
      user: {},
      user_type: '',
      // profile_lists: [],
      // customer_docs: [],
      accountSale: [],
      selectSale: {
        id: '',
        user: ''
      },
      dateStart: new Date(),
      dateEnd: new Date(),
      // all
      car_status_all: [],
      car_status_all_sum: 0,
      car_branch_all: [],
      car_branch_all_sum: 0,
      activeTab: '2'
    }

    this.onSelectSale = this.onSelectSale.bind(this);
    this.onSelectSaleHidden = this.onSelectSaleHidden.bind(this);
    this.hiddenAlert = this.hiddenAlert.bind(this);

    this.loadReportCarStatus = this.loadReportCarStatus.bind(this);
    this.loadReportCarSellPay = this.loadReportCarSellPay.bind(this);
    this.handleDateStart = this.handleDateStart.bind(this);
    this.handleDateEnd = this.handleDateEnd.bind(this);
    this.reportByDate = this.reportByDate.bind(this);

    this.loadReportCarStatusByDate = this.loadReportCarStatusByDate.bind(this);
    this.loadReportCarSellPayByDate = this.loadReportCarSellPayByDate.bind(this);
    this.loadReportExpenseByDate = this.loadReportExpenseByDate.bind(this);
    this.loadReportCarRepairSumByDate = this.loadReportCarRepairSumByDate.bind(this);
    this.loadReportCarStatusAll = this.loadReportCarStatusAll.bind(this);
    this.getReportAll = this.getReportAll.bind(this);
    this.loadReportCarBranchAll = this.loadReportCarBranchAll.bind(this);
    this.getRandomRgb = this.getRandomRgb.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  handleDateStart(date) {
    this.setState({
      dateStart: new Date(date)
    })
  }

  handleDateEnd(date) {
    this.setState({
      dateEnd: new Date(date)
    })
  }

  componentDidMount () {
    CarsUtil.removeCarSelect()
    CarSellUtil.removeCarSellSelect()

    this.mounted = true
    let user = JSON.parse(this.props.user);
    if(PORT === 9056) {
      // 365car
      if (user.type !== 'admin' && user.type !== 'account') {
        this.props.history.push('/cars/list/1');
      }
    }else {
      if (user.type !== 'admin' && user.type !== 'manager') {
        this.props.history.push('/cars/list/1');
      }
    }

    // var user = JSON.parse(this.props.user);
    this.setState({
      user: user ,
      user_type : user.type
    }, ()=> {
      //this.getReportDataBy('today')
      this.reportByDate(true)
      // TODx : const firstDateOfMonth = format(date, 'yyyy-MM-01')
    });

    this.loadCustomerProfile();
    this.loadCustomerDoc();
    this.loadAccountSale();

    this.getReportAll();

  }

  componentWillUnmount() {
    this.mounted = false
  }

  reportByDate(is_first_load) {
    let start = null
    let end = null
    if (is_first_load === true){
      let today = new Date()
      start = format(today, 'yyyy-MM-01')
      end = format(today, 'yyyy-MM-dd')

      // console.log('start', start)
      // console.log('end', end)

      this.setState({
        dateStart : new  Date(start),
        dateEnd : new  Date(end),
      })

    }else {
      start = moment(this.state.dateStart).format('YYYY-MM-DD');
      end =  moment(this.state.dateEnd).format('YYYY-MM-DD');
    }


    let diff = moment(this.state.dateStart).diff(this.state.dateEnd, 'days');

    if(diff <=0 ){ // ok
      this.setState({report_by: 'between'}, () => {
        this.loadReportCarStatusByDate(start, end)
        this.loadReportCarSellPayByDate(start, end)
        this.loadReportExpenseByDate(start, end)
        this.loadReportCarRepairSumByDate(start, end)
      });
    }else {
      AlertError('วันที่เริ่มต้น/วันที่สิ้นสุดไม่ถูกต้อง');
    }
  }

  //start bydate
  loadReportCarStatusByDate(start, end){

    axios.get(APIURL + '/report_date/car_status/' + start+'/'+end)
      .then(res => {
        this.setState({
          car_sell_count: res.data.car_sell_count,
          car_book_count: res.data.car_book_count,
          car_status_lists: res.data.car_status_lists,
        })
        //this.setState({car_status_lists: res.data}, () => {
          // eslint-disable-next-line
          /*this.state.car_status_lists.map((car_status) => {
            if (car_status.car_status_id === 7) {//จอง
              this.setState({
                car_book_count: car_status.car_count
              })
            } else if (car_status.car_status_id === 9) {//ขายแล้ว
              this.setState({
                car_sell_count: car_status.car_count
              })
            }
          })*/
        //})

      })
  }

  loadReportCarSellPayByDate (start, end) {
    axios.get(APIURL + '/report_date/car_sell_pay/' + start+'/'+end)
      .then(res => {
        this.setState({car_pay_list: res.data}, () => {
          if (this.state.car_pay_list.length > 0) {
            var car_pay_sum = this.state.car_pay_list
              .map(car_pay => car_pay.car_sell_sum)
              .reduce((prev, car_sell_sum) => prev + car_sell_sum);
            this.setState({car_pay_sum: car_pay_sum})
          }else{
            this.setState({car_pay_sum: 0})
          }
        })
      }).catch(error => {
      this.setState({car_pay_list: []})
    })
  }

  loadReportExpenseByDate (start, end) {
    axios.get(APIURL + '/report_date/expense/' + start+'/'+end)
      .then(res => {
        this.setState({expense_list: res.data}, () => {
          if (this.state.expense_list.length > 0) {
            let sum = this.state.expense_list.map(expense => expense.expense_sum).reduce((prev, expense_sum) => prev + expense_sum)
            this.setState({expense_sum: sum})
          }else{
            this.setState({expense_sum: 0})
          }
        })
      }).catch(error => {
      this.setState({expense_list: []})
    })
  }

  loadReportCarRepairSumByDate(start, end) {
    axios.get(APIURL + '/report_date/car_repair/' + start+'/'+end)
      .then(res => {
        this.setState({car_repair_list: res.data}, () => {
          if (this.state.car_repair_list.length > 0) {

            var sum = this.state.car_repair_list.map(car_repair => car_repair.car_repair_sum).reduce((prev, car_repair_sum) => prev + car_repair_sum)

            this.setState({car_repair_sum: sum})
          }else{
            this.setState({car_repair_sum: 0})
          }
        })
      }).catch(error => {
      this.setState({car_repair_list: []})
    })
  }

  //end

  loadReportCarStatus () {
    axios.get(APIURL + '/report/car_status/' + this.state.report_by)
      .then(res => {
        this.setState({
          car_sell_count: res.data.car_sell_count,
          car_book_count: res.data.car_book_count,
          car_status_lists: res.data.car_status_lists,
        })

        /*this.setState({car_status_lists: res.data}, () => {
          // eslint-disable-next-line
          this.state.car_status_lists.map((car_status) => {
            if (car_status.car_status_id === 7) {//จอง
              this.setState({
                car_book_count: car_status.car_count
              })
            } else if (car_status.car_status_id === 9) {//ขายแล้ว
              this.setState({
                car_sell_count: car_status.car_count
              })
            }
          })
        })*/

      }).catch(error => {
      this.setState({car_status_lists: []})
    })
  }

  loadReportCarSellPay () { // รายรับ
    axios.get(APIURL + '/report/car_sell_pay/' + this.state.report_by)
      .then(res => {
        this.setState({car_pay_list: res.data}, () => {
          if (this.state.car_pay_list.length > 0) {

            var car_pay_sum = this.state.car_pay_list
              .map(car_pay => car_pay.car_sell_sum)
              .reduce((prev, car_sell_sum) => prev + car_sell_sum);

            this.setState({car_pay_sum: car_pay_sum})

          }else{
            this.setState({car_pay_sum: 0})
          }
        })
      }).catch(error => {
      this.setState({car_pay_list: []})
    })
  }

  loadReportExpense () {
    axios.get(APIURL + '/report/expense/' + this.state.report_by)
      .then(res => {
        this.setState({expense_list: res.data}, () => {
          if (this.state.expense_list.length > 0) {
            let sum = this.state.expense_list.map(expense => expense.expense_sum).reduce((prev, expense_sum) => prev + expense_sum)
            this.setState({expense_sum: sum})
          }else{
            this.setState({expense_sum: 0})
          }
        })
      }).catch(error => {
        this.setState({expense_list: []})
    })
  }

  //car_repair_sum
  loadReportCarRepairSum () {
    axios.get(APIURL + '/report/car_repair/' + this.state.report_by)
      .then(res => {
        this.setState({car_repair_list: res.data}, () => {
          if (this.state.car_repair_list.length > 0) {

            var sum = this.state.car_repair_list.map(car_repair => car_repair.car_repair_sum).reduce((prev, car_repair_sum) => prev + car_repair_sum)

            this.setState({car_repair_sum: sum})
          }else{
            this.setState({car_repair_sum: 0})
          }
        })
      }).catch(error => {
      this.setState({car_repair_list: []})
    })
  }

  getReportByName () {
    let by = this.state.report_by
    if (by === 'today') {
      return 'ข้อมูลวันนี้'
    } else if (by === '7day') {
      return 'ข้อมูล 7 วัน'
    } else if (by === 'month') {
      return 'ข้อมูล 30 วัน'
    } else if (by === '3month') {
      return 'ข้อมูล 3 เดือน'
    } else if(by === 'between') {
      let start = moment(this.state.dateStart).format('DD MMM YYYY');
      let end =  moment(this.state.dateEnd).format('DD MMM YYYY');
      return 'ข้อมูล '+start+ ' ถึง '+end;
    }
  }

  getReportAll (by) {
    this.loadReportCarStatusAll()
    this.loadReportCarBranchAll()
  }

  loadReportCarStatusAll() {
    // car_branch_all
    axios.get(APIURL + '/report/car_status_all')
      .then(res => {
         // console.log('---loadReportCarStatusAll')
         // console.log(res.data)
        let car_status_all_sum =  res.data.reduce((acc, next) => acc + next.car_count, 0)
        this.setState({
          car_status_all: res.data,
          car_status_all_sum: car_status_all_sum
        })
      }).catch(error => {
      this.setState({car_status_all: []})
    })
  }

  loadReportCarBranchAll() {
    // car_branch_all
    axios.get(APIURL + '/report/car_branch_all')
      .then(res => {
        // console.log('---loadReportCarStatusAll')
        // console.log(res.data)
        let car_branch_all_sum =  res.data.reduce((acc, next) => acc + next.car_count, 0)
        this.setState({
          car_branch_all: res.data,
          car_branch_all_sum: car_branch_all_sum
        })
      }).catch(error => {
      this.setState({car_branch_all: []})
    })
  }

  getReportDataBy (by) {
    this.setState({report_by: by, dateStart: null, dateEnd: null }, () => {
      this.loadReportCarStatus()
      this.loadReportCarSellPay()
      this.loadReportExpense()
      this.loadReportCarRepairSum()
    })
  }

  loadCustomerProfile() {
    axios.get(APIURL + '/profile/')
      .then(res => {
        this.setState({profile_lists: res.data})
      }).catch(error => {
      this.setState({profile_lists: []})
    })
  }

  loadCustomerDoc() {
    // customer_docs
    axios.get(APIURL+'/customer_doc')
      .then((res) => {
        this.setState({customer_docs: res.data})
      })
  }

  loadAccountSale() {
    var userType = 'sale';
    axios.get(APIURL+'/account/type/'+userType)
    .then((res) => {
       this.setState({accountSale: res.data})
    })
  }

  onSelectSale(sale) {
    this.setState({
      selectSale: {
        id: '',
        user: ''
      }
    },()=>{
      this.setState({
        selectSale: sale
      },()=>{
        this.hiddenAlert(sale);
      });
    });

  }

  onSelectSaleHidden() {
    this.setState({
      selectSale: {
        id: '',
        user: ''
      }
    });
  }

  hiddenAlert(sale) {
    var url = APIURL+'/customer_profile_update/hidden/'+sale.user
    axios.put(url)
      .then((res) => {
        let accountSaleExist = this.state.accountSale;
        const updateAccountSale = accountSaleExist.map(account => account.id===sale.id ? {...account, count_update: 0  } : account )
        // console.log(updateAccountSale)

        this.setState({
          accountSale: updateAccountSale
        });
      })
  }

  getRandomRgb() {
    let num = Math.round(0xffffff * Math.random());
    let r = num >> 16;
    // eslint-disable-next-line no-mixed-operators
    let g = num >> 8 & 255;
    let b = num & 255;
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render () {
 let car_sell_count = this.state.car_sell_count

    let car_status_li = this.state.car_status_lists.map((car_status, index) => {

      let percent = car_status.car_count / car_status.car_sum * 100
      if(car_status.car_status_name === 'ขายแล้ว'){
        percent = car_sell_count / car_status.car_sum * 100
      }
      return (
        <li key={index}>
          <i className="icon-arrow-right"></i>
          <span className="title">{car_status.car_status_name}</span>
          <span className="value">
               <span className="badge"
                    style={{backgroundColor: car_status.car_status_color}}>
                 {car_status.car_status_name === 'ขายแล้ว' ? car_sell_count : car_status.car_count}
               </span>
                &nbsp;&nbsp;&nbsp;
               <span className="text-muted small">
                 <NumberFormat
                   fixedDecimalScale={true}
                   decimalScale={2}
                   value={car_status.car_count / car_status.car_sum * 100}
                   displayType={'text'}
                   thousandSeparator={true}/>%
               </span>
            </span>
          <div className="bars">
            <Progress className="progress-xs"
                      value={percent}/>
          </div>
        </li>
      )
    })

    const car_status_all_sum = this.state.car_status_all_sum
    const car_status_sum_li = this.state.car_status_all.map((car_status, index) => {
      return (
        <li key={index}>
          <i className="icon-arrow-right"></i>
          <span className="title">{car_status.car_status_name}</span>
          <span className="value">
               <span className="badge"
                     style={{backgroundColor: car_status.car_status_color}}>
                 {car_status.car_count}
               </span>
            &nbsp;&nbsp;&nbsp;
            <span className="text-muted small">
                 <NumberFormat
                   fixedDecimalScale={true}
                   decimalScale={2}
                   value={car_status.car_count / car_status_all_sum * 100}
                   displayType={'text'}
                   thousandSeparator={true}/>%
               </span>
            </span>
          <div className="bars">
            <Progress className="progress-xs"
                      value={car_status.car_count / car_status_all_sum * 100}/>
          </div>
        </li>
      )
    })


    const car_branch_all_sum = this.state.car_branch_all_sum
    const car_branch_all_li = this.state.car_branch_all.map((car_status, index) => {
      // const randomColor = Math.floor(Math.random()*16777215).toString(16);
      const randomColor = this.getRandomRgb();

      return (
        <li key={index}>
          <i className="icon-arrow-right"></i>
          <span className="title">{car_status.branch_name}</span>
          <span className="value">
               <span className="badge"
                     style={{backgroundColor: randomColor}}>
                 {car_status.car_count}
               </span>
            &nbsp;&nbsp;&nbsp;
            <span className="text-muted small">
                 <NumberFormat
                   fixedDecimalScale={true}
                   decimalScale={2}
                   value={car_status.car_count / car_branch_all_sum * 100}
                   displayType={'text'}
                   thousandSeparator={true}/>%
               </span>
            </span>
          <div className="bars">
            <Progress className="progress-xs"
                      value={car_status.car_count / car_branch_all_sum * 100}/>
          </div>
        </li>
      )
    })

    let car_pay_li = this.state.car_pay_list.map((car_pay, index) => {
      return (
        <div className="row p-1" key={index}>
          <div className="col-md-3">
            <i className="icon-arrow-right"></i> <strong>{car_pay.car_sell_type_name}</strong>
          </div>
          <div className="col-md-4 text-right" style={styles.textGreen}>
            <strong>
              <NumberFormat
                fixedDecimalScale={true}
                decimalScale={0}
                value={car_pay.car_sell_sum}
                displayType={'text'}
                thousandSeparator={true}
              />
            </strong>
          </div>
        </div>
      )
    })

    let expense_li = this.state.expense_list.map((expense, index) => {
      return (
        <div className="row p-1" key={index}>
          <div className="col-md-5">
            <i className="icon-arrow-right"></i> <strong>{expense.expense_type_name}</strong>
          </div>
          <div className="col-md-4 text-right" style={styles.textRed}>
            <strong>
              <NumberFormat
                fixedDecimalScale={true}
                decimalScale={0}
                value={expense.expense_sum}
                displayType={'text'}
                thousandSeparator={true}
              />
            </strong>
          </div>
        </div>
      )
    })

    let expense_all_sum = this.state.expense_sum + this.state.car_repair_sum

   /* let profileList = this.state.profile_lists.map((profile,index) => {
      return (
        <p key={index}>
          <span className="badge bg-primary">โปรไฟล์</span>
          &nbsp;&nbsp;
          ชื่อ-นามสกุล: {profile.customer_name} {profile.customer_lastname}
          ({profile.customer_mobile})
          &nbsp;&nbsp;
          <i className="icon-clock"></i> {moment(profile.updated_at).locale('th').format('DD-MM-YYYY HH:mm')}<br/>
          โปรไฟล์ : {profile.profile_name}  | จาก {profile.profile_from} <br/>
        </p>
      )
    });

    let customerDocList = this.state.customer_docs.map((customer_doc,index) => {
      return (
        <p key={index}>
          <span className="badge bg-danger">เอกสาร</span>
          &nbsp;&nbsp;
          ชื่อ-นามสกุล: {customer_doc.customer_name} {customer_doc.customer_lastname}
          ({customer_doc.customer_mobile})
          &nbsp;&nbsp;
          <i className="icon-clock"></i> {moment(customer_doc.updated_at).locale('th').format('DD-MM-YYYY HH:mm')}<br/>
          {customer_doc.doc_detail}
        </p>
      )
    }); */

    let accountSale = this.state.accountSale.map((sale, index) => {

      return (
        <div key={index} className={this.state.user.type === 'admin' ? 'row' : 'hidden' }>
          <div className="col-md-12">
            <div className="card">
              <div className="card-block">
                <div className="row">
                  <div className="col-md-6 d-inline">
                    <h6 className="d-inline">
                      <strong> {sale.fullname} ({sale.mobile}) </strong>
                      &nbsp;&nbsp;
                    </h6>
                    <span className={ sale.count_update===0 ? 'hidden' : 'd-inline'}>
                      {this.mounted ? <CustomBlinkingTxt text={sale.count_update} /> : <div></div>}
                    </span>
                  </div>
                  <div className="col-md-6 text-right">
                    <span style={{cursor: "pointer"}} className={this.state.selectSale.id !== sale.id? 'text-success' : 'hidden'}  onClick={() => this.onSelectSale(sale)}>แสดง</span>
                    <span style={{cursor: "pointer"}} className={this.state.selectSale.id === sale.id? 'text-danger'  : 'hidden'}  onClick={() => this.onSelectSaleHidden()}>ซ่อน</span>
                  </div>
                </div>

                <ProfileBySale

                  saleId={sale.id}
                  sale={this.state.selectSale}

                />

              </div>
            </div>
          </div>
        </div>
      )
    });

    return (
      <div className="animated fadeIn">

        {/*<div className={this.state.user.type === 'admin' ? 'hidden' : 'hidden' }>
          <div className="col-md-12">
            <div className="card">
              <div className="card-block">
                <div className="row">
                  <div className="col-md-12">
                    <h6><strong> โปรไฟล์/เอกสาร ลูกค้า </strong></h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    {profileList}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    {customerDocList}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>*/}


        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-block">
                <div className="row">
                  <div className="col-md-2">
                    <font><strong>ข้อมูลทั้งหมด</strong></font>
                  </div>

                </div>
                <div className="row" style={{marginTop: 20}}>
                  <div className="col-md-6">
                    <strong>จำนวนรถตามสถานะทั้งหมด {car_status_all_sum} คัน</strong>
                    <ul className="horizontal-bars type-2">
                      {car_status_sum_li}
                    </ul>
                  </div>

                  <div className="col-md-6">
                    <strong>จำนวนรถตามสาขาทั้งหมด {car_branch_all_sum} คัน</strong>
                    <ul className="horizontal-bars type-2">
                      {car_branch_all_li}
                    </ul>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-block">
                <div className="row mt-1">
                  <div className="ml-4">
                    <font><strong>{this.getReportByName()}</strong></font>
                  </div>
                </div>

                <div className="d-flex justify-content-between" style={{marginTop: 14}}>
                  <div className="d-flex ml-2">
                    จาก&nbsp;
                    <div style={{width: 250}}>
                      <DatePicker
                          selected={this.state.dateStart}
                          onChange={this.handleDateStart}
                          dateFormat="yyyy-MM-dd"
                          type="text"
                          className="form-control ml-2"
                          id="date_start"
                          name="date_start"
                          placeholder="วันที่เริ่ม"
                      />
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp; ถึง &nbsp;
                    <div style={{width: 250}}>
                      <DatePicker
                          selected={this.state.dateEnd}
                          onChange={this.handleDateEnd}
                          dateFormat="yyyy-MM-dd"
                          type="text"
                          className="form-control ml-2"
                          id="date_end"
                          name="date_end"
                          placeholder="วันที่สิ้นสุด"
                      />
                    </div>
                    &nbsp;
                    <button type="submit"
                            onClick={()=>{
                              this.reportByDate(false)
                            }}
                            className="btn btn-sm btn-primary ml-2">
                      <i className="fa fa-search"></i> ตกลง
                    </button>
                  </div>

                  <div className="d-flex">
                    <div className="btn-group" role="group" aria-label="tap">
                      <button type="button"
                              className={this.state.report_by === 'today' ? 'btn btn-secondary btn-sm active' : 'btn btn-secondary btn-sm'}
                              style={{width: 64}}
                              onClick={() => {
                                this.getReportDataBy('today')
                              }}
                      >วันนี้
                      </button>
                      <button type="button"
                              className={this.state.report_by === '7day' ? 'btn btn-secondary btn-sm active' : 'btn btn-secondary btn-sm'}
                              style={{width: 64}}
                              onClick={() => {
                                this.getReportDataBy('7day')
                              }}
                      >7 วัน
                      </button>
                      <button type="button"
                              className={this.state.report_by === 'month' ? 'btn btn-secondary btn-sm active' : 'btn btn-secondary btn-sm'}
                              style={{width: 64}}
                              onClick={() => {
                                this.getReportDataBy('month')
                              }}
                      >30 วัน
                      </button>
                      <button type="button"
                              className={this.state.report_by === '3month' ? 'btn btn-secondary btn-sm active' : 'btn btn-secondary btn-sm'}
                              style={{width: 64}}
                              onClick={() => {
                                this.getReportDataBy('3month')
                              }}
                      >3 เดือน
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row" style={{marginTop: 20}}>
                  <div className="col-md-4">
                    <strong>จำนวนรถตามสถานะ</strong>
                    <ul className="horizontal-bars type-2">
                      {car_status_li}
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <strong>ยอดจอง(คัน)</strong>
                    <div className="callout callout-warning">
                      <small className="text-muted">จอง</small>
                      <br/>
                      <strong className="h4">
                        <NumberFormat value={this.state.car_book_count} displayType={'text'}
                                      thousandSeparator={true}/>
                      </strong>
                      <div className="chart-wrapper">
                        <canvas id="sparkline-chart-3" width="100" height="30"></canvas>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <strong>ยอดขาย(คัน)</strong>
                    <div className="callout callout-success">
                      <small className="text-muted">ขาย</small>
                      <br/>
                      <strong className="h4">
                        <NumberFormat value={this.state.car_sell_count} displayType={'text'}
                                      thousandSeparator={true}/>
                      </strong>
                      <div className="chart-wrapper">
                        <canvas id="sparkline-chart-3" width="100" height="30"></canvas>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={this.state.user.type === 'admin' ? 'row' : 'hidden' }>
          <div className="col-md-12">
            <div className="card">
              <div className="card-block">
                <div className="row">
                  <div className="col-md-12">
                    <h6><strong>{this.getReportByName()}</strong></h6>
                  </div>
                </div>

                <br/>

                <div className="row">
                  <div className="col-md-6">
                    <h6 style={styles.textGreen}><strong>รายรับ</strong></h6>

                    {this.state.car_pay_list.length > 0 ? car_pay_li : ' -'}

                    <div className={this.state.car_pay_sum > 0 ? 'row p-1' : 'hidden' }>
                      <div className="col-md-3">
                        <i className="icon-arrow-right"/> <strong>รวม</strong>
                      </div>
                      <div className="col-md-4 text-right" style={styles.textGreen}>
                        <strong>
                          <u>
                            <NumberFormat fixedDecimalScale={true}
                                          decimalScale={0}
                                          value={this.state.car_pay_sum}
                                          displayType={'text'}
                                          thousandSeparator={true}
                            />
                          </u>
                        </strong>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h6 style={styles.textRed}><strong>รายจ่าย</strong></h6>

                    {this.state.expense_list.length > 0 ? expense_li : ' -'}


                    <div className={this.state.car_repair_sum > 0 ? 'row p-1' : 'hidden' }>
                      <div className="col-md-5">
                        <i className="icon-arrow-right"/> <strong>ค่าซ่อมรถ</strong>
                      </div>
                      <div className="col-md-4 text-right" style={styles.textRed}>
                        <strong>
                          <NumberFormat
                            fixedDecimalScale={true}
                            decimalScale={0}
                            value={this.state.car_repair_sum}
                            displayType={'text'}
                            thousandSeparator={true}
                          />
                        </strong>
                      </div>
                    </div>

                    <div className={ (this.state.expense_sum ===0 && this.state.car_repair_sum === 0) ? 'hidden' : 'row p-1' }>
                      <div className="col-md-5">
                        <i className="icon-arrow-right"/> <strong>รวม</strong>
                      </div>
                      <div className="col-md-4 text-right" style={styles.textRed}>
                        <strong>
                          <u>
                            <NumberFormat
                              fixedDecimalScale={true}
                              decimalScale={0}
                              value={expense_all_sum}
                              displayType={'text'}
                              thousandSeparator={true}
                            />
                          </u>
                        </strong>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggleTab('1'); }}
            >
               แจ้งเตือนการจอง
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggleTab('2'); }}
            >
               สรุปรายการรถคงค้างปัจจุบัน
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '3' })}
              onClick={() => { this.toggleTab('3'); }}
            >
              สรุปยี่ห้อรถ/ลักษณะรถ
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
                className={classnames({ active: this.state.activeTab === '4' })}
                onClick={() => { this.toggleTab('4'); }}
            >
              สรุปตามประเภทการซื้อ
            </NavLink>
          </NavItem>

          {/*<NavItem>
            <NavLink
                className={classnames({ active: this.state.activeTab === '4' })}
                onClick={() => { this.toggleTab('4'); }}
            >
              สรุปตามประเภทรถ
            </NavLink>
          </NavItem>*/}


        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <strong className="text-title">แจ้งเตือนการจองตามเซลล์</strong>
                  </div>
                  <div className="card-block">
                    <div className="row">
                      <div className="col-lg-12">
                        {accountSale}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>

          <TabPane tabId="2">
            <div className="row">
              <CurrentOutstandingCarList />
            </div>
          </TabPane>

          <TabPane tabId="3">
            <div className="row">
               <CurrentCarSummary />
            </div>
          </TabPane>

          <TabPane tabId="4">
            <div className="row">
              <CurrentCarPersonVat />
            </div>
          </TabPane>

        </TabContent>

        <div className="mt-2">
          &nbsp;
        </div>

        <CalendarList />


      </div>
    )
  }
}

const styles = {
  textGreen: {
    color: '#24933c'
  },
  textRed: {
    color: '#ff0000'
  }
}
const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})


class CustomBlinkingTxt extends Component {
  constructor(props) {
    super(props);
    this.state = { showText: true };
  }
  componentDidMount() {
    this.myInterval = setInterval(this.onBlinkText.bind(this),1200);
  }

  onBlinkText(){
    this.setState(previousState => {
      return { showText: !previousState.showText };
    });
  }

  render() {
    let display = this.state.showText ? this.props.text : '';
    return (
      <span className="badge badge-danger" style={{padding: 3}}>{display}</span>
    );
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }
}


export default connect(mapStateToProps)(Dashboard)
