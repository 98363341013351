

export const CustomerUtil = {

  getWalkInLevelList() {
    let walkin_level_list = [
      {walkin_level_id: 'A', walkin_level_name: 'A'},
      {walkin_level_id: 'B', walkin_level_name: 'B'},
      {walkin_level_id: 'C', walkin_level_name: 'C'},
      {walkin_level_id: 'D', walkin_level_name: 'D'},
      {walkin_level_id: 'E', walkin_level_name: 'E'}
    ];

    return walkin_level_list
  }
  // getCustomerSellType(){
  //
  // }
}
